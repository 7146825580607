import { useEffect } from "react";

const useGoogleFonts = (fontList = []) => {
  useEffect(() => {
    if (!fontList.length) return;

    const formattedFonts = fontList
      .map((font) => font.replace(/ /g, "+"))
      .join("&family=");
    const apiUrl = `https://fonts.googleapis.com/css2?family=${formattedFonts}&display=swap`;

    const linkId = `google-fonts-${fontList.join("-")}`;

    // Check if fonts are already loaded
    if (!document.querySelector(`link#${linkId}`)) {
      const link = document.createElement("link");
      link.id = linkId;
      link.href = apiUrl;
      link.rel = "stylesheet";
      document.head.appendChild(link);
    }

    return () => {
      const link = document.querySelector(`link#${linkId}`);
      if (link) {
        link.remove();
      }
    };
  }, [fontList]);
};

export default useGoogleFonts;
