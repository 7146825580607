import React from "react";
import { Box, Typography } from "@mui/material";

const NoRouteFound = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <Box>
        <Typography variant="h4" color="textPrimary" gutterBottom>
          404 - Page Not Found
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          The page you're looking for doesn't exist.
        </Typography>
      </Box>
    </Box>
  );
};

export default NoRouteFound;
