import { createSlice } from "@reduxjs/toolkit";
import { onFetchPageComponentsListById } from "services/ApiService";
import pageComponentDataModule from "./PageComponentData";
import { ERROR_MESSAGES } from "StatusAlerts";

const initialState = {
  pageComponents: [],
};

export const pageComponentsSlice = createSlice({
  name: "pageComponents",
  reducers: {
    addPageComponentsList: (state, { payload }) => ({
      ...state,
      pageComponents: payload,
    }),
    clearPageComponents: (state) => ({
      ...state,
      pageComponents: [],
    }),
  },
  initialState,
});

const { addPageComponentsList, clearPageComponents } =
  pageComponentsSlice.actions;

const fetchPageComponentsListById = ({
  pageId,
  isPreview,
  onSuccessResponse,
  onErrorResponse,
}) => {
  return async (dispatch) => {
    dispatch(clearPageComponents());
    dispatch(pageComponentDataModule.operations.onClearComponentsListData());
    try {
      const { result, error, statusCode } = await onFetchPageComponentsListById(
        pageId,
        isPreview
      );
      if (statusCode === 200) {
        dispatch(addPageComponentsList(result.data));
        onSuccessResponse({ response: result.data });
      } else {
        onErrorResponse({
          response: {
            errorMessage: error || ERROR_MESSAGES.ERROR_FETCH_PAGE_COMPONENTS,
          },
        });
      }
    } catch (error) {
      onErrorResponse({
        response: {
          errorMessage:
            error?.message || ERROR_MESSAGES.ERROR_FETCH_PAGE_COMPONENTS,
        },
      });
    }
  };
};
const pageComponentsModule = {
  reducer: pageComponentsSlice.reducer,
  operations: {
    fetchPageComponentsListById,
  },
};

export default pageComponentsModule;
