const indicatorStyles = {
  color: "#D9D9D9",
  width: "12px",
  height: "12px",
  borderRadius: "50%",
  margin: "0 10px",
  cursor: "pointer",
};
const activeIndicatorStyles = {
  color: "#0072CE",
};
const indicatorContainerStyles = {
  position: "absolute",
  bottom: "40px",
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  justifyContent: "center",
  zIndex: 1,
  width: "auto",
};
export { indicatorStyles, activeIndicatorStyles, indicatorContainerStyles };
