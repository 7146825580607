import { BASE_API_URL } from "Constants";

const apiRequest = async (url, options = {}) => {
  try {
    const response = await fetch(url, {
      ...options,
    });
    const statusCode = response.status;
    if (!response.ok) {
      return {
        result: null,
        error: `Error ${response.status}: ${response.statusText}`,
        statusCode,
      };
    }
    const result = await response.json();

    return { result, error: null, statusCode };
  } catch (error) {
    return { result: null, error: error.message, statusCode: 500 };
  }
};

// Fetch all project pages by projectId
export const onFetchProjectPagesList = async (projectId) => {
  return apiRequest(`${BASE_API_URL}ProjectPages/GetAll/${projectId}`);
};

// Fetch components of a specific page by pageId
export const onFetchPageComponentsListById = async (pageId, isPreview) => {
  return apiRequest(
    isPreview
      ? `${BASE_API_URL}Component/GetPageComponents/${pageId}`
      : `${BASE_API_URL}PublishControler/GetPageComponents/${pageId}`
  );
};

// Fetch component data by componentId and componentType
export const onFetchComponentDataById = async (
  componentId,
  componentType,
  isPreview
) => {
  return apiRequest(
    isPreview
      ? `${BASE_API_URL}Component/GetById/${componentId}?componentType=${componentType}`
      : `${BASE_API_URL}PublishControler/GetById/${componentId}?componentType=${componentType}`
  );
};
//Fetch theme
export const fetchThemeByOrganizationIdApi = async (organizationId) => {
  return apiRequest(
    `${BASE_API_URL}ThemeAndBranding/GetOrganizationBrandByOrganizationId/${organizationId}`
  );
};
// Fetch project branding by projectId
export const onFetchProjectBranding = async (projectId) => {
  return apiRequest(`${BASE_API_URL}ProjectBrand/Project/${projectId}`);
};
export const onFetchTabDetails = async (tabId, isPreview) => {
  return apiRequest(
    isPreview
      ? `${BASE_API_URL}Component/Tab/${tabId}`
      : `${BASE_API_URL}PublishControler/Tab/${tabId}`
  );
};
