import React, { useMemo } from "react";
import { Box, Grid } from "@mui/material";


import Card from "components/Card/Card";
import BannerSection from "./components/BannerSection";
import MapComponent from "./components/MapComponent";
import HeaderComponent from "../Components/HeaderSection";

import { mapJSONData } from "metaDataJson/Map";

import { GOLF_TOURNAMENTS_URL, TEXT_CONSTANTS } from "Constants";
import { extractData, getUpdatedButtonStyles } from "utils";

import CardImage1 from "assets/images/golf-offer.png";

import { styles as mapStyles } from "styles/MapStyles";


const mediaStyles = {
  borderRadius: "30px",
  height: "230px !important",
};
const cardStyles = {
  borderRadius: "30px",
};
const mapCardData = {
  title: "Fairways",
  description: "2016 Club Car fleet, complete with Gps",
  image: CardImage1,
  CTALink:GOLF_TOURNAMENTS_URL,
  isCTAExternal:true,

};
const SpecialOfferCard = ({ brandTags }) => {
  const updatedStyles = getUpdatedButtonStyles(brandTags, "fourthButton", {padding: "4px 12px"})
  return (
    <Card
    image={mapCardData.image}
    title={mapCardData.title}
    description={mapCardData.description}
    onLearnMoreClick={() => console.log("Learn More clicked!")}
    imagePosition={TEXT_CONSTANTS.TOP}
    cardStyles={{
      ...cardStyles,
    }}
    cardContentStyles={mapStyles.cardContainer}
    buttonPosition={TEXT_CONSTANTS.BOTTOM}
    titleStyle={brandTags?.h3}
    descriptionStyle={brandTags?.p}
    buttonName={TEXT_CONSTANTS.KNOW_MORE}
    mediaStyle={{
      ...mediaStyles,
    }}
    titleVariant="h3"
    descriptionVariant="p"
    buttonVariant={TEXT_CONSTANTS.SECONDARY_BUTTON}
    // buttonStyles={{
    //   ...brandTags?.fourthButton,
    //   // ...buttonStyles,
    // }}
    buttonStyles={updatedStyles}
    buttonExtraStyles={{ justifyContent: "center" }}
    CTALink={mapCardData.CTALink}
    isExternal={mapCardData.isCTAExternal}

  />
  )
}

const MapLayout = ({ brandTags }) => {
  const mapData = useMemo(() => extractData(mapJSONData), []);

  return (
    <Box flexGrow={1}>
      <HeaderComponent
        title={TEXT_CONSTANTS.GOLF}
        subTitle={TEXT_CONSTANTS.GOLF_SUBTITLE}
        styles={brandTags}
        titleVariant="h2"
        subTitleVariant="p"
      />
      <BannerSection tagStyle={brandTags} />

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={4}>
          <SpecialOfferCard brandTags={brandTags} />
        </Grid>
        <Grid item xs={12} md={8}>
          <MapComponent data={mapData[0]} tagStyle={brandTags} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MapLayout;
