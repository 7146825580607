import React from "react";
import { Box } from "@mui/material";

import Header from "components/Header/Header";

const HeaderComponent = ({
  title,
  subTitle,
  styles,
  titleVariant,
  subTitleVariant,
  showSearch,
}) => (
  <Box mb={1.25}>
    <Header
      title={title}
      subTitle={subTitle}
      showSearch={showSearch}
      userNameStyles={styles?.[titleVariant]}
      dateStyles={styles?.[subTitleVariant]}
      titleVariant={titleVariant}
      subTitleVariant={subTitleVariant}
    />
  </Box>
);



export default HeaderComponent;
