import React, { memo, useMemo } from "react";
import { Box } from "@mui/material";
import { getTodayDate, sortDataByOrderId } from "utils";
import HtmlContentWrapper from "components/HtmlContentWrapper";

const HeaderWithTags = memo(({ pageHtmlTags = [] }) => {
  const orderedTexts = useMemo(
    () => sortDataByOrderId(pageHtmlTags),
    [pageHtmlTags]
  );
  return (
    <Box>
      {orderedTexts.map(({ id, htmlTagType, description, style }) => {
        const content = htmlTagType === "Date" ? getTodayDate() : description;
        return (
          <HtmlContentWrapper key={id} contentHTML={content} styles={style} tagType={htmlTagType === "Date"?"p":htmlTagType} />
        );
      })}
    </Box>
  );
});

export default HeaderWithTags;
