export const styles = {
  paper: {
    height: "500px",
    position: "relative",
    boxShadow: "none",
    backgroundColor: "transparent",
    overflow: "hidden",
  },
  imageContainer: {
    height: "100%",
  },
  overlay: {
    position: "absolute",
    width: "calc(100% - 48px)",
    height: "calc(100% - 48px)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    margin: "auto",
    top: "50%",
    left: "50%",
    borderRadius: "30px",
    transform: "translate(-50%, -50%)",
  },
  leftGrid: {
    width: "100%",
    height: "100%",
  },
  button: {
    width: "90%",
    marginBottom: 2,
  },
  mapStyles: {
    height: "400px",
    width: "100%",
  },
  cardContainer: {
    borderColor: "#D9D9D9",
    borderRadius: "60px",
    height: "100%",
  },
  mainCardTitle: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -10%)",
    textAlign: "center",
    bgcolor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "10px",
    padding: "8px",
    width: "81%",
  },
  buttonStyles: {
    fontSize: "12px",
    fontWeight: 400,
    borderRadius: 30,
    padding: 5,
    foregroundColor: "#885636",
  },
};
