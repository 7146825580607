import React, { useState } from "react";
import { Box, Grid } from "@mui/material";

import { subChipSets, contentMap } from "metaDataJson/FitnessStatic";

import ContentSection from "./components/ContentSection";

import { imageComponentData } from "metaDataJson/Fitness";
import IntroDetailsCard from "components/IntroDetailsCard";
import HeaderComponent from "../Components/HeaderSection";

import DynamicChipSet from "components/Tabs/DynamicTabs";
import { TEXT_CONSTANTS } from "Constants";
import { useNavigate } from "react-router-dom";

const FitnessLayout = ({ brandTags }) => {
  const [activeTab, setActiveTab] = useState("1");

  const handleSubChipClick = (value) => setActiveTab(value);
  const navigate = useNavigate()

  const renderTabs = (
    tabsList,
    activeTab,
    handleChip,
    style,
    direction,
    contentPosition
  ) => {
    return (
      <DynamicChipSet
        chips={tabsList}
        activeTab={activeTab}
        handleChip={(e) => {
          handleChip(e);
        }}
        styles={{ ...style, width: "100%" }}
        direction={direction}
        justifyContent={contentPosition}
      />
    );
  };

  return (
    <Box flexGrow={1}>
      <HeaderComponent
        title={TEXT_CONSTANTS.FITNESS_TITLE}
        subTitle={TEXT_CONSTANTS.FITNESS_SUBTITLE}
        styles={brandTags}
        titleVariant={"h2"}
        subTitleVariant={"p"}
        showSearch={false}
      />

      {imageComponentData && (
        <IntroDetailsCard data={imageComponentData?.$values?.[0]} />
      )}

      <Grid container mt={3}>
        <Grid item xs={12} md={3} sx={{ pr: 3 }} mb={4}>
          {renderTabs(
            subChipSets,
            activeTab,
            handleSubChipClick,
            {},
            "column",
            "flex-start"
          )}
        </Grid>

        <ContentSection
          contentData={contentMap?.[activeTab]}
          tagStyle={brandTags}
          subChipSets={subChipSets}
          activeTab={activeTab}
          handleSubChipClick={handleSubChipClick}
          titleVariant="h3"
          subTitleVariant="p"
          descriptionVariant="p"
          navigate={navigate}

        />
      </Grid>
    </Box>
  );
};

export default FitnessLayout;
