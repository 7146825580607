import React from "react";
import { Box, Typography } from "@mui/material";

const NoDataFound = ({ title, description = "" }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <Box>
        <Typography variant="h4" color="textPrimary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default NoDataFound;
