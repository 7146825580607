import Image1 from "assets/images/Fitness_tab1_img.png"
import Image2 from "assets/images/Fitness_tab2_img.png"
import Image3 from "assets/images/Fitness_tab4_img.jpeg"
export const mainChips = [
    { label: "Equinox", value: "1" },
    { label: "Soul Cycle", value: "2" },
  ];

  export const subChipSets = [
    
      { title: "HIIT Fitness", id: "1" },
      { title: "Group Fitness", id: "2" },
      { title: "Personal Training", id: "3" },
      { title: "Pilates", id: "4" },
    
  ];

  export const thirdChipSets = [
    { label: "Tickets", value: "1" },
    { label: "Speakers", value: "2" },
    { label: "Schedule", value: "3" },
  ];

  export const thirdChipContent = {
    1: [
      {
        title: "Tickets for Complete Event",
        tabData: [
          {
            title: "2 Day Pass",
            subTitle: "$650",
            description:
              " Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form,without the meaning of the text influencing the design.",
          },
          {
            title: "VIP Pass",
            subTitle: "$650",
            description:
              " Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form,without the meaning of the text influencing the design.",
          },
          {
            title: "General Admission",
            subTitle: "$650",
            description:
              " Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form,without the meaning of the text influencing the design.",
          },
        ],
      },
    ],
    2: [
      {
        title: "Speakers",
        tabData: [
          {
            subTitle: "",
            description:
              "Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form,without the meaning of the text influencing the design.",
          },
          {
            subTitle: "",
            description:
              "Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form,without the meaning of the text influencing the design.",
          },
          {
            subTitle: "",
            description:
              "Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form,without the meaning of the text influencing the design.",
          },
          {
            subTitle: "",
            description:
              "Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form,without the meaning of the text influencing the design.",
          },
        ],
      },
    ],
    3: [
      {
        title: "Schedule",
        tabData: [
          {
            subTitle: "Day 1",
            description:
              "Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form,without the meaning of the text influencing the design.",
          },
          {
            subTitle: "Day 2",
            description:
              "Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form,without the meaning of the text influencing the design.",
          },
          {
            subTitle: "Day 3",
            description:
              "Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form,without the meaning of the text influencing the design.",
          },
          {
            subTitle: "Day 4",
            description:
              "Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form,without the meaning of the text influencing the design.",
          },
          {
            subTitle: "Day 5",
            description:
              "Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form,without the meaning of the text influencing the design.",
          },
          {
            subTitle: "Day 6",
            description:
              "Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form,without the meaning of the text influencing the design.",
          },
        ],
      },
    ],
  };


export const contentMap = {
  1: [
    
    {
      text: "HIIT Fitness",
      variant: "h3",
      fontSize: "20px",
      fontWeight: 500,
      image:Image1,

    },
    {
      text: "The ultimate realization of high-performance living. Designed to inspire uncompromised results.",
      variant: "p",
    },
    {
      text: "At Equinox Hudson Yards, unlocking your potential takes center stage. Our personalized service and signature programming come together in unparalleled fitness experiences, from renowned Personal Training to innovative Group Fitness classes led by the industry’s most beloved talent and rising stars",
      variant: "p",
    },
    {
      text: "HIIT Fitness available to overnight resort guests only.",
      variant: "p",
    },
    {
      text: "Meet at the Volleyball Court, every Saturday and Sunday at 9:20am",
      variant: "p",
    },
    
  ],
  2: [
    {
      text: "Group Fitness",
      variant: "h3",
      fontSize: "20px",
      fontWeight: 500,
      image: Image2,

    },
    {
      text: "Push your boundaries with cutting-edge classes designed to challenge and empower. From high-energy cardio to strength and conditioning, Equinox Hudson Yards offers a diverse range of Group Fitness options that cater to all levels. Our world-class instructors guide you through innovative workouts, ensuring that every session is engaging, transformative, and results-driven. Whether you're stepping into your first class or pushing towards new goals, this is where fitness meets community ",
      variant: "p",
    },
    {
      text: "Group Fitness Schedule:",
      variant: "p",
    },
    {
      text: "Daily classes available starting from 6:00 am to 9:00 pm",
      variant: "p",
    },
    {
      text: "Early morning, lunchtime, and evening options to fit your schedule",
      variant: "p",
    },
  ],
  3:[{
    text:"Personal Training",
    variant: "h3",
    fontSize: "20px",
    fontWeight: 500,
    image:Image3,

  },
  {text:"Achieve the extraordinary with one-on-one coaching designed just for you. At Equinox Hudson Yards, our elite Personal Trainers craft personalized programs that optimize your progress, focusing on your unique goals and capabilities. From strength and endurance building to mobility and recovery, every session is tailored to unlock your full potential. Experience the pinnacle of personal training with cutting-edge techniques and hands-on support from the best in the industry. ",
  variant:"p"
  },
  {text:"Personal Training Availability: ",
  variant:"p"
  },
  {text:"Flexible booking from early morning to evening sessions",
  variant:"p"
  },
  {text:"In-person and virtual options for tailored coaching",
  variant:"p",

  },
],
  4:[{
    text:"Pilates",
    variant:"h3",
    fontSize: "20px",
    fontWeight: 500,
    image:Image3,

  },
  {
    text:"Elevate your strength, flexibility, and posture through our comprehensive Pilates program. At Equinox Hudson Yards, we offer a full spectrum of Pilates classes and private sessions in a serene, studio environment. Using state-of-the-art equipment, our certified Pilates instructors deliver precise and effective workouts that align, strengthen, and energize the body. Whether you’re new to Pilates or looking to refine your practice, our approach combines expert instruction with a focus on mindful movement",
  variant:"p",

  },
  {text:"Pilates Studio Hours:",
  variant:"p",

  },
  {
    text:"Mon to Fri / 6:00 am – 9:00 pm",
  variant:"p",

  },
  {text:" Sat & Sun / 8:00 am – 6:00 pm",
  variant:"p",

  },
]
  
};
