import React from "react";
import { Grid } from "@mui/material";
import TextWrapper from "components/TextWrapper";
import Image from "components/Image";
import { defaultBorderThickness } from "styles/CommonStyles";
import Button from "components/Button";
import { getUpdatedButtonStyles, handleCTA } from "utils";
import { HITFITNESS_URL } from "Constants";

const ContentSection = ({ contentData, tagStyle, navigate }) => {
  const buttonStyles = getUpdatedButtonStyles(tagStyle, "primaryButton", {padding: "10px 30px"})
  return (
  <>
    <Grid item xs={12} md={6} mb={4}>
      {contentData.map((item) => (
        <TextWrapper
          key={item?.text}
          sx={{
            marginBottom: "16px !important",
            ...tagStyle?.[item.variant],
          }}
          styles={{
            fontSize: item.fontSize || "16px",
            fontWeight: item.fontWeight || 400,
            ...tagStyle?.[item.variant],
            textAlignment: 1,
            ...defaultBorderThickness,
          }}
          title={item.text}
          variant={item.variant}
        />
      ))}
      <Button
        name="Know More"
        styles={buttonStyles}
        handleClick={() => handleCTA(true , HITFITNESS_URL)}
      />
    </Grid>
    <Grid item xs={12} md={3} sx={{ textAlign: "center" }} mb={4}>
      <Image imageURL={contentData?.[0]?.image} extraStyles={{height:"281px" , borderRadius:"20px" }} />
    </Grid>
  </>
  )
}

export default ContentSection;
