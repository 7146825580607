import * as React from "react";
import Card from "@mui/material/Card";
import { useNavigate, useParams } from "react-router-dom";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Box, styled, Typography } from "@mui/material";

import { buttonAlignment, generateStyles } from "../../styles/GenerateStyles";
import ContentBlock from "components/ContentBlock";
import CTAButton from "components/CTAButton";
import useCustomSnackbar from "hooks/useCustomSnackbar";
import { handleCTANavigation } from "utils";
import { TEXT_CONSTANTS } from "Constants";

const MediaCard = ({
  image,
  cardStyles,
  mediaStyle,
  cardContentStyles,
  imagePosition = "left",
  buttonPosition,
  cta,
  imageStyles,

  contentArray,
  imageObject,
}) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const snackbar = useCustomSnackbar();
  
  const isImageLeft = imagePosition === "left";

  const updatedObject = generateStyles({
    styles: { ...imageStyles, margin: "0px !important" },
    disableBackground: false,
  });
  const updatedObject2 = generateStyles({
    styles: { margin: imageStyles?.margin, padding: "0px !important" },

    disableBackground: false,
  });
  const isVertical = [TEXT_CONSTANTS.TOP, TEXT_CONSTANTS.BOTTOM].includes(
    imagePosition
  );
  const flexDirection = isVertical
    ? imagePosition === TEXT_CONSTANTS.BOTTOM
      ? "column"
      : "column-reverse"
    : isImageLeft
    ? "row-reverse"
    : "row"

  const onHandleClick = (isExternal, url) => {
    handleCTANavigation({ isExternal, navigate, projectId, snackbar, url });
  };

  return (
    <StyledCard
      sx={{
        maxWidth: "auto",
        display: "flex",
        flexDirection,
        justifyContent:
          isVertical ? "flex-end" : "",
        height: "100%",
      }}
      styles={cardStyles}
    >
      <Box
        sx={{
          width: isVertical ? "100%" : "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CardContent sx={cardContentStyles}>
          {contentArray?.map((item, index) => (
            <Typography variant="span" key={item?.id || `content-${index}`}>
              <ContentBlock
                styles={item?.style}
                content={item?.content}
                tagType={item?.tagType}
                contentHTML={item?.contentHTML}
              />
            </Typography>
          ))}
        </CardContent>
        {buttonPosition !== "top" &&
          cta?.map((item, index) => (
            <CardActions
              sx={
                item?.style?.textAlignment
                  ? buttonAlignment(item?.style?.textAlignment)
                  : {}
              }
              key={item?.id || `cta-${index}`}
            >
              <CTAButton cta={item} />
            </CardActions>
          ))}
      </Box>
      <Box
        sx={{
          position: "relative",
          height: isVertical ? "232px" : "auto",
          width: isVertical ? "auto" : "50%",
          ...updatedObject2,
        }}
      >
        <Box sx={{ ...imgContainerStyles }}>
          <CardMedia
            component="img"
            sx={{
              height:
              isVertical ? "232px" : "100%",
              width: "100%",
              objectFit: "cover",
              ...mediaStyle,
              ...updatedObject,
              cursor: "pointer",
              boxSizing: "border-box",
            }}
            image={image}
            onClick={() =>
              onHandleClick(imageObject?.isExternal, imageObject?.linkURL)
            }
          />
        </Box>

        {buttonPosition === "top" &&
          cta?.map((item, index) => {
            const buttonStyle = {
              position: "absolute",
              top: `${14 + index * 40}px`,
              right: 22,
            };
            return (
              <Box sx={buttonStyle} key={item?.id || `top-cta-${index}`}>
                <CTAButton
                  sx={{
                    backdropFilter: "blur(10px)",
                    WebkitBackdropFilter: "blur(10px)",
                  }}
                  cta={item}
                />
              </Box>
            );
          })}
      </Box>
    </StyledCard>
  );
};

export default MediaCard;
const imgContainerStyles = {
  width: "100%",
  height: "100%",
};
const StyledCard = styled(Card)(({ styles }) => ({
  ...generateStyles({ styles, disableBackground: false }),
}));
