import React, { useCallback } from "react";
import { Box, styled, useTheme } from "@mui/material";
import Logo from "./Logo";
import { handleCTANavigation } from "utils";
import { useNavigate, useParams } from "react-router-dom";
import useCustomSnackbar from "hooks/useCustomSnackbar";
import { TEXT_CONSTANTS } from "Constants";

const StyledLogoContainer = styled(Box)({
  position: "absolute",
  top: "30px",
  left: "30px",
  zIndex: 2,
});

const LandingLogo = ({ firstPage, from = "" }) => {
  const { projectId, organizationId } = useParams();
  const navigate = useNavigate();
  const snackbar = useCustomSnackbar();
  const theme = useTheme();
  const logo = theme?.logo || null;

  const onClickLogo = useCallback(() => {
    const { pageSlug, isExternal } = firstPage || {};
    if (from === TEXT_CONSTANTS.ORGANIZATION_TEXT) {
      navigate(`/layout/${pageSlug}/${organizationId}`);
    } else {
      handleCTANavigation({
        isExternal,
        url: pageSlug,
        projectId,
        navigate,
        snackbar,
      });
    }
  }, [firstPage, from, navigate, organizationId, projectId, snackbar]);
  return (
    <StyledLogoContainer onClick={onClickLogo}>
      <Logo imagePath={logo} extraStyles={{ height: "100px" , backgroundColor:"transparent" }} />
    </StyledLogoContainer>
  );
};

export default LandingLogo;
