import React, { memo } from "react";
import { Provider } from "react-redux";
import { SnackbarProvider } from "material-ui-snackbar-provider";

import AppRouter from "./router/AppRouter";
import { store } from "./reduxStore/Store";

const App = memo(() => {
  return (
    <Provider store={store}>
      <SnackbarProvider>
        <AppRouter />
      </SnackbarProvider>
    </Provider>
  );
});

export default App;
