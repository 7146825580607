export const ERROR_MESSAGES = {
  FAILED_TO_FETCH_ORGANIZATION_THEME:
    "Failed to fetch theme by organization ID.",
  FAILED_TO_FETCH_PROJECT_PAGES: "Failed to fetch project pages list.",
  FAILED_TO_FETCH_PROJECT_COMPONENT_DATA:
    "Failed to fetch project component data.",
  FAILED_TO_FETCH_TAB_COMPONENT_DATA: "Failed to tab component data.",
  INVALID_ORGANIZATION_ID: "Invalid Organization Id",
  FAILED_TO_FETCH_BRANDING_DATA: "Failed to fetch branding data.",
  UN_EXPECTED_ERROR_OCCUR: "An unexpected error occurred.",
  FAILED_TO_LOAD_PAGE_COMPONENTS: "Failed to load page components.",
  NO_PROJECTS_FOUND: "No Project Pages Found.",
  FAILED_TO_FETCH_TAB_DATA: "Failed to fetch tab data.",
  ERROR_FETCH_PAGE_COMPONENTS: "Failed to fetch page components",
};
