import React, { useMemo } from "react";
import Chip from "../Chip/Chip";
import { Box, useTheme } from "@mui/material";
import { generateStyles } from "../../styles/GenerateStyles";
import useGoogleFonts from "../../hooks/useGoogleFonts";


const ChipSet = ({
  chips,
  activeTab,
  handleChip,
  styles,
  direction = "row",
  justifyContent,
}) => {
  const theme = useTheme();
  const { palette } = theme;
  const { tertiary,  accent1 , accent2 , primaryFontStyles } = palette;

  const fontFamily = useMemo(
    () => (primaryFontStyles?.fontFamily ? [primaryFontStyles.fontFamily] : []),
    [primaryFontStyles.fontFamily]
  );

  useGoogleFonts(fontFamily);

  const chipStyles = useMemo(() => {
    const {fontFamily , fontWeight} = primaryFontStyles
    return chips?.map((item) => {
      const isActive = activeTab === item.id;
      return {
        id: item.id,
        styles: generateStyles({
          styles: {
            ...styles,
            backgroundColor: isActive ? tertiary : "",
            borderRadius: "0px",

            fontFamily: fontFamily || "Poppins",
            fontSize: "14px",
            fontWeight: fontWeight || 400,
          },
          disableBackground: !isActive,
        }),
        isActive,
        label: item.title,
      };
    });
  }, [chips, activeTab, styles , tertiary , primaryFontStyles]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexDirection: direction,
        flexWrap: "wrap",
      }}
    >
      {chipStyles?.map(({ id, label, styles, isActive }) => (
        <Chip
          key={id}
          label={label}
          onClick={() => handleChip(id)}
          variant={isActive ? "filled" : "outlined"}
          styles={styles}
          sx={{
            justifyContent: justifyContent,
            backgroundColor: isActive ? tertiary : "",
            borderRadius: "0px",

            fontWeight: primaryFontStyles?.fontWeight || 400,

            color: isActive ? accent1 : accent2,
            "&:hover": {
              backgroundColor: `${tertiary} !important`,
              fontFamily: primaryFontStyles?.fontFamily || "Poppins",
              fontSize: "14px",
              fontWeight: primaryFontStyles?.fontWeight || 400,
              color: accent1,
            },
          }}
        />
      ))}
    </Box>
  );
};

export default ChipSet;
