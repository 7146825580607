import { useCallback, useState } from "react";
import { onFetchTabDetails } from "../services/ApiService";
import { isPathPreview, transformKeysToCamelCase } from "../utils";
import { useLocation } from "react-router-dom";
import useCustomSnackbar from "./useCustomSnackbar";
import { ERROR_MESSAGES } from "StatusAlerts";

// Reusable hook for fetching tab details
const useTabDetails = (initialData = {}, transformFn = (data) => data) => {
  const location = useLocation();
  const snackbar = useCustomSnackbar();
  const isPreview = isPathPreview(location.pathname);
  const [loading, setLoading] = useState(true);
  const [tabData, setTabData] = useState(initialData);
  const [error] = useState(null);

  const showError = useCallback(
    (message) => {
      snackbar.showError(message, { autoHideDuration: 3000 });
    },
    [snackbar]
  );

  const fetchTabDetailsData = useCallback(
    async (activeTab) => {
      try {
        setLoading(true);

        const { result, statusCode } = await onFetchTabDetails(
          activeTab,
          isPreview
        );

        if (statusCode === 200) {
          const data = transformFn(JSON.parse(result.data));
          setTabData(data);
        } else {
          showError(ERROR_MESSAGES.FAILED_TO_FETCH_TAB_DATA);
          setTabData(initialData);
        }
      } catch (err) {
        setTabData(initialData);
        showError(ERROR_MESSAGES.UN_EXPECTED_ERROR_OCCUR);
      } finally {
        setLoading(false);
      }
    },
    [isPreview, initialData, showError, transformFn]
  );

  return { loading, tabData, error, fetchTabDetailsData };
};

// Hook for Tab 1 details
export const useTab1Details = () =>
  useTabDetails([], (data) => transformKeysToCamelCase(data));

// Hook for Tab 2 details
export const useTab2Details = () =>
  useTabDetails([], (data) => transformKeysToCamelCase(data));
