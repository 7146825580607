import React from "react";
import { Grid } from "@mui/material";
import CardSection from "components/Card/DynamicCard";
import { defaultStyles } from "styles/SpaStyles";
import { extractCallToActions, extractTextContent } from "utils";
import { imagePosition } from "Constants";

const CardList = ({ cardData, handleClick }) => {
  return (
    <Grid container spacing={3}>
      {cardData?.map((card) => {
        const { texts, image, carouselCTAs, cardType, style } = card;

        const contentArray = extractTextContent(texts);

        const cta = extractCallToActions(carouselCTAs);

        return (
          <Grid item xs={6} md={3} lg={3} key={card?.id}>
            <CardSection
              image={image?.imageURL}
              imagePosition={imagePosition[cardType]}
              contentArray={contentArray}
              defaultStyles={defaultStyles}
              // cta={cta?.[0]}
              cta={cta}
              cardStyles={style}
              imageStyles={image?.style}
              handleClick={handleClick}
              imageObject={{isExternal:image?.isExternal , linkURL:image?.linkURL}}
              buttonPosition={"top"}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CardList;
