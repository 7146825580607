import { createSlice } from "@reduxjs/toolkit";
import { onFetchTabDetails } from "services/ApiService";
import { transformKeysToCamelCase } from "utils";
import { ERROR_MESSAGES } from "StatusAlerts";

const initialState = {
  tabDetailsData: {},
};

export const tabDetailsData = createSlice({
  name: "tabComponentData",
  reducers: {
    setSecation1TabDetailsData: (state, { payload }) => ({
      ...state,
      section1tabDetailsData: payload,
    }),
    setSecation2TabDetailsData: (state, { payload }) => ({
      ...state,
      section2tabDetailsData: payload,
    }),

    clearSecation1TabDetailsData: (state) => ({
      ...state,
      section1tabDetailsData: {},
    }),
    clearSecation2TabDetailsData: (state) => ({
      ...state,
      section2tabDetailsData: {},
    }),
  },
  initialState,
});
export const {
  setSecation1TabDetailsData,
  setSecation2TabDetailsData,

  clearSecation1TabDetailsData,
  clearSecation2TabDetailsData,
} = tabDetailsData.actions;

const fetchSection1TabDetailsData = ({
  activeTab,

  onSuccessResponse,
  onErrorResponse,
}) => {
  return async (dispatch) => {
    try {
      const { result, error, statusCode } = await onFetchTabDetails(activeTab);
      if (statusCode === 200) {
        let tabDetaila = transformKeysToCamelCase(JSON.parse(result.data));
        dispatch(setSecation1TabDetailsData(tabDetaila));
        onSuccessResponse({});
      } else {
        onErrorResponse({
          response: {
            errorMessage:
              error || ERROR_MESSAGES.FAILED_TO_FETCH_TAB_COMPONENT_DATA,
          },
        });
      }
    } catch (error) {
      onErrorResponse({
        response: {
          errorMessage:
            error?.message || ERROR_MESSAGES.FAILED_TO_FETCH_TAB_COMPONENT_DATA,
        },
      });
    }
  };
};
const fetchSection2TabDetailsData = ({
  activeTab,

  onSuccessResponse,
  onErrorResponse,
}) => {
  return async (dispatch) => {
    try {
      const { result, error, statusCode } = await onFetchTabDetails(activeTab);
      if (statusCode === 200) {
        let tabDetaila = transformKeysToCamelCase(JSON.parse(result.data));
        dispatch(setSecation2TabDetailsData(tabDetaila));
        onSuccessResponse({});
      } else {
        onErrorResponse({
          response: {
            errorMessage:
              error || ERROR_MESSAGES.FAILED_TO_FETCH_TAB_COMPONENT_DATA,
          },
        });
      }
    } catch (error) {
      onErrorResponse({
        response: {
          errorMessage:
            error?.message || ERROR_MESSAGES.FAILED_TO_FETCH_TAB_COMPONENT_DATA,
        },
      });
    }
  };
};
const onClearSection1ComponentsListData = () => {
  return async (dispatch) => {
    dispatch(clearSecation1TabDetailsData());
  };
};
const onClearSection2ComponentsListData = () => {
  return async (dispatch) => {
    dispatch(clearSecation2TabDetailsData());
  };
};

const tabDetailsComponentData = {
  reducer: tabDetailsData.reducer,
  operations: {
    fetchSection1TabDetailsData,
    fetchSection2TabDetailsData,

    onClearSection1ComponentsListData,
    onClearSection2ComponentsListData,
  },
};

export default tabDetailsComponentData;
