import React from "react";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Card, CardActions, styled, Typography } from "@mui/material";
import ContentBlock from "components/ContentBlock";
import CTAButton from "components/CTAButton";
import useCustomSnackbar from "hooks/useCustomSnackbar";
import { handleCTANavigation } from "utils";
import { generateStyles } from "styles/GenerateStyles";

const ImageCard = ({
  image,
  altText = "Image",
  height = "100%",
  width = "100%",
  categoryBadgeStyles,
  cta,
  cardStyles,
  imageStyle = {},
  from,
  defaultStyles = {},
  contentArray = [],
  imageObject = {},
}) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const snackbar = useCustomSnackbar();

  const [marginTop, marginRight, marginBottom, marginLeft] = (
    imageStyle?.margin || "0"
  )
    .split(" ")
    .map((value) => parseInt(value, 10));

  const dynamicHeight = `calc(100% - ${
    (marginTop || 0) + (marginBottom || 0)
  }px)`;
  const dynamicWidth = `calc(100% - ${
    (marginLeft || 0) + (marginRight || 0)
  }px)`;
  const updatedObject = generateStyles({
    styles: {
      ...imageStyle,
      margin: "0px !important",
      padding: "0px !important",
    },
    disableBackground: false,
  });
  const updatedObject2 = generateStyles({
    styles: {
      ...imageStyle,
      margin: "0px !important",
      border: "none !important",
    },
    disableBackground: false,
  });
  const handleClick = () => {
    const { isExternal, linkURL } = imageObject;
    handleCTANavigation({
      isExternal,
      navigate,
      projectId,
      snackbar,
      url: linkURL,
    });
  };

  return (
    <Box sx={{ ...defaultStyles?.containerStyles, width, height }}>
      <StyledCard sx={defaultStyles?.defaultCardStyles} style={cardStyles}>
        <Box
          sx={{
            ...updatedObject,
            height: dynamicHeight,
            width: dynamicWidth,

            margin: imageStyle?.margin,
            position: "relative",
            cursor: "pointer",
            boxSizing: "border-box",
          }}
          onClick={handleClick}
        >
          <CardMedia
            component="img"
            image={image}
            alt={altText}
            sx={{
              ...imgContainerStyles,

              ...updatedObject2,

              border: "none !important",
            }}
          />
        </Box>

        {from === "homeLayout" && cta?.[1] &&(
          <Box sx={categoryBadgeStyles}>
            <CTAButton cta={cta?.[1]} />
          </Box>
        )}

        <CardActions sx={defaultStyles?.cardActionsStyles || cardActionStyles}>
          <Box sx={cardActionStyles}>
            {contentArray.map((item, key) => (
              <Typography variant="span" key={item?.id || `content-${key}`}>
                <ContentBlock
                  styles={item?.style}
                  title={item?.content}
                  content={item?.content}
                  tagType={item?.tagType}
                  contentHTML={item?.contentHTML}
                />
                {from === "homeLayout" && cta?.[0] && (
                  <CTAButton
                    cta={cta?.[0]}
                    extrastyles={{ boxShadow: "none !important" }}
                  />
                )}
              </Typography>
            ))}
          </Box>
        </CardActions>
      </StyledCard>
    </Box>
  );
};

export default React.memo(ImageCard);
const cardActionStyles = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
const imgContainerStyles = {
  objectFit: "cover",
  height: "100%",
  width: "100%",
  boxSizing: "border-box",
  position: "relative",

  cursor: "pointer",
};

const StyledCard = styled(Card)(({ theme, styles }) => ({
  ...generateStyles({ styles, disableBackground: false }),
  [theme.breakpoints.down("md")]: {
    height: "500px",
  },
}));
