import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { generateStyles } from "styles/GenerateStyles";
import { ImageObjectFit } from "Constants";
import useCustomSnackbar from "hooks/useCustomSnackbar";
import { handleCTANavigation } from "utils";

const Image = ({
  extraStyles = {},
  imageURL,
  objectFit = 3,
  style: styles = null,
  linkURL,
  isExternal,
  disableCTANavigation,
}) => {
  const combinedStyles = useMemo(
    () => ({
      objectFit: ImageObjectFit[objectFit],
      width: "100%",
      cursor: "pointer",
      boxSizing: "border-box",
      ...(styles ? generateStyles({ styles }) : {}),
      ...extraStyles,
    }),
    [objectFit, styles, extraStyles]
  );

  const { projectId } = useParams();
  const navigate = useNavigate();
  const snackbar = useCustomSnackbar();

  const handleClick = () => {
    if (disableCTANavigation) return;

    handleCTANavigation({
      isExternal,
      navigate,
      projectId,
      snackbar,
      url: linkURL,
    });
  };
  return (
    <img src={imageURL} alt={""} style={combinedStyles} onClick={handleClick} />
  );
};

export default Image;
