import React from "react";
import { Box } from "@mui/material";
import { Container, ContentArea, SidebarContainer } from "styles/CommonStyles";
import Sidebar from "./Sidebar";
import MobileHeader from "./Sidebar/MobileHeader";

const WithSidebar = ({ component: Component, pageId, ...rest }) => {
  return (
    <Container>
      <Box sx={{display: {xs: "flex", md: "none"}}}>
      <MobileHeader pageId={pageId}/>
      </Box>
      <Box sx={{display: {xs: "none", md: "flex"}}}>
      <SidebarContainer>
        <Sidebar selectedPageId={pageId} />
      </SidebarContainer>
      </Box>
      <ContentArea sx={{mt: {xs: 6, md: 0}}}>
        <Component {...rest} pageId={pageId} />
      </ContentArea>
    </Container>
  );
};

export default WithSidebar;
