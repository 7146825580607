
import HomeImage1 from "../assets/images/Home_carousel_img-1.jpg"
import HomeImage2 from "../assets/images/Home_carousel_img-4.png"
import HomeImage3 from "../assets/images/Home_carousel_img-3.jpg"
import HomeImage4 from "../assets/images/Home_carousel_img-2.png"

import CardImage from "assets/images/Home_cardview_img-1.jpeg";
import CardImage2 from "assets/images/Home_cardview_img-2.png";

import Image2 from "assets/images/spa_1.png";
import Image3 from "assets/images/Home_cardview_img-4.png";
import { SELECT_A_ROOM_URL, SERVICE_MENU_URL } from "Constants";

export const horizontalViews = [
    {
        "$id": "2",
        "id": "1a233ed1-cb90-422b-9d92-76a50589c49d",
        "styleId": "dde6eb13-86c7-4348-a3a6-444e1e975ef2",
        "cardType": 0,
        "viewType": "horizontal",
        "order": 0,
        "description": "A card view with ImageFirst display style.",
        "contextID": "105b1229-137a-4382-a865-ce0fa3924e17",
        "contextType": 0,
        "cardConfig": "",
        "image": {
            "$id": "3",
            "id": "ccd60dfd-80f7-4950-8371-3ba253f7831d",
            "componentId": "1a233ed1-cb90-422b-9d92-76a50589c49d",
            "contextType": 0,
            "styleId": "fd3869e2-095f-4acc-a9fd-82ceb24b8a2f",
            "imageURL": "https://dev.gems.intelity.com/images/static-images/Home/Home_cardview_img-1.png",
            "isExternal": false,
            "linkURL": null,
            "openInWindow": true,
            "imageName": "Card_Image1",
            "altText": "",
            "sizeOption": 2,
            "maximumWidth": 100,
            "maximumHeight": 200,
            "cornerRadius": 10,
            "order": 1,
            "objectFit": 3,
            "texts": null,
            "carouselCTAs": null,
            "style": {
                "$id": "4",
                "id": "fd3869e2-095f-4acc-a9fd-82ceb24b8a2f",
                "fontFamily": "Poppins",
                "fontSize": "8px",
                "fontWeight": 400,
                "fontStyle": 1,
                "textDecoration": "",
                "foregroundColor": "#000000",
                "backgroundColor": "#FFFFFF",
                "textAlignment": 1,
                "padding": "0px 0px 0px 0px",
                "margin": "0px 0px 0px 0px",
                "borderRadius": "50px",
                "borderThickness": 0,
                "borderColor": "#FFFFFF",
                "opacity": 1,
                "hoverEffect": "",
                "customCSS": "",
                "isActive": true,
                "createdBy": null,
                "createdDate": null,
                "modifiedBy": null,
                "modifiedDate": null,
                "isDeleted": false
            },
            "isActive": true,
            "createdBy": null,
            "createdDate": "2024-10-15T04:14:06.210738+00:00",
            "modifiedBy": null,
            "modifiedDate": null,
            "isDeleted": false
        },
        "texts": {
            "$id": "5",
            "$values": [
                {
                    "$id": "6",
                    "id": "58fc93f7-3d88-49c6-9a41-9e00523b9385",
                    "componentId": "1a233ed1-cb90-422b-9d92-76a50589c49d",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "81b3415e-466b-4c4c-8739-b24f05ed2632",
                    "content": "Stay and Place",
                    "contentHTML": "Stay and Place",
                    "tagType": "h2",
                    "order": 1,
                    "images": null,
                    "style": {
                        "$id": "7",
                        "id": "81b3415e-466b-4c4c-8739-b24f05ed2632",
                        "fontFamily": "Poppins",
                        "fontSize": "14px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#000000",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 2,
                        "padding": "0px 0px 0px 0px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "0",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.218389+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                },
                {
                    "$id": "8",
                    "id": "65ea16cd-9c83-431b-872b-d941444264ab",
                    "componentId": "1a233ed1-cb90-422b-9d92-76a50589c49d",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "3fc5de80-5bbe-4f48-81eb-508686ea0517",
                    "content": "Cultural Experience",
                    "contentHTML": "Cultural Experience",
                    "tagType": "h4",
                    "order": 2,
                    "images": null,
                    "style": {
                        "$id": "9",
                        "id": "3fc5de80-5bbe-4f48-81eb-508686ea0517",
                        "fontFamily": "Poppins",
                        "fontSize": "20px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#885636",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 2,
                        "padding": "0px 0px 0px 0px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "0",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.230877+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                },
                {
                    "$id": "10",
                    "id": "988354e4-214c-4e1b-8f62-49429f606664",
                    "componentId": "1a233ed1-cb90-422b-9d92-76a50589c49d",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "de0e644b-d610-4eec-9ced-3d51309619d1",
                    "content": " The Porch offers an alfresco-all-the-time atmosphere with warm hospitality, fresh-baked pastries, espresso creations, and craft cocktails shared around crackling firepits with live music daily. For poolside libations and passed snacks, cozy up to the Pool Bar’s sun-loving scene.  ",
                    "contentHTML": "<span> The Porch offers an alfresco-all-the-time atmosphere with warm hospitality, fresh-baked pastries, espresso creations, and craft cocktails shared around crackling firepits with live music daily. For poolside libations and passed snacks, cozy up to the Pool Bar’s sun-loving scene.  </span>",
                    "tagType": "p",
                    "order": 3,
                    "images": null,
                    "style": {
                        "$id": "11",
                        "id": "de0e644b-d610-4eec-9ced-3d51309619d1",
                        "fontFamily": "Poppins",
                        "fontSize": "14px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#7D7D7D",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 2,
                        "padding": "0px 0px 0px 0px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "0",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.244113+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "carouselCTAs": {
            "$id": "12",
            "$values": [
                {
                    "$id": "13",
                    "id": "7d4fa1b3-7f45-4685-963c-bd76c3bb7869",
                    "componentId": "1a233ed1-cb90-422b-9d92-76a50589c49d",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "65c1841f-1797-4283-b2a4-09f10de8d593",
                    "text": "Learn More",
                    "uRL": "https://intelity.com/",
                    "isExternal": false,
                    "images": null,
                    "style": {
                        "$id": "14",
                        "id": "65c1841f-1797-4283-b2a4-09f10de8d593",
                        "fontFamily": "Poppins",
                        "fontSize": "10px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#885636",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 2,
                        "padding": "6px 20px 6px 20px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "20px",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.251731+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "style": {
            "$id": "15",
            "id": "dde6eb13-86c7-4348-a3a6-444e1e975ef2",
            "fontFamily": "Poppins",
            "fontSize": "10px",
            "fontWeight": 400,
            "fontStyle": 1,
            "textDecoration": "",
            "foregroundColor": "#885636",
            "backgroundColor": "#FFFFFF",
            "textAlignment": 2,
            "padding": "0px 0px 0px 0px",
            "margin": "0px 0px 0px 0px",
            "borderRadius": "50px",
            "borderThickness": 0,
            "borderColor": "#FFFFFF",
            "opacity": 1,
            "hoverEffect": "",
            "customCSS": "",
            "isActive": true,
            "createdBy": null,
            "createdDate": null,
            "modifiedBy": null,
            "modifiedDate": null,
            "isDeleted": false
        }
    },
    {
        "$id": "16",
        "id": "b3a2ca33-d590-45f7-b012-2ab442811493",
        "styleId": "486bf34f-eb96-4265-ae35-bbf7f8052eca",
        "cardType": 1,
        "viewType": "horizontal",
        "order": 0,
        "description": "A card view with Image_Right display style.",
        "contextID": "105b1229-137a-4382-a865-ce0fa3924e17",
        "contextType": 0,
        "cardConfig": "",
        "image": {
            "$id": "17",
            "id": "d750f813-0884-4043-ac27-7b12a111bb69",
            "componentId": "b3a2ca33-d590-45f7-b012-2ab442811493",
            "contextType": 0,
            "styleId": "8d0e138c-691a-4949-b4d0-a2fc45ce5a26",
            "imageURL": "https://dev.gems.intelity.com/images/static-images/Home/Home_cardview_img-2.png",
            "isExternal": false,
            "linkURL": null,
            "openInWindow": true,
            "imageName": "Card_Image1",
            "altText": "",
            "sizeOption": 2,
            "maximumWidth": 100,
            "maximumHeight": 200,
            "cornerRadius": 10,
            "order": 1,
            "objectFit": 3,
            "texts": null,
            "carouselCTAs": null,
            "style": {
                "$id": "18",
                "id": "8d0e138c-691a-4949-b4d0-a2fc45ce5a26",
                "fontFamily": "Poppins",
                "fontSize": "8px",
                "fontWeight": 400,
                "fontStyle": 1,
                "textDecoration": "",
                "foregroundColor": "#000000",
                "backgroundColor": "#FFFFFF",
                "textAlignment": 1,
                "padding": "0px 0px 0px 0px",
                "margin": "0px 0px 0px 0px",
                "borderRadius": "50px",
                "borderThickness": 0,
                "borderColor": "#FFFFFF",
                "opacity": 1,
                "hoverEffect": "",
                "customCSS": "",
                "isActive": true,
                "createdBy": null,
                "createdDate": null,
                "modifiedBy": null,
                "modifiedDate": null,
                "isDeleted": false
            },
            "isActive": true,
            "createdBy": null,
            "createdDate": "2024-10-15T04:14:06.270353+00:00",
            "modifiedBy": null,
            "modifiedDate": null,
            "isDeleted": false
        },
        "texts": {
            "$id": "19",
            "$values": [
                {
                    "$id": "20",
                    "id": "ca657dfd-932d-4d83-af4d-92b4b2193289",
                    "componentId": "b3a2ca33-d590-45f7-b012-2ab442811493",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "30b2d72c-41de-4447-9029-23986971fa33",
                    "content": "Stay and Place",
                    "contentHTML": "Stay and Place",
                    "tagType": "h2",
                    "order": 1,
                    "images": null,
                    "style": {
                        "$id": "21",
                        "id": "30b2d72c-41de-4447-9029-23986971fa33",
                        "fontFamily": "Poppins",
                        "fontSize": "14px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#000000",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 1,
                        "padding": "0px 0px 0px 0px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "0",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.283968+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                },
                {
                    "$id": "22",
                    "id": "1d2b3a41-de01-458d-acbd-4f3d80890b4f",
                    "componentId": "b3a2ca33-d590-45f7-b012-2ab442811493",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "83ef9100-a981-42d9-803c-323b644a032e",
                    "content": "Explore Exciting places",
                    "contentHTML": "Explore Exciting places",
                    "tagType": "h4",
                    "order": 2,
                    "images": null,
                    "style": {
                        "$id": "23",
                        "id": "83ef9100-a981-42d9-803c-323b644a032e",
                        "fontFamily": "Poppins",
                        "fontSize": "20px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#885636",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 1,
                        "padding": "0px 0px 0px 0px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "0",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.29134+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                },
                {
                    "$id": "24",
                    "id": "76009a24-a1f5-4a66-a214-c35d66f023a5",
                    "componentId": "b3a2ca33-d590-45f7-b012-2ab442811493",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "ee9d095f-03c6-48fa-bda6-ad7f39683364",
                    "content": "California has a rich history of innovation defined by legendary artists and craftsman ",
                    "contentHTML": "<span>California has a rich history of innovation defined by legendary artists and craftsman </span>",
                    "tagType": "p",
                    "order": 3,
                    "images": null,
                    "style": {
                        "$id": "25",
                        "id": "ee9d095f-03c6-48fa-bda6-ad7f39683364",
                        "fontFamily": "Poppins",
                        "fontSize": "14px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#7D7D7D",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 1,
                        "padding": "0px 0px 0px 0px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "0",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.301794+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "carouselCTAs": {
            "$id": "26",
            "$values": [
                {
                    "$id": "27",
                    "id": "7a5e6eff-ddf7-455e-b248-047060628475",
                    "componentId": "b3a2ca33-d590-45f7-b012-2ab442811493",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "5cb3053e-29d9-4d13-a99f-828dbd09075d",
                    "text": "Learn More",
                    "uRL": "https://intelity.com/",
                    "isExternal": false,
                    "images": null,
                    "style": {
                        "$id": "28",
                        "id": "5cb3053e-29d9-4d13-a99f-828dbd09075d",
                        "fontFamily": "Poppins",
                        "fontSize": "10px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#885636",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 3,
                        "padding": "6px 20px 6px 20px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "20px",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.320497+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "style": {
            "$id": "29",
            "id": "486bf34f-eb96-4265-ae35-bbf7f8052eca",
            "fontFamily": "Poppins",
            "fontSize": "8px",
            "fontWeight": 400,
            "fontStyle": 1,
            "textDecoration": "",
            "foregroundColor": "#000000",
            "backgroundColor": "#FFFFFF",
            "textAlignment": 3,
            "padding": "0px 0px 0px 0px",
            "margin": "0px 0px 0px 0px",
            "borderRadius": "50px",
            "borderThickness": 0,
            "borderColor": "#FFFFFF",
            "opacity": 1,
            "hoverEffect": "",
            "customCSS": "",
            "isActive": true,
            "createdBy": null,
            "createdDate": null,
            "modifiedBy": null,
            "modifiedDate": null,
            "isDeleted": false
        }
    }
]
export const verticalViews = [
    {
        "$id": "30",
        "id": "d6ec43dc-a334-4cbc-8d19-f177acd45e5d",
        "styleId": "9c513609-d619-4b6a-8c5d-b67723992e81",
        "cardType": 3,
        "viewType": "vertical",
        "order": 0,
        "description": "A card view with Full_Image display style.",
        "contextID": "105b1229-137a-4382-a865-ce0fa3924e17",
        "contextType": 0,
        "cardConfig": "",
        "image": {
            "$id": "31",
            "id": "98ec9022-1955-4456-89d2-8777ac8887bb",
            "componentId": "d6ec43dc-a334-4cbc-8d19-f177acd45e5d",
            "contextType": 0,
            "styleId": "062b3d68-7522-4cf1-95ce-9440cf7b4e8f",
            "imageURL": "https://dev.gems.intelity.com/images/static-images/Home/Home_cardview_img-3.png",
            "isExternal": false,
            "linkURL": null,
            "openInWindow": true,
            "imageName": "Card_Image1",
            "altText": "",
            "sizeOption": 2,
            "maximumWidth": 100,
            "maximumHeight": null,
            "cornerRadius": 10,
            "order": 1,
            "objectFit": 3,
            "texts": null,
            "carouselCTAs": null,
            "style": {
                "$id": "32",
                "id": "062b3d68-7522-4cf1-95ce-9440cf7b4e8f",
                "fontFamily": "Poppins",
                "fontSize": "8px",
                "fontWeight": 400,
                "fontStyle": 1,
                "textDecoration": "",
                "foregroundColor": "#000000",
                "backgroundColor": "#FFFFFF",
                "textAlignment": 1,
                "padding": "0px 0px 0px 0px",
                "margin": "0px 0px 0px 0px",
                "borderRadius": "50px",
                "borderThickness": 0,
                "borderColor": "#FFFFFF",
                "opacity": 1,
                "hoverEffect": "",
                "customCSS": "",
                "isActive": true,
                "createdBy": null,
                "createdDate": null,
                "modifiedBy": null,
                "modifiedDate": null,
                "isDeleted": false
            },
            "isActive": true,
            "createdBy": null,
            "createdDate": "2024-10-15T04:14:06.33651+00:00",
            "modifiedBy": null,
            "modifiedDate": null,
            "isDeleted": false
        },
        "texts": {
            "$id": "33",
            "$values": [
                {
                    "$id": "34",
                    "id": "e42df8e5-8be0-4b12-a15f-b5c369b14f3a",
                    "componentId": "d6ec43dc-a334-4cbc-8d19-f177acd45e5d",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "d0dbaebb-dbb4-4b75-809e-adc9cb397e54",
                    "content": "Escape at Sycamore Spa",
                    "contentHTML": "Escape at Sycamore Spa",
                    "tagType": "h2",
                    "order": 1,
                    "images": null,
                    "style": {
                        "$id": "35",
                        "id": "d0dbaebb-dbb4-4b75-809e-adc9cb397e54",
                        "fontFamily": "Poppins",
                        "fontSize": "15px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#000000",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 3,
                        "padding": "0px 0px 0px 0px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "0",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.345067+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "carouselCTAs": {
            "$id": "36",
            "$values": [
                {
                    "$id": "37",
                    "id": "f0999eec-a3e9-4cb9-926b-278b99890066",
                    "componentId": "d6ec43dc-a334-4cbc-8d19-f177acd45e5d",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "dda662b5-79d5-4895-a9a4-c80241065553",
                    "text": "Learn More",
                    "uRL": "https://intelity.com/",
                    "isExternal": false,
                    "images": null,
                    "style": {
                        "$id": "38",
                        "id": "dda662b5-79d5-4895-a9a4-c80241065553",
                        "fontFamily": "Poppins",
                        "fontSize": "12px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#885636",
                        "backgroundColor": "#FFFFFF00",
                        "textAlignment": 3,
                        "padding": "4px 20px 4px 20px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "0",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.351721+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                },
                {
                    "$id": "39",
                    "id": "0d79ae99-e9ce-466c-a7d1-8c8e34921d7d",
                    "componentId": "d6ec43dc-a334-4cbc-8d19-f177acd45e5d",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "d8cae307-a0df-4dcb-be59-fb9134f7bcfa",
                    "text": "SPA",
                    "uRL": "https://intelity.com/",
                    "isExternal": false,
                    "images": null,
                    "style": {
                        "$id": "40",
                        "id": "d8cae307-a0df-4dcb-be59-fb9134f7bcfa",
                        "fontFamily": "Poppins",
                        "fontSize": "15px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#885636",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 3,
                        "padding": "6px 18px 6px 18px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "0",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.358874+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "style": {
            "$id": "41",
            "id": "9c513609-d619-4b6a-8c5d-b67723992e81",
            "fontFamily": "Poppins",
            "fontSize": "10px",
            "fontWeight": 400,
            "fontStyle": 1,
            "textDecoration": "",
            "foregroundColor": "#885636",
            "backgroundColor": "#FFFFFF",
            "textAlignment": 3,
            "padding": "0px 0px 0px 0px",
            "margin": "0px 0px 0px 0px",
            "borderRadius": "50px",
            "borderThickness": 0,
            "borderColor": "#FFFFFF",
            "opacity": 1,
            "hoverEffect": "",
            "customCSS": "",
            "isActive": true,
            "createdBy": null,
            "createdDate": null,
            "modifiedBy": null,
            "modifiedDate": null,
            "isDeleted": false
        }
    },
    {
        "$id": "42",
        "id": "940e9a2e-0576-4886-b889-9201bbc50377",
        "styleId": "f1dbb17f-f602-4ee4-8e3f-980e16e1ac7c",
        "cardType": 3,
        "viewType": "vertical",
        "order": 0,
        "description": "A card view with Full_Image display style.",
        "contextID": "105b1229-137a-4382-a865-ce0fa3924e17",
        "contextType": 0,
        "cardConfig": "",
        "image": {
            "$id": "43",
            "id": "5d900371-fb8d-476c-b739-eb7d97e1ddd2",
            "componentId": "940e9a2e-0576-4886-b889-9201bbc50377",
            "contextType": 0,
            "styleId": "a1717d09-2dfa-42c7-acbc-07b8343cd676",
            "imageURL": "https://dev.gems.intelity.com/images/static-images/Home/Home_cardview_img-4.png",
            "isExternal": false,
            "linkURL": null,
            "openInWindow": true,
            "imageName": "Card_Image1",
            "altText": "",
            "sizeOption": 2,
            "maximumWidth": 100,
            "maximumHeight": null,
            "cornerRadius": 10,
            "order": 1,
            "objectFit": 3,
            "texts": null,
            "carouselCTAs": null,
            "style": {
                "$id": "44",
                "id": "a1717d09-2dfa-42c7-acbc-07b8343cd676",
                "fontFamily": "Poppins",
                "fontSize": "8px",
                "fontWeight": 400,
                "fontStyle": 1,
                "textDecoration": "",
                "foregroundColor": "#000000",
                "backgroundColor": "#FFFFFF",
                "textAlignment": 1,
                "padding": "0px 0px 0px 0px",
                "margin": "0px 0px 0px 0px",
                "borderRadius": "50px",
                "borderThickness": 0,
                "borderColor": "#FFFFFF",
                "opacity": 1,
                "hoverEffect": "",
                "customCSS": "",
                "isActive": true,
                "createdBy": null,
                "createdDate": null,
                "modifiedBy": null,
                "modifiedDate": null,
                "isDeleted": false
            },
            "isActive": true,
            "createdBy": null,
            "createdDate": "2024-10-15T04:14:06.375986+00:00",
            "modifiedBy": null,
            "modifiedDate": null,
            "isDeleted": false
        },
        "texts": {
            "$id": "45",
            "$values": [
                {
                    "$id": "46",
                    "id": "b3942ba8-dc20-4652-91d8-a3763d130790",
                    "componentId": "940e9a2e-0576-4886-b889-9201bbc50377",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "ac142fdb-e739-44c4-bbec-608234def476",
                    "content": "Meetings",
                    "contentHTML": "Meetings",
                    "tagType": "h2",
                    "order": 1,
                    "images": null,
                    "style": {
                        "$id": "47",
                        "id": "ac142fdb-e739-44c4-bbec-608234def476",
                        "fontFamily": "Poppins",
                        "fontSize": "15px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#000000",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 3,
                        "padding": "0px 0px 0px 0px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "0",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.383171+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "carouselCTAs": {
            "$id": "48",
            "$values": [
                {
                    "$id": "49",
                    "id": "32c5ca3a-e97f-4f92-81d1-3fb2d2b193bd",
                    "componentId": "940e9a2e-0576-4886-b889-9201bbc50377",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "05f3d05b-0d52-4df3-a766-7855e3dfdfc9",
                    "text": "Learn More",
                    "uRL": "https://intelity.com/",
                    "isExternal": false,
                    "images": null,
                    "style": {
                        "$id": "50",
                        "id": "05f3d05b-0d52-4df3-a766-7855e3dfdfc9",
                        "fontFamily": "Poppins",
                        "fontSize": "12px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#885636",
                        "backgroundColor": "#FFFFFF00",
                        "textAlignment": 3,
                        "padding": "4px 20px 4px 20px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "0",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.39+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                },
                {
                    "$id": "51",
                    "id": "c6804152-d67f-4a71-8110-9753b135ac1b",
                    "componentId": "940e9a2e-0576-4886-b889-9201bbc50377",
                    "contextType": 0,
                    "imagesId": null,
                    "styleId": "0a730a98-f40f-43ae-aea3-394b416d92f5",
                    "text": "GATHER",
                    "uRL": "https://intelity.com/",
                    "isExternal": false,
                    "images": null,
                    "style": {
                        "$id": "52",
                        "id": "0a730a98-f40f-43ae-aea3-394b416d92f5",
                        "fontFamily": "Poppins",
                        "fontSize": "15px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#885636",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 3,
                        "padding": "6px 18px 6px 18px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "0",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.397506+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "style": {
            "$id": "53",
            "id": "f1dbb17f-f602-4ee4-8e3f-980e16e1ac7c",
            "fontFamily": "Poppins",
            "fontSize": "10px",
            "fontWeight": 400,
            "fontStyle": 1,
            "textDecoration": "",
            "foregroundColor": "#885636",
            "backgroundColor": "#FFFFFF",
            "textAlignment": 3,
            "padding": "0px 0px 0px 0px",
            "margin": "0px 0px 0px 0px",
            "borderRadius": "50px",
            "borderThickness": 0,
            "borderColor": "#FFFFFF",
            "opacity": 1,
            "hoverEffect": "",
            "customCSS": "",
            "isActive": true,
            "createdBy": null,
            "createdDate": null,
            "modifiedBy": null,
            "modifiedDate": null,
            "isDeleted": false
        }
    }
]
export const carouselConfig ={
    "$id": "2",
    "id": "3d0e98d4-1808-4d50-9cc1-b923e7d169ff",
    "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
    "carouselIconsId": null,
    "styleId": "3ddfef37-3030-4fea-bd5d-fb3a0f4107d6",
    "slideTransition": 1,
    "navigationStyle": "Arrows",
    "slidePerPage": 1,
    "transistionTime": 300,
    "leftNavIconId": "752b045d-0c26-47ca-9bba-8b9559a7c499",
    "rightNavIconId": "97429907-fe87-4e8c-a1b7-c63270b339a6",
    "loopSlides": true,
    "autoplay": false,
    "showNavigationArrows": true,
    "showThumbnails": false,
    "showDotNavigation": true,
    "component": null,
    "carouselIcons": null,
    "leftNavIcon": {
        "$id": "3",
        "id": "752b045d-0c26-47ca-9bba-8b9559a7c499",
        "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
        "imageId": null,
        "styleId": null,
        "content": "Sample content for the carousel item.",
        "uRL": "",
        "colorHex": "#000000",
        "opacity": 0,
        "component": null,
        "isActive": true,
        "createdBy": null,
        "createdDate": null,
        "modifiedBy": null,
        "modifiedDate": null,
        "isDeleted": false
    },
    "rightNavIcon": {
        "$id": "4",
        "id": "97429907-fe87-4e8c-a1b7-c63270b339a6",
        "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
        "imageId": null,
        "styleId": null,
        "content": "Sample content for the carousel item.",
        "uRL": "",
        "colorHex": "#000000",
        "opacity": 0,
        "component": null,
        "isActive": true,
        "createdBy": null,
        "createdDate": null,
        "modifiedBy": null,
        "modifiedDate": null,
        "isDeleted": false
    },
    "style": {
        "$id": "5",
        "id": "3ddfef37-3030-4fea-bd5d-fb3a0f4107d6",
        "fontFamily": "Poppins",
        "fontSize": "15px",
        "fontWeight": 400,
        "fontStyle": 1,
        "textDecoration": "",
        "foregroundColor": "#000000",
        "backgroundColor": "#FFFFFF00",
        "textAlignment": 1,
        "padding": "0px 0px 0px 0px",
        "margin": "0px 0px 0px 0px",
        "borderRadius": "0",
        "borderThickness": 0,
        "borderColor": "#FFFFFF",
        "opacity": 1,
        "hoverEffect": "",
        "customCSS": "",
        "isActive": true,
        "createdBy": null,
        "createdDate": null,
        "modifiedBy": null,
        "modifiedDate": null,
        "isDeleted": false
    },
    "isActive": true,
    "createdBy": "System",
    "createdDate": "2024-10-15T04:14:05.960906+00:00",
    "modifiedBy": null,
    "modifiedDate": null,
    "isDeleted": false
}

export const carouselItems = [
    {
        "$id": "7",
        "id": "1420dbe8-ab8c-4a94-ac36-25d40aab707a",
        "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
        "contextType": 4,
        "styleId": "063097fb-b378-4810-883d-51279589f2ab",
        "imageURL": HomeImage3,
        "isExternal": false,
        "linkURL": null,
        "openInWindow": true,
        "imageName": "home_Caraousal_Image3",
        "altText": "Creakside Studio suite",
        "sizeOption": 2,
        "maximumWidth": 100,
        "maximumHeight": 500,
        "cornerRadius": 10,
        "order": 3,
        "objectFit": 3,
        "texts": {
            "$id": "8",
            "$values": [
                {
                    "$id": "9",
                    "id": "dec25b20-e39f-41ce-95a2-9245c998b64d",
                    "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                    "contextType": 4,
                    "imagesId": "1420dbe8-ab8c-4a94-ac36-25d40aab707a",
                    "styleId": "121c662e-5f9c-4b81-8642-d99f3410c0f0",
                    "content": "DINE AT THE PORCH",
                    
                    "tagType": "h1",
                    "order": 1,
                    "images": {
                        "$ref": "7"
                    },
                    "style": {
                        
                    },
                    
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.111803+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                },
                {
                    "$id": "11",
                    "id": "e4b7fa36-dc22-4cb8-9004-cda49f531f18",
                    "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                    "contextType": 4,
                    "imagesId": "1420dbe8-ab8c-4a94-ac36-25d40aab707a",
                    "styleId": "1fd25232-f15f-488c-b4f6-a318721496e6",
                    "content": " The Porch offers an alfresco-all-the-time atmosphere with warm hospitality, fresh-baked pastries, espresso creations, and craft cocktails shared around crackling firepits with live music daily. For poolside libations and passed snacks, cozy up to the Pool Bar’s sun-loving scene. ",
                    
                    "tagType": "h3",
                    "order": 2,
                    "images": {
                        "$ref": "7"
                    },
                    "style": {
                        
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.121135+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "carouselCTAs": {
            "$id": "13",
            "$values": [
                {
                    "$id": "14",
                    "id": "b8877baf-b97d-4421-b6a4-639d6d0d0122",
                    "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                    "contextType": 4,
                    "imagesId": "1420dbe8-ab8c-4a94-ac36-25d40aab707a",
                    "styleId": "21933bdd-580f-4b49-afb0-a5ab5966c9ae",
                    "text": "Book Now",
                    "uRL": "https://www.theranchlb.com/dine/overview",
                    "isExternal": true,
                    "images": {
                        "$ref": "7"
                    },
                    "buttonType": "secondaryButton",
                    "style": {
                        "$id": "15",
                        "id": "21933bdd-580f-4b49-afb0-a5ab5966c9ae",
                        "fontFamily": "Poppins",
                        "fontSize": "10px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#885636",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 1,
                        "padding": "4px 20px 4px 20px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "20px",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.129312+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "style": {
            "$id": "16",
            "id": "063097fb-b378-4810-883d-51279589f2ab",
            "fontFamily": "Poppins",
            "fontSize": "8px",
            "fontWeight": 400,
            "fontStyle": 1,
            "textDecoration": "",
            "foregroundColor": "#000000",
            "backgroundColor": "#FFFFFF",
            "textAlignment": 1,
            "padding": "0px 0px 0px 0px",
            "margin": "0px 0px 0px 0px",
            "borderRadius": "40px",
            "borderThickness": 0,
            "borderColor": "#FFFFFF",
            "opacity": 1,
            "hoverEffect": "",
            "customCSS": "",
            "isActive": true,
            "createdBy": null,
            "createdDate": null,
            "modifiedBy": null,
            "modifiedDate": null,
            "isDeleted": false
        },
        "isActive": true,
        "createdBy": null,
        "createdDate": "2024-10-15T04:14:06.101838+00:00",
        "modifiedBy": null,
        "modifiedDate": null,
        "isDeleted": false
    },
    {
        "$id": "17",
        "id": "7ebd4616-1d67-43f1-8c5c-3f7028001b25",
        "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
        "contextType": 4,
        "styleId": "e74207d7-74f5-4f12-8e7e-f2367c2dc726",
        "imageURL": HomeImage4,
        "isExternal": false,
        "linkURL": null,
        "openInWindow": true,
        "imageName": "home_Caraousal_Image4",
        "altText": "Creakside Studio suite",
        "sizeOption": 2,
        "maximumWidth": 100,
        "maximumHeight": 500,
        "cornerRadius": 10,
        "order": 2,
        "objectFit": 3,
        "texts": {
            "$id": "18",
            "$values": [
                {
                    "$id": "19",
                    "id": "2662b8fb-b014-416f-936f-ed4bd5973f24",
                    "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                    "contextType": 4,
                    "imagesId": "7ebd4616-1d67-43f1-8c5c-3f7028001b25",
                    "styleId": "1558d123-3b5c-4793-bfb0-b104e3c1a247",
                    "content": "CANYON ROOM",
                    "contentHTML": "CANYON ROOM",
                    "tagType": "h1",
                    "order": 1,
                    "images": {
                        "$ref": "17"
                    },
                    "style": {
                       
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.146599+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                },
                {
                    "$id": "21",
                    "id": "d2518827-4ea7-4e02-9f86-13fa4dd90bcf",
                    "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                    "contextType": 4,
                    "imagesId": "7ebd4616-1d67-43f1-8c5c-3f7028001b25",
                    "styleId": "5b29a3ca-6ba4-4e8f-9595-05f3e1c25531",
                    "content": "Canyon Rooms feature 410 square-feet of total bliss. Nestled in nature, these private oasis offer expansive views and plenty of indoor/outdoor space to enjoy that includes an additional 160 square foot outdoor furnished patio.",
                    "contentHTML": "<span>Canyon Rooms feature 410 square-feet of total bliss. Nestled in nature, these private oasis offer expansive views and plenty of indoor/outdoor space to enjoy that includes an additional 160 square foot outdoor furnished patio.</span>",
                    "tagType": "h3",
                    "order": 2,
                    "images": {
                        "$ref": "17"
                    },
                    "style": {
                        
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.157377+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "carouselCTAs": {
            "$id": "23",
            "$values": [
                {
                    "$id": "24",
                    "id": "813ccd3f-017b-4fc2-8a45-bf6080030e3a",
                    "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                    "contextType": 4,
                    "imagesId": "7ebd4616-1d67-43f1-8c5c-3f7028001b25",
                    "styleId": "25fced13-7ee8-46b9-908c-371ddb228787",
                    "text": "Book Now",
                    "uRL": "https://be.synxis.com/?_gl=1*1vomuq8*_gcl_aw*R0NMLjE3MzA4MTUxMjcuQ2p3S0NBandfNFMzQmhBQUVpd0FfNjRZaG81d0p5UXEzRk1jSUpjUWc1Q0d6TnlxbHo3TWZGcFFhbVhSU1I1OW9HcW5BdjdkZFI3QkFCb0MyLTBRQXZEX0J3RQ..*_gcl_dc*R0NMLjE3MzA4MTUxMjcuQ2p3S0NBandfNFMzQmhBQUVpd0FfNjRZaG81d0p5UXEzRk1jSUpjUWc1Q0d6TnlxbHo3TWZGcFFhbVhSU1I1OW9HcW5BdjdkZFI3QkFCb0MyLTBRQXZEX0J3RQ..*_gcl_au*MTA2NDk5NDAxLjE3MzA4MTQxODA.*_ga*MTA0OTcwNjIwMS4xNzMwODE0MTgx*_ga_B4819V18WP*MTczMDkwNDUwNi40LjEuMTczMDkwNDYwMy41Ny4wLjA.&adult=2&arrive=2024-11-06&chain=10237&child=0&currency=USD&depart=2024-11-07&hotel=61912&level=hotel&locale=en-US&productcurrency=USD&rooms=1",
                    "isExternal": true,
                    "images": {
                        "$ref": "17"
                    },
                    "buttonType": "secondaryButton",
                    "style": {
                        "$id": "25",
                        "id": "25fced13-7ee8-46b9-908c-371ddb228787",
                        "fontFamily": "Poppins",
                        "fontSize": "10px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#885636",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 1,
                        "padding": "4px 20px 4px 20px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "20px",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.169174+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "style": {
            "$id": "26",
            "id": "e74207d7-74f5-4f12-8e7e-f2367c2dc726",
            "fontFamily": "Poppins",
            "fontSize": "8px",
            "fontWeight": 400,
            "fontStyle": 1,
            "textDecoration": "",
            "foregroundColor": "#000000",
            "backgroundColor": "#FFFFFF",
            "textAlignment": 1,
            "padding": "0px 0px 0px 0px",
            "margin": "0px 0px 0px 0px",
            "borderRadius": "40px",
            "borderThickness": 0,
            "borderColor": "#FFFFFF",
            "opacity": 1,
            "hoverEffect": "",
            "customCSS": "",
            "isActive": true,
            "createdBy": null,
            "createdDate": null,
            "modifiedBy": null,
            "modifiedDate": null,
            "isDeleted": false
        },
        "isActive": true,
        "createdBy": null,
        "createdDate": "2024-10-15T04:14:06.13874+00:00",
        "modifiedBy": null,
        "modifiedDate": null,
        "isDeleted": false
    },
    {
        "$id": "27",
        "id": "8d421634-5662-45a7-b875-5abe9d38554c",
        "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
        "contextType": 4,
        "styleId": "3c666a31-95ea-448e-90b2-73b3a2ae5557",
        "imageURL": HomeImage1,
        "isExternal": false,
        "linkURL": null,
        "openInWindow": true,
        "imageName": "home_Caraousal_Image1",
        "altText": "Creakside Studio suite",
        "sizeOption": 2,
        "maximumWidth": 100,
        "maximumHeight": 500,
        "cornerRadius": 10,
        "order": 1,
        "objectFit": 3,
        "texts": {
            "$id": "28",
            "$values": [
                {
                    "$id": "29",
                    "id": "8eeb1ad3-c065-4523-bc04-61ba35596bfe",
                    "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                    "contextType": 4,
                    "imagesId": "8d421634-5662-45a7-b875-5abe9d38554c",
                    "styleId": "10920394-0f6f-4c6b-9463-a07c27247895",
                    "content": "CREEKSIDE STUDIO SUITE",
                    "contentHTML": "CREEKSIDE STUDIO SUITE",
                    "tagType": "h1",
                    "order": 1,
                    "images": {
                        "$ref": "27"
                    },
                    "style": {
                        
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:05.995535+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                },
                {
                    "$id": "31",
                    "id": "e5d47034-c2d5-49b3-b8cd-5adf0b5d1585",
                    "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                    "contextType": 4,
                    "imagesId": "8d421634-5662-45a7-b875-5abe9d38554c",
                    "styleId": "7bdab390-eb87-4179-ba35-1618ac857b99",
                    "content": "Our Creekside Studios offer 600 square feet, plus an additional 160-square-foot patio or balcony. Studios provide king beds with a little extra space and exceptional creek and canyon views.",
                    "contentHTML": "<span>Our Creekside Studios offer 600 square feet, plus an additional 160-square-foot patio or balcony. Studios provide king beds with a little extra space and exceptional creek and canyon views.</span>",
                    "tagType": "h3",
                    "order": 2,
                    "images": {
                        "$ref": "27"
                    },
                    "style": {
                        
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.026939+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "carouselCTAs": {
            "$id": "33",
            "$values": [
                {
                    "$id": "34",
                    "id": "5316ebe5-c3c4-4b10-90ec-648500677559",
                    "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                    "contextType": 4,
                    "imagesId": "8d421634-5662-45a7-b875-5abe9d38554c",
                    "styleId": "78d442ef-8033-48e1-8f60-27a026d1dc72",
                    "text": "Book Now",
                    "uRL": "https://be.synxis.com/?_gl=1*1vomuq8*_gcl_aw*R0NMLjE3MzA4MTUxMjcuQ2p3S0NBandfNFMzQmhBQUVpd0FfNjRZaG81d0p5UXEzRk1jSUpjUWc1Q0d6TnlxbHo3TWZGcFFhbVhSU1I1OW9HcW5BdjdkZFI3QkFCb0MyLTBRQXZEX0J3RQ..*_gcl_dc*R0NMLjE3MzA4MTUxMjcuQ2p3S0NBandfNFMzQmhBQUVpd0FfNjRZaG81d0p5UXEzRk1jSUpjUWc1Q0d6TnlxbHo3TWZGcFFhbVhSU1I1OW9HcW5BdjdkZFI3QkFCb0MyLTBRQXZEX0J3RQ..*_gcl_au*MTA2NDk5NDAxLjE3MzA4MTQxODA.*_ga*MTA0OTcwNjIwMS4xNzMwODE0MTgx*_ga_B4819V18WP*MTczMDkwNDUwNi40LjEuMTczMDkwNDYwMy41Ny4wLjA.&adult=2&arrive=2024-11-06&chain=10237&child=0&currency=USD&depart=2024-11-07&hotel=61912&level=hotel&locale=en-US&productcurrency=USD&rooms=1",
                    "isExternal": true,
                    "images": {
                        "$ref": "27"
                    },
                    "buttonType": "secondaryButton",
                    "style": {
                        "$id": "35",
                        "id": "78d442ef-8033-48e1-8f60-27a026d1dc72",
                        "fontFamily": "Poppins",
                        "fontSize": "10px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#885636",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 1,
                        "padding": "4px 20px 4px 20px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "20px",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.039646+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "style": {
            "$id": "36",
            "id": "3c666a31-95ea-448e-90b2-73b3a2ae5557",
            "fontFamily": "Poppins",
            "fontSize": "8px",
            "fontWeight": 400,
            "fontStyle": 1,
            "textDecoration": "",
            "foregroundColor": "#000000",
            "backgroundColor": "#FFFFFF",
            "textAlignment": 1,
            "padding": "0px 0px 0px 0px",
            "margin": "0px 0px 0px 0px",
            "borderRadius": "40px",
            "borderThickness": 0,
            "borderColor": "#FFFFFF",
            "opacity": 1,
            "hoverEffect": "",
            "customCSS": "",
            "isActive": true,
            "createdBy": null,
            "createdDate": null,
            "modifiedBy": null,
            "modifiedDate": null,
            "isDeleted": false
        },
        "isActive": true,
        "createdBy": null,
        "createdDate": "2024-10-15T04:14:05.969215+00:00",
        "modifiedBy": null,
        "modifiedDate": null,
        "isDeleted": false
    },
    {
        "$id": "37",
        "id": "eecc7cd0-5943-46bf-9ea4-517b35efc226",
        "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
        "contextType": 4,
        "styleId": "af699e78-78e0-4807-90df-c0119004ed38",
        "imageURL": HomeImage2,
        "isExternal": false,
        "linkURL": null,
        "openInWindow": true,
        "imageName": "home_Caraousal_Image2",
        "altText": "Creakside Studio suite",
        "sizeOption": 2,
        "maximumWidth": 100,
        "maximumHeight": 500,
        "cornerRadius": 10,
        "order": 4,
        "objectFit": 3,
        "texts": {
            "$id": "38",
            "$values": [
                {
                    "$id": "39",
                    "id": "515b054c-e4d2-456c-be74-ef4e0200de13",
                    "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                    "contextType": 4,
                    "imagesId": "eecc7cd0-5943-46bf-9ea4-517b35efc226",
                    "styleId": "032708bb-881b-4db8-9f56-fa42d2e21e1e",
                    "content": "Bring the whole family for our signature pancakes station, ornament decorating, pictures with Santa, holiday sing-a-long, face painting and more!",
                    "contentHTML": "<span>Bring the whole family for our signature pancakes station, ornament decorating, pictures with Santa, holiday sing-a-long, face painting and more!</span>",
                    "tagType": "h3",
                    "order": 2,
                    "images": {
                        "$ref": "37"
                    },
                    "style": {
                        
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.079191+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                },
                {
                    "$id": "41",
                    "id": "bec90c29-f223-47a9-94cd-b375b8661d3f",
                    "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                    "contextType": 4,
                    "imagesId": "eecc7cd0-5943-46bf-9ea4-517b35efc226",
                    "styleId": "6633e028-d0ce-4049-838c-cd7f4feabd14",
                    "content": "RESERVE AN EXPERIENCE",
                    "contentHTML": "RESERVE AN EXPERIENCE",
                    "tagType": "h1",
                    "order": 1,
                    "images": {
                        "$ref": "37"
                    },
                    "style": {
                        
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.066148+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "carouselCTAs": {
            "$id": "43",
            "$values": [
                {
                    "$id": "44",
                    "id": "2779541a-17ee-4de0-943c-25f82796e6a0",
                    "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                    "contextType": 4,
                    "imagesId": "eecc7cd0-5943-46bf-9ea4-517b35efc226",
                    "styleId": "79a23794-f93b-47f8-874d-cb2866a5c25e",
                    "text": "Book Now",
                    "uRL": "https://www.theranchlb.com/play/experiences#!/event/67858475-afe6-4b8a-930f-a076c2a7a721?preselectedDate=2024-12-22",
                    "isExternal": true,
                    "images": {
                        "$ref": "37"
                    },
                    "buttonType": "secondaryButton",
                    "style": {
                        "$id": "45",
                        "id": "79a23794-f93b-47f8-874d-cb2866a5c25e",
                        "fontFamily": "Poppins",
                        "fontSize": "10px",
                        "fontWeight": 400,
                        "fontStyle": 1,
                        "textDecoration": "",
                        "foregroundColor": "#885636",
                        "backgroundColor": "#FFFFFF",
                        "textAlignment": 1,
                        "padding": "4px 20px 4px 20px",
                        "margin": "0px 0px 0px 0px",
                        "borderRadius": "20px",
                        "borderThickness": 0,
                        "borderColor": "#FFFFFF",
                        "opacity": 1,
                        "hoverEffect": "",
                        "customCSS": "",
                        "isActive": true,
                        "createdBy": null,
                        "createdDate": null,
                        "modifiedBy": null,
                        "modifiedDate": null,
                        "isDeleted": false
                    },
                    "isActive": true,
                    "createdBy": null,
                    "createdDate": "2024-10-15T04:14:06.090095+00:00",
                    "modifiedBy": null,
                    "modifiedDate": null,
                    "isDeleted": false
                }
            ]
        },
        "style": {
            "$id": "46",
            "id": "af699e78-78e0-4807-90df-c0119004ed38",
            "fontFamily": "Poppins",
            "fontSize": "8px",
            "fontWeight": 400,
            "fontStyle": 1,
            "textDecoration": "",
            "foregroundColor": "#000000",
            "backgroundColor": "#FFFFFF",
            "textAlignment": 1,
            "padding": "0px 0px 0px 0px",
            "margin": "0px 0px 0px 0px",
            "borderRadius": "40px",
            "borderThickness": 0,
            "borderColor": "#FFFFFF",
            "opacity": 1,
            "hoverEffect": "",
            "customCSS": "",
            "isActive": true,
            "createdBy": null,
            "createdDate": null,
            "modifiedBy": null,
            "modifiedDate": null,
            "isDeleted": false
        },
        "isActive": true,
        "createdBy": null,
        "createdDate": "2024-10-15T04:14:06.05727+00:00",
        "modifiedBy": null,
        "modifiedDate": null,
        "isDeleted": false
    }
]

export const horizontalCardData =  [
    {
      title: "Stay and Play",
      subTitle: "HIIT Fitness",
      description:
        "California has a rich history of innovation defined by legendary artists and craftsman ",
      image: CardImage,
      imagePosition: "left",
      titleVariant: "h5",
      subTitleVariant: "h4",
      descriptionVariant: "p",
      buttonType: "secondaryButton",
      id:"1",
      CTALink:"/Fit01",
      isCTAExternal:false,
  
    },
    {
      title: "Stay and Play",
      subTitle: "Fairways Golf",
      description:
        "California has a rich history of innovation defined by legendary artists and craftsman ",
      image: CardImage2,
      imagePosition: "right",
      titleVariant: "h5",
      subTitleVariant: "h4",
      descriptionVariant: "p",
      buttonType: "secondaryButton",
      id:"2",
      CTALink:"/Map01",
      isCTAExternal:false,

    },
  ]

  export const verticalCardData =  [
    {
      image: Image2,
      title: "Escape at Sycamore Spa",
      category: "Book Now",
      titleVariant: "h5",
      color: "#5B7961",
      id:"1",
      categoryCTALink: SERVICE_MENU_URL,
      buttonType: "fourthButton",
      CTALink:"/Spa01",
      isCTAExternal:false,

    },
    {
      image: Image3,
      title: "Pillows & Pancakes",
      category: "Book Now",
      titleVariant: "h5",
      color: "#5B7961",
      id:"2",
      categoryCTALink: SELECT_A_ROOM_URL,
      buttonType: "fourthButton",
      CTALink:"/Offer",
      isCTAExternal:false,

    },
  ]