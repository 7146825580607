import React, { useEffect, useMemo } from "react";
import {  ThemeProvider, createTheme } from "@mui/material";
import { Route, Routes, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Landing from "pages/Landing";
import Home from "pages/Home";
import ProjectPageNavigator from "pages/ProjectPageNavigator";
import Fitness from "pages/Fitness";
import Spa from "pages/Spa";
import Map from "pages/Map";
import Menu from "pages/Menu";
import NoRouteFound from "../NoRouteFound";
import { LayoutNames } from "Constants";
import { useProjectPages } from "hooks/useProjectPages";
import NoDataFound from "components/NoDataContainer";
import Loader from "components/Loader";
import WithSidebar from "pages/WithSidebar";
import { useProjectBranding } from "hooks/useProjectBranding";

// Map of layout codes to components
const layoutComponentsMap = {
  [LayoutNames.Landing]: Landing,
  [LayoutNames.Home]: Home,
  [LayoutNames.Fitness]: Fitness,
  [LayoutNames.Fitness2]: Fitness,
  [LayoutNames.Map]: Map,
  [LayoutNames.Offer]: Menu,
  [LayoutNames.Spa]: Spa,
};

const DynamicRoutes = () => {
  // Extract projectId from the URL
  const { projectId } = useParams();
  const { pagesList } = useSelector((state) => state.pages);

  //   Fetch the project pages using projectId
  const { loading, errorMessage, fetchProjectPages } = useProjectPages("");

  const { loading: brandLoading, branding } = useProjectBranding(projectId);

  const muiTheme = useMemo(() => {
    const {
      primary = "#fff",
      secondary = "#fff",
      tertiary = "#fff",
      accent1 = "#fff",
      accent2 = "#fff",
      primaryFontProperties,
      primaryFontWeight,
      
    } = branding?.projectBrandTheme || {};

    return createTheme({
      palette: {
        primary: { main: primary },
        secondary: { main: secondary },
        tertiary: tertiary,
        accent1: accent1,
        accent2:accent2,
        primaryFontStyles:{
          fontFamily:primaryFontProperties,
          fontWeight:primaryFontWeight,
        },
        
      },
      logo: branding?.logoURL,
      appName: branding?.name || "",

    });
  }, [branding]);

  useEffect(() => {
    if (projectId) {
      fetchProjectPages(projectId);
    }
  }, [projectId, fetchProjectPages]);

  if (loading || brandLoading) return <Loader />;
  if (errorMessage) return <NoDataFound title={errorMessage} />;

  // Function to render routes
  const renderRoutes = () => {
    return pagesList.map((page) => {
      const LayoutComponent = layoutComponentsMap[page.layoutCode];
      const needsSidebar = page.layoutCode !== LayoutNames.Landing;

      if (!LayoutComponent) {
        return (
          <Route key={page.pageSlug} path="*" element={<NoRouteFound />} />
        );
      }

      return (
        <Route
          key={`${page.pageSlug}-${page.id}`}
          path={`/${page.pageSlug}`}
          element={
            needsSidebar ? (
              <WithSidebar component={LayoutComponent} pageId={page.id} />
            ) : (
              <LayoutComponent pageId={page.id} />
            )
          }
        />
      );
    });
  };

  return (
    <ThemeProvider theme={muiTheme}>
      
      
      <Routes>
        <Route path="/" element={<ProjectPageNavigator />} />
        {renderRoutes()}
        <Route path="*" element={<NoRouteFound />} />
      </Routes>
    </ThemeProvider>
  );
};

export default DynamicRoutes;
