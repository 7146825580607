import {
  borderStyleMap,
  buttonPosition,
  fontStyleMap,
  fontWeightMap,
  textAlignMap,
  textTransformMap,
} from "Constants";
import { transformKeysToCamelCase } from "../utils";

export const generateStyles = ({ styles = {}, disableBackground = false }) => {
  // Convert to camel case first
  const camelCaseFontStyles = transformKeysToCamelCase(styles);
  const {
    fontFamily = "Arial",
    fontSize = "16px",
    fontWeight = 400,
    fontStyle = 1,
    textDecoration = "none",
    foregroundColor = "#000000",
    backgroundColor = "transparent",
    textAlignment = 0,
    padding = "0px",
    margin = "0px",
    borderRadius = 0,
    borderThickness = 0,
    transform = 0,
    borderColor = "#000000",
    borderStyle = 0,
    opacity = 1,
    hoverEffect = "",
  } = camelCaseFontStyles;
  const hoverColorMatch = hoverEffect.match(/color:\s*([^;]*)/);
  const hoverColor = hoverColorMatch ? hoverColorMatch[1] : foregroundColor;
  return {
    fontFamily,
    fontSize,
    fontWeight: fontWeightMap[fontWeight] || fontWeightMap[400],
    fontStyle: fontStyleMap[fontStyle] || fontStyleMap[1],
    textDecoration,
    color: foregroundColor,
    backgroundColor: disableBackground ? "transparent" : backgroundColor,
    textAlign: textAlignMap[textAlignment] || textAlignMap[0],
    padding,
    margin,
    borderRadius: `${borderRadius}`,
    textTransform: textTransformMap[transform],
    lineHeight: "normal",
    border: borderThickness
      ? `${borderThickness}px solid ${borderColor}`
      : "none !important",
      borderStyle: borderStyleMap[borderStyle],
    opacity,
    "&:hover": !disableBackground && {
      backgroundColor,
      color: hoverColor,
    },
  };
};

export const transformStyles = (imageStyles) => {
  const { foregroundColor, borderThickness, borderColor, ...rest } =
    imageStyles;

  return {
    ...rest,
    color: foregroundColor,
    borderWidth: borderThickness,
    border: `${borderThickness}px solid ${borderColor}`,
  };
};

export const buttonAlignment = (justification) => ({
  justifyContent: buttonPosition[justification],
  padding: "10px",
});
