import { SELECT_A_ROOM_URL } from "Constants";
import { handleCTA } from "utils";
import OfferImage from "assets/images/Mask group (7).png";

export const imageComponentData = {
  $id: "1",
  $values: [
    {
      $id: "2",
      id: "11a0d9e8-21ab-4f7f-92fd-8c98981d0d06",
      componentId: "be985752-8b84-40e6-94b3-2d802966beaf",
      imageURL: OfferImage,
      cornerRadius: 10,
      isExternal: false,
      linkURL: null,
      maximumHeight: 354,
      maximumWidth: 1232,
      openInWindow: true,
      sizeOption: 2,
      style: {
        $id: "3",
        id: "3cabf19e-b402-41e1-b2a1-485f1fe0e0ff",
        backgroundColor: "#FFFFFF",
        borderColor: "#FFFFFF",
        borderRadius: "40px",
        foregroundColor: "#000000",
        margin: "0px 0px 0px 0px",
        fontSize: "8px",
        borderThickness: 0,
        fontFamily: "Poppins",
        fontWeight: 400,
        fontStyle: 1,
        padding: "0px 0px 0px 0px",
        opacity: 1,
        textAlignment: 1,
        textDecoration: "",
        customCSS: "",
      },
      texts: {
        $id: "4",
        $values: [],
      },
      carouselCTAs: {
        $id: "7",
        $values: [],
      },
    },
  ],
};

const VISIT_ACTIVITIES = "https://intelity.com/";
export const menuData = [
  {
    id: "1",
    content: [
      {
        id: "1.1",
        content: "Day 1",
        style: { textAlignment: 3 },
        tagType: "h5",
      },
      {
        id: "1.2",
        content: "Mon 18 November, 2024",
        style: {
          textAlignment: 3,
          margin: "5px 0px 10px 0px",
        },
        tagType: "h6",
      },
      {
        id: "1.3",
        content:
          "Start the event with an inspiring keynote session and an introduction to the key themes. Set the stage with foundational insights and networking opportunities.",
        tagType: "p",
        style: { textAlignment: 3  , margin:"0px 0px 16px 0px"},
      },
    ],
    cta: [
      {
        id: "1.4",
        text: "Visit Activities",
        isExternal: true,
        uRL: VISIT_ACTIVITIES,
        buttonType: "primaryButton",
        style: {
          backgroundColor: "#DDE8E9",
          foregroundColor: "#123236",
          borderRadius: "30px",
          padding: "10px 20px 10px 20px",
          textAlignment: 3,
          width: "100%",
        },
        action: () => handleCTA(true, SELECT_A_ROOM_URL),
      },
    ],
  },
  {
    id: "2",
    content: [
      {
        id: "2.1",
        content: "Day 2",
        style: { textAlignment: 3 },
        tagType: "h5",
      },
      {
        id: "2.2",
        content: "Tue 19 November, 2024",
        style: {
          textAlignment: 3,
          margin: "5px 0px 10px 0px",
        },
        tagType: "h6",
      },
      {
        id: "2.3",
        content:
          "Dive deeper into the expertise of Speaker 1 through interactive sessions and discussions. Explore hands-on learning tailored to their unique field.",
        tagType: "p",
        style: { textAlignment: 3  , margin:"0px 0px 16px 0px"},
      },
    ],
    cta: [
      {
        id: "2.4",
        text: "Visit Activities",
        isExternal: true,
        uRL: VISIT_ACTIVITIES,
        buttonType: "primaryButton",
        style: {
          backgroundColor: "#DDE8E9",
          foregroundColor: "#123236",
          borderRadius: "30px",
          padding: "10px 20px 10px 20px",
          textAlignment: 3,
          width: "100%",
        },
        action: () => handleCTA(true, SELECT_A_ROOM_URL),
      },
    ],
  },
  {
    id: "3",
    content: [
      {
        id: "3.1",
        content: "Day 3",
        style: { textAlignment: 3 },
        tagType: "h5",
      },
      {
        id: "3.2",
        content: "Wed 20 November, 2024",
        style: {
          textAlignment: 3,
          margin: "5px 0px 10px 0px",
        },
        tagType: "h6",
      },
      {
        id: "3.3",
        content:
          "Engage with Speaker 2 as they lead a thought-provoking session followed by a hands-on workshop, offering practical tools for immediate application.", // Empty description for Day 3
        tagType: "p",
        style: { textAlignment: 3  , margin:"0px 0px 16px 0px"},
      },
    ],
    cta: [
      {
        id: "3.4",
        text: "Visit Activities",
        isExternal: true,
        uRL: VISIT_ACTIVITIES,
        buttonType: "primaryButton",
        style: {
          backgroundColor: "#DDE8E9",
          foregroundColor: "#123236",
          borderRadius: "30px",
          padding: "10px 20px 10px 20px",
          textAlignment: 3,
          width: "100%",
        },
        action: () => handleCTA(true, SELECT_A_ROOM_URL),
      },
    ],
  },
  {
    id: "4",
    content: [
      {
        id: "4.1",
        content: "Day 4",
        style: { textAlignment: 3 },
        tagType: "h5",
      },
      {
        id: "4.2",
        content: "Thu 21 November, 2024",
        style: {
          textAlignment: 3,
          margin: "5px 0px 10px 0px",
        },
        tagType: "h6",
      },
      {
        id: "4.3",
        content:
          "Expand your perspective with Speaker 3’s dynamic talk and collaborative breakout sessions designed to foster innovative thinking.",
        tagType: "p",
        style: { textAlignment: 3  , margin:"0px 0px 16px 0px"},
      },
    ],
    cta: [
      {
        id: "4.4",
        text: "Visit Activities",
        isExternal: true,
        uRL: VISIT_ACTIVITIES,
        buttonType: "primaryButton",
        style: {
          backgroundColor: "#DDE8E9",
          foregroundColor: "#123236",
          borderRadius: "30px",
          padding: "10px 20px 10px 20px",
          textAlignment: 3,
          width: "100%",
        },
        action: () => handleCTA(true, SELECT_A_ROOM_URL),
      },
    ],
  },
  {
    id: "5",
    content: [
      {
        id: "5.1",
        content: "Day 5",
        style: { textAlignment: 3 },
        tagType: "h5",
      },
      {
        id: "5.2",
        content: "Fri 22 November, 2024",
        style: {
          textAlignment: 3,
          margin: "5px 0px 10px 0px",
        },
        tagType: "h6",
      },
      {
        id: "5.3",
        content:
          "Learn from Speaker 4 in an in-depth presentation followed by an exclusive Q&A session, allowing for deeper engagement with the audience.",
        tagType: "p",
        style: { textAlignment: 3  , margin:"0px 0px 16px 0px"},
      },
    ],
    cta: [
      {
        id: "5.4",
        text: "Visit Activities",
        isExternal: true,
        uRL: VISIT_ACTIVITIES,
        buttonType: "primaryButton",
        style: {
          backgroundColor: "#DDE8E9",
          foregroundColor: "#123236",
          borderRadius: "30px",
          padding: "10px 20px 10px 20px",
          textAlignment: 3,
          width: "100%",
        },
        action: () => handleCTA(true, SELECT_A_ROOM_URL),
      },
    ],
  },
  {
    id: "6",
    content: [
      {
        id: "6.1",
        content: "Day 6",
        style: { textAlignment: 3 },
        tagType: "h5",
      },
      {
        id: "6.2",
        content: "Sat 23 November, 2024",
        style: {
          textAlignment: 3,
          margin: "5px 0px 10px 0px",
        },
        tagType: "h6",
      },
      {
        id: "6.3",
        content:
          "Learn from Speaker 5 in an in-depth presentation followed by an exclusive Q&A session, allowing for deeper engagement with the audience.",
        tagType: "p",
        style: { textAlignment: 3  , margin:"0px 0px 16px 0px"},
      },
    ],
    cta: [
      {
        id: "6.4",
        text: "Visit Activities",
        isExternal: true,
        uRL: VISIT_ACTIVITIES,
        buttonType: "primaryButton",
        style: {
          backgroundColor: "#DDE8E9",
          foregroundColor: "#123236",
          borderRadius: "30px",
          padding: "10px 20px 10px 20px",
          textAlignment: 3,
          width: "100%",
        },
        action: () => handleCTA(true, SELECT_A_ROOM_URL),
      },
    ],
  },
  {
    id: "7",
    content: [
      {
        id: "7.1",
        content: "Day 7",
        style: { textAlignment: 3 },
        tagType: "h5",
      },
      {
        id: "7.2",
        content: "Sun 24 November, 2024",
        style: {
          textAlignment: 3,
          margin: "5px 0px 10px 0px",
        },
        tagType: "h6",
      },
      {
        id: "7.3",
        content:
          "Learn from Speaker 6 in an in-depth presentation followed by an exclusive Q&A session, allowing for deeper engagement with the audience.",
        tagType: "p",
        style: { textAlignment: 3  , margin:"0px 0px 16px 0px"},
      },
    ],
    cta: [
      {
        id: "7.4",
        text: "Visit Activities",
        isExternal: true,
        uRL: VISIT_ACTIVITIES,
        buttonType: "primaryButton",
        style: {
          backgroundColor: "#DDE8E9",
          foregroundColor: "#123236",
          borderRadius: "30px",
          padding: "10px 20px 10px 20px",
          textAlignment: 3,
          width: "100%",
        },
        action: () => handleCTA(true, SELECT_A_ROOM_URL),
      },
    ],
  },
  {
    id: "8",
    content: [
      {
        id: "8.1",
        content: "Day 8",
        style: { textAlignment: 3 },
        tagType: "h5",
      },
      {
        id: "8.2",
        content: "Mon 25 November, 2024",
        style: {
          textAlignment: 3,
          margin: "5px 0px 10px 0px",
        },
        tagType: "h6",
      },
      {
        id: "8.3",
        content:
          "Conclude the event with a final panel discussion, key takeaways from all speakers, and a dedicated networking session to connect with peers and speakers.",
        tagType: "p",
        style: { textAlignment: 3  , margin:"0px 0px 16px 0px"},
      },
    ],
    cta: [
      {
        id: "8.4",
        text: "Visit Activities",
        isExternal: true,
        uRL: VISIT_ACTIVITIES,
        buttonType: "primaryButton",
        style: {
          backgroundColor: "#DDE8E9",
          foregroundColor: "#123236",
          borderRadius: "30px",
          padding: "10px 20px 10px 20px",
          textAlignment: 3,
          width: "100%",
        },
        action: () => handleCTA(true, SELECT_A_ROOM_URL),
      },
    ],
  },
];
