import React from "react";
import { CircularProgress, styled } from "@mui/material";

const Loader = ({size = 50, color = "secondary", fullPage = true, left}) => (
  <Container fullPage={fullPage} sx={{ left }}>
    <CircularProgress size={size} color={color} />
  </Container>
);

export default Loader;


const Container = styled("div")(({ fullPage }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: fullPage ? "absolute" : "relative",
  top: fullPage ? 0 : 'auto',
  left: fullPage ? 0 : 'auto',
  right: fullPage ? 0 : 'auto',
  bottom: fullPage ? 0 : 'auto',
  zIndex: 2,
  height: fullPage ? "100vh" : "50vh",
  width: fullPage ? "" : "auto",
}))