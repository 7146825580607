import React, { useEffect, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";

import CarouselSlider from "components/CarouselSlider";
import Loader from "components/Loader/Loader";
import HeaderWithTags from "components/HeaderWithTags/HeaderWithTags";

import CardGridSection from "./components/CardSection";
import ImageCardGridSection from "./components/ImageSection";

import { ComponentTypes } from "Constants";
import { transformKeysToCamelCase } from "utils";

import {
  selectComponentDataByType,
  selectPageComponents,
} from "reduxStore/selectors/PageSelectors";
import { usePageComponents } from "hooks/usePageComponents";

import { defaultStyles } from "styles/HomeStyles";

const Home = ({ pageId }) => {
  const { loading, fetchPageComponents } = usePageComponents();
  const { pageHtmlTags } = useSelector(selectPageComponents);
  const carouselComponents = useSelector(
    selectComponentDataByType(pageId, ComponentTypes.Carousel)
  );
  const carouselData = useMemo(() => {
    const [data = {}] = carouselComponents || [];
    return data;
  }, [carouselComponents]);

  const cardData = useSelector(
    selectComponentDataByType(pageId, ComponentTypes.CardGroup)
  );
  const { carouselConfig = {}, carousalImages = {} } = carouselData || {};
  const carouselItems = carousalImages?.["$values"] || [];

  const config = useMemo(() => {
    const cardGroup = cardData?.flatMap((item) => item["$values"] || []) || [];
    return transformKeysToCamelCase(cardGroup);
  }, [cardData]);

  useEffect(() => {
    if (pageId) {
      fetchPageComponents(pageId);
    }
  }, [pageId, fetchPageComponents]);

  const horizontalViews = config
    ?.filter((value) => value.viewType === "horizontal")
    ?.sort((a, b) => a.order - b.order);
  const verticalViews = config
    ?.filter((value) => value.viewType === "vertical")
    ?.sort((a, b) => a.order - b.order);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {pageHtmlTags?.length > 0 && (
            <HeaderWithTags pageHtmlTags={pageHtmlTags} />
          )}
          {carouselItems?.length > 0 && (
            <Box sx={{ position: "relative", overflow: "hidden" }}>
              <CarouselSlider
                carouselConfig={carouselConfig}
                carouselItems={carouselItems}
                carouselStyle={defaultStyles.carouselStyles}
              />
            </Box>
          )}
          <Box mt={2}>
            <Grid container spacing={3}>
              <CardGridSection
                defaultStyles={defaultStyles}
                horizontalCardsData={horizontalViews}
              />

              <ImageCardGridSection verticalViews={verticalViews} />
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default Home;
