import React from "react";
import { Grid } from "@mui/material";
import ImageCardSection from "components/Card/DynamicImageCard";
import { defaultStyles } from "styles/HomeStyles";
import { extractCallToActions, extractTextContent } from "utils";

const ImageCardGridSection = ({ secondaryColor, verticalViews }) => (
  <Grid item xs={12} sm={12} md={6} lg={6} container spacing={2}>
    {verticalViews.map((card) => {
      const { texts, image, carouselCTAs, style } = card;

      // Extract texts and styles

      const contentArray = extractTextContent(texts);
      // Extract CTA (Call To Actions)

      const cta = extractCallToActions(carouselCTAs);

      return (
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          // sx={{ maxHeight: "632px" }}
          key={card?.id}
        >
          <ImageCardSection
            image={image?.imageURL}
            color={secondaryColor}
            defaultStyles={defaultStyles}
            cta={cta}
            cardStyles={{ ...style }}
            categoryBadgeStyles={defaultStyles?.categoryBadgeStyles}
            contentArray={contentArray}
            imageStyle={image?.style}
            imageObject={{isExternal:image?.isExternal , linkURL:image?.linkURL}}
            from="homeLayout"
          />
        </Grid>
      );
    })}
  </Grid>
);

export default ImageCardGridSection;
