import { createSlice } from "@reduxjs/toolkit";
import { onFetchComponentDataById } from "services/ApiService";
import { transformKeysToCamelCase } from "utils";
import { ERROR_MESSAGES } from "StatusAlerts";

const initialState = {
  componentData: {},
  componentsListData: {},
};

export const pageComponentDataSlice = createSlice({
  name: "pageComponentData",
  reducers: {
    setPageComponentData: (state, { payload }) => ({
      ...state,
      componentData: payload,
    }),
    setPageComponentsListData: (state, { payload }) => {
      payload?.forEach(({ projectPageId, componentType, data }) => {
        if (!state.componentsListData[projectPageId]) {
          state.componentsListData[projectPageId] = {};
        }
        if (!state.componentsListData[projectPageId][componentType]) {
          state.componentsListData[projectPageId][componentType] = [];
        }
        const transformedData = transformKeysToCamelCase(data);
        state.componentsListData[projectPageId][componentType].push(
          transformedData
        );
      });
    },
    clearComponentsListData: (state) => ({
      ...state,
      componentsListData: {},
    }),
  },
  initialState,
});
export const {
  setPageComponentData,
  setPageComponentsListData,
  clearComponentsListData,
} = pageComponentDataSlice.actions;

const fetchComponentDataById = ({
  componentId,
  componentType,
  onSuccessResponse,
  onErrorResponse,
}) => {
  return async (dispatch) => {
    try {
      const { result, error, statusCode } = await onFetchComponentDataById(
        componentId,
        componentType
      );
      if (statusCode === 200) {
        dispatch(setPageComponentData(JSON.parse(result.data)));
        onSuccessResponse({});
      } else {
        onErrorResponse({
          response: {
            errorMessage: error || ERROR_MESSAGES.FAILED_TO_FETCH_PROJECT_PAGES,
          },
        });
      }
    } catch (error) {
      onErrorResponse({
        response: {
          errorMessage:
            error?.message || ERROR_MESSAGES.FAILED_TO_FETCH_PROJECT_PAGES,
        },
      });
    }
  };
};
const onClearComponentsListData = () => {
  return async (dispatch) => {
    dispatch(clearComponentsListData());
  };
};
const fetchComponentsListDataByIds = ({
  pageComponents,
  isPreview,
  onSuccessResponse,
  onErrorResponse,
}) => {
  return async (dispatch) => {
    try {
      const allComponentsData = [];

      const componentFetchPromises = pageComponents?.map(
        async (component, index) => {
          const {
            id,
            projectPageId,
            componentType,
            componentOrder = index,
          } = component;
          try {
            const { result, error, statusCode } =
              await onFetchComponentDataById(id, componentType, isPreview);

            if (statusCode === 200) {
              const data = JSON.parse(result.data);
              const newData = { ...data, componentOrder };

              allComponentsData.push({
                projectPageId,
                componentType,
                data: newData,
              });
            } else {
              throw new Error(
                error || ERROR_MESSAGES.FAILED_TO_FETCH_PROJECT_COMPONENT_DATA
              );
            }
          } catch (error) {
            throw new Error(
              error.message ||
                ERROR_MESSAGES.FAILED_TO_FETCH_PROJECT_COMPONENT_DATA
            );
          }
        }
      );

      // Wait for all the fetch requests to complete
      await Promise.all(componentFetchPromises);

      if (allComponentsData.length > 0) {
        dispatch(setPageComponentsListData(allComponentsData)); // Pass all data at once
      }

      // If all requests are successful
      onSuccessResponse({});
    } catch (error) {
      // Handle any error from any of the fetch requests
      onErrorResponse({
        response: {
          errorMessage:
            error?.message || ERROR_MESSAGES.FAILED_TO_FETCH_PROJECT_PAGES,
        },
      });
    }
  };
};

const pageComponentDataModule = {
  reducer: pageComponentDataSlice.reducer,
  operations: {
    fetchComponentDataById,
    fetchComponentsListDataByIds,
    onClearComponentsListData,
  },
};

export default pageComponentDataModule;
