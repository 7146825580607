import { Box, CardActions, Grid, styled } from "@mui/material";
import React from "react";
import ContentBlock from "components/ContentBlock";
import CTAButton from "components/CTAButton";
import { buttonAlignment, generateStyles } from "styles/GenerateStyles";

const Cardsection = ({ cardData, brandTags }) => {
  const renderTextContent = (contentArray) =>
    contentArray?.map((item) => (
      <React.Fragment key={item?.id}>
        <ContentBlock
          content={item?.content}
          styles={{
            ...item?.style,
            ...brandTags?.[item?.tagType],
            margin: item?.tagType === "h6" ? "5px 0px 10px 0px" : item?.tagType === "p"?"0px 0px 16px 0px":"",
          }}

          contentHTML={item?.contentHTML}
          tagType={item?.tagType}
        />
      </React.Fragment>
    ));

  const renderCallToActions = (cta) =>
    cta?.map(
      (item) =>
        item?.text && (
          <CardActions
            key={item?.id}
            sx={
              item?.style?.textAlignment
                ? buttonAlignment(item?.style?.textAlignment)
                : {}
            }
          >
            <CTAButton
              cta={item}
              brandTags={brandTags}
              extrastyles={{ width: "100%", boxShadow: "none" }}
            />
          </CardActions>
        )
    );

  const renderCardContent = (card, index) => (
    <Grid
      item
      xs={6}
      md={4}
      lg={3}
      key={card.id}
      sx={{
        p: {xs: 0 , md:3},
        borderLeft: {
          lg: index % 4 !== 0 ? "1px solid #DDE8E9" : "none",
          md: index % 3 !== 0 ? "1px solid #DDE8E9" : "none",
        },
        overflow: "hidden",
      }}
      mt={3}
    >
      <StyledBox
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          textAlign: "center",
        }}
      >
        <Box>{renderTextContent(card.content)}</Box>

        {renderCallToActions(card.cta)}
      </StyledBox>
    </Grid>
  );

  return (
    <Grid container spacing={3} p={3}>
      {cardData?.map((card, index) => renderCardContent(card, index))}
    </Grid>
  );
};

export default Cardsection;

const StyledBox = styled(Box)(({ styles }) => ({
  ...generateStyles({ styles, disableBackground: true }),
}));
