import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";

import Loader from "components/Loader/Loader";
import HeaderWithTags from "components/HeaderWithTags/HeaderWithTags";
import DynamicChipSet from "components/Tabs/DynamicTabs";
import IntroDetailsCard from "components/IntroDetailsCard/IntroDetailsCard";

import TabSection from "./components/TabSection";

import { ComponentTypes } from "Constants";

import { usePageComponents } from "hooks/usePageComponents";
import { useTab1Details } from "hooks/useTabDetails";
import {
  selectComponentDataByType,
  selectComponentsByType,
  selectPageComponents,
} from "reduxStore/selectors/PageSelectors";

const Fitness = ({ pageId }) => {
  const { loading, fetchPageComponents } = usePageComponents();
  const { pageHtmlTags } = useSelector(selectPageComponents);
  // States for tabs and content type
  const [activeTab, setActiveTab] = useState("");
  const [contentType, setContentType] = useState("");

  const imageComponents = useSelector(
    selectComponentDataByType(pageId, ComponentTypes.Image)
  );
  const tabComponentData = useSelector(
    selectComponentsByType(pageId, ComponentTypes.TabGroup)
  );
  const imageComponentData = useMemo(() => {
    const [data = {}] = imageComponents || [];
    return data;
  }, [imageComponents]);

  useEffect(() => {
    if (pageId) fetchPageComponents(pageId);
    setActiveTab("");
  }, [pageId, fetchPageComponents]);

  const section1tabComponentsData = tabComponentData?.tabGroup?.[0];

  
  const {
    loading: section1Loading,
    tabData: tab1Data,
    fetchTabDetailsData: fetchTab1Details,
  } = useTab1Details();
  const mainTabsListMemo = useMemo(() => {
    return section1tabComponentsData?.tabs?.["$values"]?.sort(
      (a, b) => a.order - b.order
    );
  }, [section1tabComponentsData]);
  // Update contentType when activeTab changes
  useEffect(() => {
    if (mainTabsListMemo?.length > 0) {
      const firstTabId = mainTabsListMemo[0]?.id;
      if (firstTabId && !activeTab) {
        // Set the active tab to the first tab's ID
        setActiveTab(firstTabId);
        // Fetch details for the first tab using its ID
        fetchTab1Details(firstTabId);
      }
    }
  }, [mainTabsListMemo, fetchTab1Details, activeTab]);

  // useEffect to update contentType based on activeTab
  const isFirstRef = useRef(true);
  useEffect(() => {
    if (isFirstRef.current) {
      isFirstRef.current = false;
      return;
    }

    if (activeTab && mainTabsListMemo) {
      const selectedTab = mainTabsListMemo.find((tab) => tab.id === activeTab);
      if (selectedTab && selectedTab?.contentType !== contentType) {
        setContentType(selectedTab?.contentType || "");
      }
    }
  }, [activeTab, mainTabsListMemo, contentType]);

  const tabCardViews = tab1Data?.$values?.sort((a, b) => a.order - b.order);

  const handleDispatch = (tabId) => {
    if (tabId) {
      fetchTab1Details(tabId);
    }
  };
  const renderTabs = (
    tabsList,
    activeTab,
    handleChip,
    style,
    direction,
    contentPosition
  ) => {
    return (
      <DynamicChipSet
        chips={tabsList}
        activeTab={activeTab}
        handleChip={(e) => {
          handleChip(e);
          handleDispatch(e);
        }}
        styles={{ ...style, width: "100%" }}
        direction={direction}
        justifyContent={contentPosition}
      />
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {pageHtmlTags?.length > 0 && (
            <HeaderWithTags pageHtmlTags={pageHtmlTags} />
          )}
          {imageComponentData && (
            <IntroDetailsCard data={imageComponentData?.$values?.[0]} />
          )}

          {section1tabComponentsData?.style && (
            <Box>
              {mainTabsListMemo?.length > 0 &&
              section1tabComponentsData?.isHorizontalDisplay ? (
                renderTabs(
                  mainTabsListMemo,
                  activeTab,
                  setActiveTab,
                  section1tabComponentsData?.style,
                  "row",
                  ""
                )
              ) : (
                <Grid container mt={3}>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{
                      pr: 3,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    {renderTabs(
                      mainTabsListMemo,
                      activeTab,
                      setActiveTab,
                      section1tabComponentsData?.style,
                      "column",
                      "flex-start"
                    )}
                  </Grid>
                  {section1Loading ? (
                    <Loader fullPage={false} left={"25%"} />
                  ) : (
                    <Grid item xs={12} md={9}>
                      <TabSection
                        tabData={tabCardViews}
                        contentType={contentType}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Fitness;
