import React from "react";
import { Grid } from "@mui/material";

import Card from "components/Card/Card";

import { overFlowStyles } from "styles/OverFlowStyles";
import { defaultBorderThickness } from "styles/CommonStyles";
import { getUpdatedButtonStyles } from "utils";

const getCardContentStyles = (justification) => ({
  textAlignment: justification === "end" ? 2 : 1,
  justifyContent: justification === "end" ? "end" : "start",
  padding: "10px",
});
const LearnMorebuttonStyles = {
  backgroundColor: "#FFFFFF",
  borderRadius: "20px",
  padding: "6px 20px 6px 20px",
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "10px",
  foregroundColor: "#885636",
  textdecoration: "none",
  fontStyle: "Normal",
  boxShadow: "0px 4px 10px 0px #0000001A",
};
const CardSection = React.memo(
  ({
    title,
    subTitle,
    description,
    image,
    imagePosition,
    styles,
    defaultStyles,
    titleVariant = "h2",
    subTitleVariant = "h4",
    descriptionVariant = "p",
    buttonPosition = "bottom",
    buttonVariant,
    CTALink,
    isExternal,

  }) => {
    const alignment = imagePosition === "left" ? "end" : "start";

    const titleStyle = {
      ...styles?.[titleVariant],
      ...getCardContentStyles(alignment),
    };
    const subTitleStyle = {
      ...styles?.[subTitleVariant],
      ...getCardContentStyles(alignment),
    };
  
    const buttonStyles = getUpdatedButtonStyles(styles, buttonVariant, LearnMorebuttonStyles)
    const descriptionStyle = {
      ...styles?.[descriptionVariant],
      ...getCardContentStyles(alignment),
    };

    return (
      <Grid item xs={12} md={12} lg={12}>
        <Card
          title={title}
          subTitle={subTitle}
          description={description}
          titleStyle={{
            ...titleStyle,

            ...defaultStyles?.titleStyles,
            margin: "3px 0px",
            ...defaultBorderThickness,
          }}
          subTitleStyle={{
            ...subTitleStyle,

            ...defaultStyles?.subTitleStyles,
            margin: "3px 0px",
            ...defaultBorderThickness,
          }}
          descriptionStyle={{
            ...descriptionStyle,
            ...overFlowStyles,
            ...defaultStyles?.descriptionStyles,
            ...defaultBorderThickness,
          }}
          image={image}
          mediaStyle={{ ...defaultStyles.media, ...defaultStyles?.mediaStyles }}
          cardStyles={defaultStyles.cardContainer}
          cardContentStyles={getCardContentStyles(alignment)}
          imagePosition={imagePosition}
          titleVariant={titleVariant}
          subTitleVariant={subTitleVariant}
          descriptionVariant={descriptionVariant}
          buttonPosition={buttonPosition}
          buttonStyles={buttonStyles}
          defaultStyles={defaultStyles}
          buttonExtraStyles={{ padding: "6px 20px 6px 20px" }}
          CTALink={CTALink}
          isExternal={isExternal}

        />
      </Grid>
    );
  }
);

const CardGridSection = ({ tagStyle, defaultStyles, cardData }) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      sx={{ display: "flex", flexDirection: "column" }}
      gap={2}
    >
      {cardData.map((card) => (
        <CardSection
          key={card?.id}
          title={card.title}
          subTitle={card.subTitle}
          description={card.description}
          image={card.image}
          imagePosition={card.imagePosition}
          titleVariant={card.titleVariant}
          subTitleVariant={card.subTitleVariant}
          descriptionVariant={card.descriptionVariant}
          buttonVariant={card.buttonType}
          styles={tagStyle}
          defaultStyles={defaultStyles}
          CTALink={card.CTALink}
          isExternal={card.isCTAExternal}
        />
      ))}
    </Grid>
  );
};

export default CardGridSection;
