import * as React from "react";

import Card from "@mui/material/Card";
import { Box, styled } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Button from "../Button";
import TextWrapper from "../TextWrapper";
import { TEXT_CONSTANTS } from "Constants";

import { defaultStyles } from "pages/OrganizationLayout/Spa/DefaultStyles";

import { generateStyles } from "styles/GenerateStyles";
import { handleCTA } from "utils";
import { useNavigate, useParams } from "react-router-dom";

const MediaCard = ({
  image,
  title,
  subTitle,
  description,
  // onLearnMoreClick,
  imagePosition = TEXT_CONSTANTS.LEFT,
  buttonPosition,
  buttonName = TEXT_CONSTANTS.LEARN_MORE,
  titleVariant,
  subTitleVariant,
  descriptionVariant,
  cardContentStyles = {},
  titleStyle = {},
  subTitleStyle = {},
  descriptionStyle = {},
  buttonStyles = {},
  buttonExtraStyles = {},
  CTALink,
  isExternal,

}) => {
  const isImageLeft = imagePosition === TEXT_CONSTANTS.LEFT;
  const isVertical = [TEXT_CONSTANTS.TOP, TEXT_CONSTANTS.BOTTOM].includes(
    imagePosition
  );
  const flexDirection = isVertical
    ? imagePosition === TEXT_CONSTANTS.BOTTOM
      ? "column"
      : "column-reverse"
    : isImageLeft
    ? "row-reverse"
    : "row";

    const navigate = useNavigate()
    const {organizationId} = useParams()
    const onLearnMoreClick = () => {
      // if (onLearnMoreClick) onLearnMoreClick();
      if(CTALink) handleCTA(isExternal , CTALink , navigate , organizationId)
    }

  return (
    <StyledCard
      sx={{
        maxWidth: "auto",
        display: "flex",
        flexDirection,
        justifyContent: isVertical ? "flex-end" : "",
        height: "100%",
        borderRadius: "50px",
      }}
      style={defaultStyles?.cardStyles}
    >
      <Box
        sx={{
          width: isVertical ? "100%" : "55%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CardContent
          sx={{
            padding: "16px",
            textAlign: imagePosition === TEXT_CONSTANTS.TOP ? "center" : "left",
            ...cardContentStyles,
          }}
        >
          <TextWrapper
            styles={{...titleStyle , padding:"0px"}}
            title={title}
            variant={titleVariant}
          />
          {subTitle && (
            <TextWrapper
              styles={{...subTitleStyle , padding:"0px"}}
              title={subTitle}
              variant={subTitleVariant}
            />
          )}
          <TextWrapper
            styles={{ color: "text.secondary", ...descriptionStyle , padding:"0px" }}
            title={description}
            variant={descriptionVariant}
          />
        </CardContent>

        {buttonPosition !== TEXT_CONSTANTS.TOP && (
          <CardActions sx={{ ...cardContentStyles, ...buttonExtraStyles }}>
            <Button
              handleClick={onLearnMoreClick}
              styles={buttonStyles}
              name={buttonName}
            />
          </CardActions>
        )}
      </Box>

      <CardMedia
        sx={{
          position: "relative",
          width: isVertical ? "100%" : "55%",
          height: isVertical ? "232px" : "100%",
          objectFit: "cover",

          ...generateStyles({
            styles: defaultStyles?.imageStyles,
            disableBackground: false,
          }),
          borderRadius: "50px",
        }}
        image={image}
        title={title}
      >
        {buttonPosition === TEXT_CONSTANTS.TOP && (
          <Box sx={{ position: "absolute", top: "16px", right: "22px" }}>
            <Button
              styles={buttonStyles}
              name={TEXT_CONSTANTS.BOOK_NOW}
              handleClick={onLearnMoreClick}
            />
          </Box>
        )}
      </CardMedia>
    </StyledCard>
  );
};

export default MediaCard;
const StyledCard = styled(Card)(({ styles }) => ({
  ...generateStyles({ styles, disableBackground: false }),
}));
