import { createSlice } from "@reduxjs/toolkit";
import { onFetchProjectPagesList } from "services/ApiService";
import { ERROR_MESSAGES } from "StatusAlerts";

const initialState = {
  pagesList: [],
};

export const pagesSlice = createSlice({
  name: "pages",
  reducers: {
    addProjectPagesList: (state, { payload }) => ({
      ...state,
      pagesList: payload,
    }),
  },
  initialState,
});

const fetchProjectPagesList = ({
  projectId,
  isPreview,
  onSuccessResponse,
  onErrorResponse,
}) => {
  return async (dispatch) => {
    try {
      const { result, error, statusCode } = await onFetchProjectPagesList(
        projectId
      );
      if (statusCode === 200) {
        const publishedPages = isPreview
          ? result.data
          : result.data.filter((page) => page.state === 1);
        dispatch(pagesSlice.actions.addProjectPagesList(publishedPages));
        onSuccessResponse({ response: publishedPages });
      } else {
        onErrorResponse({
          response: {
            errorMessage: error || ERROR_MESSAGES.FAILED_TO_FETCH_PROJECT_PAGES,
            statusCode: statusCode,
          },
        });
      }
    } catch (error) {
      onErrorResponse({
        response: {
          errorMessage:
            error?.message || ERROR_MESSAGES.FAILED_TO_FETCH_PROJECT_PAGES,
          statusCode: 500,
        },
      });
    }
  };
};
const pagesModule = {
  reducer: pagesSlice.reducer,
  operations: {
    fetchProjectPagesList,
  },
};
export default pagesModule;
