import React from "react";

import { Box, Grid, useTheme } from "@mui/material";

import CarouselSlider from "components/CarouselSlider";

import HeaderComponent from "../Components/HeaderSection";
import CardGridSection from "./components/CardGridSection";
import ImageCardGridSection from "./components/ImageSection";

import {
  carouselConfig,
  carouselItems,
  horizontalCardData,
  verticalCardData,
} from "metaDataJson/Home";

import { TEXT_CONSTANTS } from "Constants";

import { defaultStyles } from "./DefaultStyles";

const Dashboard = ({ brandTags }) => {
  const theme = useTheme();
  const { palette } = theme;

  const secondary = palette.secondary.main;
  
  return (
    <>
      <HeaderComponent
        title={TEXT_CONSTANTS.WELCOME_THE_RANCH}
        subTitle={TEXT_CONSTANTS.AT_LAGUNA_BEACH}
        styles={brandTags}
        titleVariant={"h2"}
        subTitleVariant={"p"}
      />

      {carouselItems?.length > 0 && (
        <Box sx={{ position: "relative", overflow: "hidden" }}>
          <CarouselSlider
            carouselConfig={carouselConfig}
            carouselItems={carouselItems}
            carouselStyle={{ ...defaultStyles.carouselStyles }}
            brandTags={brandTags}
            from={TEXT_CONSTANTS.ORGANIZATION_TEXT}
          />
        </Box>
      )}
      <Box mt={2}>
        <Grid container spacing={3} item>
          <CardGridSection
            tagStyle={brandTags}
            defaultStyles={defaultStyles}
            cardData={horizontalCardData}
          />

          <ImageCardGridSection
            tagStyle={brandTags}
            secondaryColor={secondary}
            defaultStyles={defaultStyles}
            styles={brandTags}
            cardData={verticalCardData}
          />
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
