import React, { memo, useMemo } from "react";
import Carousel from "react-material-ui-carousel";

import { sortDataByOrderId, transformKeysToCamelCase } from "utils";
import {
  activeIndicatorStyles,
  indicatorContainerStyles,
  indicatorStyles,
} from "./Styles";
import CarouselItem from "./CarouselItem";
import { generateStyles } from "styles/GenerateStyles";

const CarouselSlider = memo(
  ({
    carouselItems = [],
    carouselConfig = {},
    carouselStyle = {},
    lines,
    from,
    brandTags,
    applyGradient,
    boxStyles,
  }) => {
    const config = useMemo(
      () => (carouselConfig ? transformKeysToCamelCase(carouselConfig) : {}),
      [carouselConfig]
    );
    const items = useMemo(
      () =>
        carouselItems.length
          ? sortDataByOrderId(transformKeysToCamelCase(carouselItems))
          : [],
      [carouselItems]
    );
    const {
      showNavigationArrows = true,
      showDotNavigation = true,
      style: styles,
    } = config;
    return (
      <Carousel
        sx={{
          ...generateStyles({ styles }),
          ...carouselStyle,
        }}
        autoPlay={true}
        fullHeightHover={false}
        indicators={showDotNavigation}
        navButtonsAlwaysVisible={showNavigationArrows}
        indicatorIconButtonProps={{ style: indicatorStyles }}
        activeIndicatorIconButtonProps={{ style: activeIndicatorStyles }}
        indicatorContainerProps={{ style: indicatorContainerStyles }}
        animation="fade"
      >
        {items.map((item, index) => {
          return (
            <CarouselItem
              key={item.id || index}
              item={item}
              carouselStyle={carouselStyle}
              lines={lines}
              from={from}
              brandTags={brandTags}
              applyGradient={applyGradient}
              boxStyles={boxStyles}
            />
          );
        })}
      </Carousel>
    );
  }
);

export default CarouselSlider;
