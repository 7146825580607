
import { SERVICE_MENU_URL } from "Constants";
import CardImage1 from "../assets/images/Mask_group_1.png";
import CardImage2 from "../assets/images/Mask_group_2.png";
import CardImage3 from "../assets/images/Mask_group_3.png";
import CardImage4 from "../assets/images/Mask_group_4.png"
import SpaImage from "../assets/images/Mask_group_5.png"

import CarouselSpaImage1 from "../assets/images/Spa_carousel_img-1.png"
import CarouselSpaImage2 from "../assets/images/Spa_carousel_img-2.png"
import CarouselSpaImage3 from "../assets/images/Spa_carousel_img-3.png"
import CarouselSpaImage4 from "../assets/images/Spa_carousel_img-4.png"

export const cardData = [
    {
      title: "Calming Canyon",
      description:
        "This Swedish-style massage improves circulation and relax the body.",
      image: CardImage4,
      id:"1",
      CTALink:SERVICE_MENU_URL,
      isCTAExternal:true,
      buttonType: "fourthButton"

    },
    {
      title: "Himalayan Salt Stone",
      description:
        "Hand-carved Himalayan salt stones relieve stress and renews your skin.",
      image: CardImage3,
      id:"2",
      CTALink:SERVICE_MENU_URL,
      isCTAExternal:true,
       buttonType: "fourthButton"

    },
    {
      title: "Deep Dive",
      description:
        "This massage uses firm to deep pressure to release muscle tension and increase mobility.",
      image: CardImage2,
      id:"3",
      CTALink:SERVICE_MENU_URL,
      isCTAExternal:true,
       buttonType: "fourthButton"

    },
    {
      title: "Performance Healing",
      description:
        "Lymphatic Drainage Detox +Slim Body Treatment with Iccone Roboderm helps removes toxins ",
      
      image: CardImage1,
      id:"4",
      CTALink:SERVICE_MENU_URL,
      isCTAExternal:true,
       buttonType: "fourthButton"
      
    },
    
  ];

  export const chips = [
    { label: "MASSAGE", value: "1" },
    { label: "FACIALS", value: "2" },
    { label: "CRYOSKIN THERAPY", value: "3" },
  ];
  
  export const subChipSets = {
    1: [
      { label: "Massages", value: "1" },
      { label: "Body Treatment", value: "2" },
      { label: "Treatment Enhancements", value: "3" },
    ],
    2: [
      { label: "Massages", value: "1" },
      { label: "Body Treatment", value: "2" },
      { label: "Treatment Enhancements", value: "3" },
    ],
    3: [
      { label: "Massages", value: "1" },
      { label: "Body Treatment", value: "2" },
      { label: "Treatment Enhancements", value: "3" },
    ],
  };

 export const tab1Data={
    "$id": "1",
    "$values": [
        {
            "$id": "2",
            "id": "7a836741-77c0-462a-9e39-ccd97d927a5c",
            "componentId": "994e4dac-6635-4d08-b684-520c9ea5a49c",
            "imageURL": SpaImage,
            "cornerRadius": 10,
            "isExternal": false,
            "linkURL": null,
            "maximumHeight": 500,
            "maximumWidth": 100,
            "openInWindow": true,
            "sizeOption": 2,
            "style": {
                "$id": "3",
                "id": "d545c3e9-3354-4dcb-8f1d-077f5132b9aa",
                "backgroundColor": "#FFFFFF",
                "borderColor": "#FFFFFF",
                "borderRadius": "40px",
                "foregroundColor": "#000000",
                "margin": "0px 0px 0px 0px",
                "fontSize": "8px",
                "borderThickness": 0,
                "fontFamily": "Poppins",
                "fontWeight": 400,
                "fontStyle": 1,
                "padding": "0px 0px 0px 0px",
                "opacity": 1,
                "textAlignment": 1,
                "textDecoration": "",
                "customCSS": ""
            },
            "texts": {
                "$id": "4",
                "$values": [
                    {
                        "$id": "5",
                        "id": "7c779320-1e7d-4cf5-b0ab-47bd13558d7a",
                        "content": "Spa Hours: Monday-Friday: 10:00am - 6:00pm, Saturday & Sunday: 9:00am - 6:00pm",
                        "contentHTML":`<span>Spa Hours:&ZeroWidthSpace; Monday-Friday:&nbsp;<span style="color:#5B7961"><b>10:00am - 6:00pm</b></span> Saturday &amp; Sunday:&nbsp;<span style="color:#5B7961"><b>9:00am - 6:00pm</b></span></span>`,
                        "order": 2,
                        "tagType": "p",
                        "style": {
                           "padding":"12px 0px 16px 0px",
                           
                        }
                    },
                    {
                        "$id": "7",
                        "id": "e89bdc3e-64e9-47f2-821f-158bd1793749",
                        "content": "Welcome to Sycamore Spa by Hudson",
                        "contentHTML": "Welcome to Sycamore Spa by Hudson",
                        "order": 1,
                        "tagType": "h1",
                        "style": {
                            // margin:"10px 0px",

                        }
                    }
                ]
            },
            "carouselCTAs": {
                "$id": "9",
                "$values": [
                    {
                        "$id": "10",
                        "id": "17d764c2-c6cb-4d3a-9841-dd6d38506884",
                        "text": "Learn More",
                        "isExternal": true,
                        "uRL": "https://www.spabyhudson.com/?_gl=1*cpnfx4*_up*MQ..&gclid=CjwKCAjw_4S3BhAAEiwA_64Yho5wJyQq3FMcIJcQg5CGzNyqlz7MfFpQamXRSR59oGqnAv7ddR7BABoC2-0QAvD_BwE&gclsrc=aw.ds",
                        "style": {
                            "$id": "11",
                            "id": "12428d6a-cd1c-49d4-95d1-cead2fa4eac6",
                            "backgroundColor": "#FFFFFF",
                            "borderColor": "#FFFFFF",
                            "borderRadius": "20px",
                            "foregroundColor": "#885636",
                            "margin": "0px 0px 0px 0px",
                            "fontSize": "12px",
                            "borderThickness": 0,
                            "fontFamily": "Poppins",
                            "fontWeight": 500,
                            "fontStyle": 1,
                            "padding": "4px 20px 4px 20px",
                            "opacity": 1,
                            "textAlignment": 3,
                            "textDecoration": "",
                            "customCSS": "",
                            // margin:"10px 0px",
                            
                        }
                    }
                ]
            }
        }
    ]
}
export const carouselConfig ={
  "$id": "2",
  "id": "3d0e98d4-1808-4d50-9cc1-b923e7d169ff",
  "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
  "carouselIconsId": null,
  "styleId": "3ddfef37-3030-4fea-bd5d-fb3a0f4107d6",
  "slideTransition": 1,
  "navigationStyle": "Arrows",
  "slidePerPage": 1,
  "transistionTime": 300,
  "leftNavIconId": "752b045d-0c26-47ca-9bba-8b9559a7c499",
  "rightNavIconId": "97429907-fe87-4e8c-a1b7-c63270b339a6",
  "loopSlides": true,
  "autoplay": false,
  "showNavigationArrows": true,
  "showThumbnails": false,
  "showDotNavigation": true,
  "component": null,
  "carouselIcons": null,
  "leftNavIcon": {
      "$id": "3",
      "id": "752b045d-0c26-47ca-9bba-8b9559a7c499",
      "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
      "imageId": null,
      "styleId": null,
      "content": "Sample content for the carousel item.",
      "uRL": "",
      "colorHex": "#000000",
      "opacity": 0,
      "component": null,
      "isActive": true,
      "createdBy": null,
      "createdDate": null,
      "modifiedBy": null,
      "modifiedDate": null,
      "isDeleted": false
  },
  "rightNavIcon": {
      "$id": "4",
      "id": "97429907-fe87-4e8c-a1b7-c63270b339a6",
      "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
      "imageId": null,
      "styleId": null,
      "content": "Sample content for the carousel item.",
      "uRL": "",
      "colorHex": "#000000",
      "opacity": 0,
      "component": null,
      "isActive": true,
      "createdBy": null,
      "createdDate": null,
      "modifiedBy": null,
      "modifiedDate": null,
      "isDeleted": false
  },
  "style": {
      "$id": "5",
      "id": "3ddfef37-3030-4fea-bd5d-fb3a0f4107d6",
      "fontFamily": "Poppins",
      "fontSize": "15px",
      "fontWeight": 400,
      "fontStyle": 1,
      "textDecoration": "",
      "foregroundColor": "#000000",
      "backgroundColor": "#FFFFFF00",
      "textAlignment": 3,
      "padding": "0px 0px 0px 0px",
      "margin": "0px 0px 0px 0px",
      "borderRadius": "0",
      "borderThickness": 0,
      "borderColor": "#FFFFFF",
      "opacity": 1,
      "hoverEffect": "",
      "customCSS": "",
      "isActive": true,
      "createdBy": null,
      "createdDate": null,
      "modifiedBy": null,
      "modifiedDate": null,
      "isDeleted": false
  },
  "isActive": true,
  "createdBy": "System",
  "createdDate": "2024-10-15T04:14:05.960906+00:00",
  "modifiedBy": null,
  "modifiedDate": null,
  "isDeleted": false
}

export const carouselItems = [
  {
      "$id": "7",
      "id": "1420dbe8-ab8c-4a94-ac36-25d40aab707a",
      "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
      "contextType": 4,
      "styleId": "063097fb-b378-4810-883d-51279589f2ab",
      "imageURL": CarouselSpaImage3,
      "isExternal": false,
      "linkURL": null,
      "openInWindow": true,
      "imageName": "home_Caraousal_Image3",
      "altText": "Creakside Studio suite",
      "sizeOption": 2,
      "maximumWidth": 100,
      "maximumHeight": 500,
      "cornerRadius": 10,
      "order": 3,
      "objectFit": 3,
      "texts": {
          "$id": "8",
          "$values": [
              {
                  "$id": "9",
                  "id": "dec25b20-e39f-41ce-95a2-9245c998b64d",
                  "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                  "contextType": 4,
                  "imagesId": "1420dbe8-ab8c-4a94-ac36-25d40aab707a",
                  "styleId": "121c662e-5f9c-4b81-8642-d99f3410c0f0",
                  "content": "Calming Canyon Massage",
                  "contentHTML": "Calming Canyon Massage",

                  "tagType": "h1",
                  "order": 1,
                  "images": {
                      "$ref": "7"
                  },
                  "style": {
                      textAlignment: 3,
                      "margin": "0px 0px 16px 0px",

                  },
                  
                  "isActive": true,
                  "createdBy": null,
                  "createdDate": "2024-10-15T04:14:06.111803+00:00",
                  "modifiedBy": null,
                  "modifiedDate": null,
                  "isDeleted": false
              },
              {
                  "$id": "11",
                  "id": "e4b7fa36-dc22-4cb8-9004-cda49f531f18",
                  "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                  "contextType": 4,
                  "imagesId": "1420dbe8-ab8c-4a94-ac36-25d40aab707a",
                  "styleId": "1fd25232-f15f-488c-b4f6-a318721496e6",
                  "content": " This Swedish-style massage uses light to medium pressure to improve circulation, calm the mind and relax the body ",
                  "contentHTML": "<span>This Swedish-style massage uses light to medium pressure to improve circulation, calm the mind and relax the body.</span>",

                  "tagType": "p",
                  "order": 2,
                  "images": {
                      "$ref": "7"
                  },
                  "style": {
                      textAlignment: 3,
                      "margin": "0px 0px 16px 0px",

                  },
                  "isActive": true,
                  "createdBy": null,
                  "createdDate": "2024-10-15T04:14:06.121135+00:00",
                  "modifiedBy": null,
                  "modifiedDate": null,
                  "isDeleted": false
              }
          ]
      },
      "carouselCTAs": {
          "$id": "13",
          "$values": [
              {
                  "$id": "14",
                  "id": "b8877baf-b97d-4421-b6a4-639d6d0d0122",
                  "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                  "contextType": 4,
                  "imagesId": "1420dbe8-ab8c-4a94-ac36-25d40aab707a",
                  "styleId": "21933bdd-580f-4b49-afb0-a5ab5966c9ae",
                  "text": "Learn More",
                  "uRL": "https://www.spabyhudson.com/massages",
                  "isExternal": true,
                  "images": {
                      "$ref": "7"
                  },
                  "buttonType": "secondaryButton",
                  "style": {
                      "$id": "15",
                      "id": "21933bdd-580f-4b49-afb0-a5ab5966c9ae",
                      "backgroundColor": "#FFFFFF",
                      "borderRadius": "20px",
                      "padding": "4px 20px 4px 20px",
                      "fontFamily": "Poppins",
                      "fontWeight": "600",
                      "fontSize": "14px",
                      "foregroundColor": "#885636",
                      "textAlignment": 3,
                      "textdecoration": "none",
                      "fontStyle": "Normal",
                      
                  },
                  "isActive": true,
                  "createdBy": null,
                  "createdDate": "2024-10-15T04:14:06.129312+00:00",
                  "modifiedBy": null,
                  "modifiedDate": null,
                  "isDeleted": false
              }
          ]
      },
      "style": {
          "$id": "16",
          "id": "063097fb-b378-4810-883d-51279589f2ab",
          "fontFamily": "Poppins",
          "fontSize": "8px",
          "fontWeight": 400,
          "fontStyle": 1,
          "textDecoration": "",
          "foregroundColor": "#000000",
          "backgroundColor": "#FFFFFF",
          "textAlignment": 1,
          "padding": "0px 0px 0px 0px",
          "margin": "0px 0px 0px 0px",
          "borderRadius": "40px",
          "borderThickness": 0,
          "borderColor": "#FFFFFF",
          "opacity": 1,
          "hoverEffect": "",
          "customCSS": "",
          "isActive": true,
          "createdBy": null,
          "createdDate": null,
          "modifiedBy": null,
          "modifiedDate": null,
          "isDeleted": false
      },
      "isActive": true,
      "createdBy": null,
      "createdDate": "2024-10-15T04:14:06.101838+00:00",
      "modifiedBy": null,
      "modifiedDate": null,
      "isDeleted": false
  },
  {
      "$id": "17",
      "id": "7ebd4616-1d67-43f1-8c5c-3f7028001b25",
      "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
      "contextType": 4,
      "styleId": "e74207d7-74f5-4f12-8e7e-f2367c2dc726",
      "imageURL": CarouselSpaImage2,
      "isExternal": false,
      "linkURL": null,
      "openInWindow": true,
      "imageName": "home_Caraousal_Image4",
      "altText": "Creakside Studio suite",
      "sizeOption": 2,
      "maximumWidth": 100,
      "maximumHeight": 500,
      "cornerRadius": 10,
      "order": 2,
      "objectFit": 3,
      "texts": {
          "$id": "18",
          "$values": [
              {
                  "$id": "19",
                  "id": "2662b8fb-b014-416f-936f-ed4bd5973f24",
                  "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                  "contextType": 4,
                  "imagesId": "7ebd4616-1d67-43f1-8c5c-3f7028001b25",
                  "styleId": "1558d123-3b5c-4793-bfb0-b104e3c1a247",
                  "content": "Cryoskin Therapy",
                  "contentHTML": "Cryoskin Therapy",
                  "tagType": "h1",
                  "order": 1,
                  "images": {
                      "$ref": "17"
                  },
                  "style": {
                     textAlignment: 3,
                     "margin": "0px 0px 16px 0px",

                  },
                  "isActive": true,
                  "createdBy": null,
                  "createdDate": "2024-10-15T04:14:06.146599+00:00",
                  "modifiedBy": null,
                  "modifiedDate": null,
                  "isDeleted": false
              },
              {
                  "$id": "21",
                  "id": "d2518827-4ea7-4e02-9f86-13fa4dd90bcf",
                  "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                  "contextType": 4,
                  "imagesId": "7ebd4616-1d67-43f1-8c5c-3f7028001b25",
                  "styleId": "5b29a3ca-6ba4-4e8f-9595-05f3e1c25531",
                  "content": "A non-invasive & pain free treatment from Paris that freezes fat, tones the body and increases collagen production.",
                  "contentHTML": "<span>A non-invasive & pain free treatment from Paris that freezes fat, tones the body and increases collagen production.</span>",
                  "tagType": "p",
                  "order": 2,
                  "images": {
                      "$ref": "17"
                  },
                  "style": {
                      textAlignment: 3,
                      "margin": "0px 0px 16px 0px",

                  },
                  "isActive": true,
                  "createdBy": null,
                  "createdDate": "2024-10-15T04:14:06.157377+00:00",
                  "modifiedBy": null,
                  "modifiedDate": null,
                  "isDeleted": false
              }
          ]
      },
      "carouselCTAs": {
          "$id": "23",
          "$values": [
              {
                  "$id": "24",
                  "id": "813ccd3f-017b-4fc2-8a45-bf6080030e3a",
                  "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                  "contextType": 4,
                  "imagesId": "7ebd4616-1d67-43f1-8c5c-3f7028001b25",
                  "styleId": "25fced13-7ee8-46b9-908c-371ddb228787",
                  "text": "Learn More",
                  "uRL": "https://www.spabyhudson.com/cryoskin",
                  "isExternal": true,
                  "images": {
                      "$ref": "17"
                  },
                  "buttonType": "secondaryButton",
                  "style": {
                      "$id": "25",
                      "id": "25fced13-7ee8-46b9-908c-371ddb228787",
                      "backgroundColor": "#FFFFFF",
                      "borderRadius": "20px",
                      "padding": "4px 20px 4px 20px",
                      "fontFamily": "Poppins",
                      "fontWeight": "600",
                      "fontSize": "14px",
                      "foregroundColor": "#885636",
                      "textAlignment": 3,
                      "textdecoration": "none",
                      "fontStyle": "Normal",

                      
                  },
                  "isActive": true,
                  "createdBy": null,
                  "createdDate": "2024-10-15T04:14:06.169174+00:00",
                  "modifiedBy": null,
                  "modifiedDate": null,
                  "isDeleted": false
              }
          ]
      },
      "style": {
          "$id": "26",
          "id": "e74207d7-74f5-4f12-8e7e-f2367c2dc726",
          "fontFamily": "Poppins",
          "fontSize": "8px",
          "fontWeight": 400,
          "fontStyle": 1,
          "textDecoration": "",
          "foregroundColor": "#000000",
          "backgroundColor": "#FFFFFF",
          "textAlignment": 1,
          "padding": "0px 0px 0px 0px",
          "margin": "0px 0px 0px 0px",
          "borderRadius": "40px",
          "borderThickness": 0,
          "borderColor": "#FFFFFF",
          "opacity": 1,
          "hoverEffect": "",
          "customCSS": "",
          "isActive": true,
          "createdBy": null,
          "createdDate": null,
          "modifiedBy": null,
          "modifiedDate": null,
          "isDeleted": false
      },
      "isActive": true,
      "createdBy": null,
      "createdDate": "2024-10-15T04:14:06.13874+00:00",
      "modifiedBy": null,
      "modifiedDate": null,
      "isDeleted": false
  },
  {
      "$id": "27",
      "id": "8d421634-5662-45a7-b875-5abe9d38554c",
      "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
      "contextType": 4,
      "styleId": "3c666a31-95ea-448e-90b2-73b3a2ae5557",
      "imageURL": CarouselSpaImage1,
      "isExternal": false,
      "linkURL": null,
      "openInWindow": true,
      "imageName": "home_Caraousal_Image1",
      "altText": "Creakside Studio suite",
      "sizeOption": 2,
      "maximumWidth": 100,
      "maximumHeight": 500,
      "cornerRadius": 10,
      "order": 1,
      "objectFit": 3,
      "texts": {
          "$id": "28",
          "$values": [
              {
                  "$id": "29",
                  "id": "8eeb1ad3-c065-4523-bc04-61ba35596bfe",
                  "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                  "contextType": 4,
                  "imagesId": "8d421634-5662-45a7-b875-5abe9d38554c",
                  "styleId": "10920394-0f6f-4c6b-9463-a07c27247895",
                  "content": "Welcome to Sycamore Spa by Hudson",
                  "contentHTML": "Welcome to Sycamore Spa by Hudson",
                  "tagType": "h1",
                  "order": 1,
                  "images": {
                      "$ref": "27"
                  },
                  "style": {
                      textAlignment: 3,
                      "margin": "0px 0px 16px 0px",

                  },
                  "isActive": true,
                  "createdBy": null,
                  "createdDate": "2024-10-15T04:14:05.995535+00:00",
                  "modifiedBy": null,
                  "modifiedDate": null,
                  "isDeleted": false
              },
              {
                  "$id": "31",
                  "id": "e5d47034-c2d5-49b3-b8cd-5adf0b5d1585",
                  "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                  "contextType": 4,
                  "imagesId": "8d421634-5662-45a7-b875-5abe9d38554c",
                  "styleId": "7bdab390-eb87-4179-ba35-1618ac857b99",
                  "content": "Spa Hours:​ Monday-Friday: 10:00am - 6:00pm Saturday & Sunday: 9:00am - 6:00pm",
                  "contentHTML": "<span>Spa Hours:​ Monday-Friday: <span style=color:#8FB6BB; font-weight: 600><b>10:00am - 6:00pm</b></span> Saturday & Sunday: <span style=color:#8FB6BB;font-weight: 600><b>9:00am - 6:00pm</b></span></span>",
                  "tagType": "p",
                  "order": 2,
                  "images": {
                      "$ref": "27"
                  },
                  "style": {
                      textAlignment: 3,
                      "margin": "0px 0px 16px 0px",

                  },
                  "isActive": true,
                  "createdBy": null,
                  "createdDate": "2024-10-15T04:14:06.026939+00:00",
                  "modifiedBy": null,
                  "modifiedDate": null,
                  "isDeleted": false
              }
          ]
      },
      "carouselCTAs": {
          "$id": "33",
          "$values": [
              {
                  "$id": "34",
                  "id": "5316ebe5-c3c4-4b10-90ec-648500677559",
                  "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                  "contextType": 4,
                  "imagesId": "8d421634-5662-45a7-b875-5abe9d38554c",
                  "styleId": "78d442ef-8033-48e1-8f60-27a026d1dc72",
                  "text": "Learn More",
                  "uRL": "https://www.spabyhudson.com/?_gl=1*cpnfx4*_up*MQ..&gclid=CjwKCAjw_4S3BhAAEiwA_64Yho5wJyQq3FMcIJcQg5CGzNyqlz7MfFpQamXRSR59oGqnAv7ddR7BABoC2-0QAvD_BwE&gclsrc=aw.ds",
                  "isExternal": true,
                  "images": {
                      "$ref": "27"
                  },
                  "buttonType": "secondaryButton",
                  "style": {
                      "$id": "35",
                      "id": "78d442ef-8033-48e1-8f60-27a026d1dc72",
                      "backgroundColor": "#FFFFFF",
                      "borderRadius": "20px",
                      "padding": "4px 20px 4px 20px",
                      "fontFamily": "Poppins",
                      "fontWeight": "600",
                      "fontSize": "14px",
                      "foregroundColor": "#885636",
                      "textAlignment": 3,
                      "textdecoration": "none",
                      "fontStyle": "Normal",

                      
                  },
                  "isActive": true,
                  "createdBy": null,
                  "createdDate": "2024-10-15T04:14:06.039646+00:00",
                  "modifiedBy": null,
                  "modifiedDate": null,
                  "isDeleted": false
              }
          ]
      },
      "style": {
          "$id": "36",
          "id": "3c666a31-95ea-448e-90b2-73b3a2ae5557",
          "fontFamily": "Poppins",
          "fontSize": "8px",
          "fontWeight": 400,
          "fontStyle": 1,
          "textDecoration": "",
          "foregroundColor": "#000000",
          "backgroundColor": "#FFFFFF",
          "textAlignment": 1,
          "padding": "0px 0px 0px 0px",
          "margin": "0px 0px 0px 0px",
          "borderRadius": "40px",
          "borderThickness": 0,
          "borderColor": "#FFFFFF",
          "opacity": 1,
          "hoverEffect": "",
          "customCSS": "",
          "isActive": true,
          "createdBy": null,
          "createdDate": null,
          "modifiedBy": null,
          "modifiedDate": null,
          "isDeleted": false
      },
      "isActive": true,
      "createdBy": null,
      "createdDate": "2024-10-15T04:14:05.969215+00:00",
      "modifiedBy": null,
      "modifiedDate": null,
      "isDeleted": false
  },
  {
      "$id": "37",
      "id": "eecc7cd0-5943-46bf-9ea4-517b35efc226",
      "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
      "contextType": 4,
      "styleId": "af699e78-78e0-4807-90df-c0119004ed38",
      "imageURL": CarouselSpaImage4,
      "isExternal": false,
      "linkURL": null,
      "openInWindow": true,
      "imageName": "home_Caraousal_Image2",
      "altText": "Creakside Studio suite",
      "sizeOption": 2,
      "maximumWidth": 100,
      "maximumHeight": 500,
      "cornerRadius": 10,
      "order": 4,
      "objectFit": 3,
      "texts": {
          "$id": "38",
          "$values": [
              {
                  "$id": "39",
                  "id": "515b054c-e4d2-456c-be74-ef4e0200de13",
                  "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                  "contextType": 4,
                  "imagesId": "eecc7cd0-5943-46bf-9ea4-517b35efc226",
                  "styleId": "032708bb-881b-4db8-9f56-fa42d2e21e1e",
                  "content": "A customized facial perfect for anyone seeking to address a multitude of specific concerns",
                  "contentHTML": "<span>A customized facial perfect for anyone seeking to address a multitude of specific concerns.</span>",
                  "tagType": "p",
                  "order": 2,
                  "images": {
                      "$ref": "37"
                  },
                  "style": {
                      textAlignment: 3,
                      "margin": "0px 0px 16px 0px",

                  },
                  "isActive": true,
                  "createdBy": null,
                  "createdDate": "2024-10-15T04:14:06.079191+00:00",
                  "modifiedBy": null,
                  "modifiedDate": null,
                  "isDeleted": false
              },
              {
                  "$id": "41",
                  "id": "bec90c29-f223-47a9-94cd-b375b8661d3f",
                  "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                  "contextType": 4,
                  "imagesId": "eecc7cd0-5943-46bf-9ea4-517b35efc226",
                  "styleId": "6633e028-d0ce-4049-838c-cd7f4feabd14",
                  "content": "Self- Reflective Facial",
                  "contentHTML": "Self- Reflective Facial",
                  "tagType": "h1",
                  "order": 1,
                  "images": {
                      "$ref": "37"
                  },
                  "style": {
                      textAlignment: 3,
                      "margin": "0px 0px 16px 0px",

                  },
                  "isActive": true,
                  "createdBy": null,
                  "createdDate": "2024-10-15T04:14:06.066148+00:00",
                  "modifiedBy": null,
                  "modifiedDate": null,
                  "isDeleted": false
              }
          ]
      },
      "carouselCTAs": {
          "$id": "43",
          "$values": [
              {
                  "$id": "44",
                  "id": "2779541a-17ee-4de0-943c-25f82796e6a0",
                  "componentId": "8f9866f9-e4ac-4b30-b606-1418ffd3a6e7",
                  "contextType": 4,
                  "imagesId": "eecc7cd0-5943-46bf-9ea4-517b35efc226",
                  "styleId": "79a23794-f93b-47f8-874d-cb2866a5c25e",
                  "text": "Learn More",
                  "uRL": "https://www.spabyhudson.com/facials",
                  "isExternal": true,
                  "images": {
                      "$ref": "37"
                  },
                  "buttonType": "secondaryButton",
                  "style": {
                      "$id": "45",
                      "id": "79a23794-f93b-47f8-874d-cb2866a5c25e",
                      "backgroundColor": "#FFFFFF",
                      "borderRadius": "20px",
                      "padding": "4px 20px 4px 20px",
                      "fontFamily": "Poppins",
                      "fontWeight": "600",
                      "fontSize": "14px",
                      "foregroundColor": "#885636",
                      "textAlignment": 3,
                      "textdecoration": "none",
                      "fontStyle": "Normal",

                      
                  },
                  "isActive": true,
                  "createdBy": null,
                  "createdDate": "2024-10-15T04:14:06.090095+00:00",
                  "modifiedBy": null,
                  "modifiedDate": null,
                  "isDeleted": false
              }
          ]
      },
      "style": {
          "$id": "46",
          "id": "af699e78-78e0-4807-90df-c0119004ed38",
          "fontFamily": "Poppins",
          "fontSize": "8px",
          "fontWeight": 400,
          "fontStyle": 1,
          "textDecoration": "",
          "foregroundColor": "#000000",
          "backgroundColor": "#FFFFFF",
          "textAlignment": 1,
          "padding": "0px 0px 0px 0px",
          "margin": "0px 0px 0px 0px",
          "borderRadius": "40px",
          "borderThickness": 0,
          "borderColor": "#FFFFFF",
          "opacity": 1,
          "hoverEffect": "",
          "customCSS": "",
          "isActive": true,
          "createdBy": null,
          "createdDate": null,
          "modifiedBy": null,
          "modifiedDate": null,
          "isDeleted": false
      },
      "isActive": true,
      "createdBy": null,
      "createdDate": "2024-10-15T04:14:06.05727+00:00",
      "modifiedBy": null,
      "modifiedDate": null,
      "isDeleted": false
  }
]