import React from "react";

import { Grid } from "@mui/material";
import Card from "components/Card/DynamicCard";
import { imagePosition } from "Constants";
import { extractCallToActions, extractTextContent } from "utils";

const CardGridSection = ({ defaultStyles, horizontalCardsData = [] }) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      sx={{ display: "flex", flexDirection: "column" }}
      // gap={{ md: 2 }}
      gap={2}
    >
      {horizontalCardsData.map((card) => {
        const { texts, image, carouselCTAs, cardType, style } = card;

        // Extract texts and styles

        const contentArray = extractTextContent(texts);
        // Extract CTA (Call To Actions)

        const cta = extractCallToActions(carouselCTAs);

        return (
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            key={card.id}
            // sx={{ maxHeight: "300px" }}
          >
            <Card
              image={image?.imageURL}
              imagePosition={imagePosition[cardType]}
              contentArray={contentArray}
              defaultStyles={defaultStyles}
              // cta={cta?.[0]}
              cta={cta}
              cardStyles={style}
              imageStyles={image?.style}
              imageObject={{isExternal:image?.isExternal , linkURL:image?.linkURL}}

            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CardGridSection;
