import { transformKeysToCamelCase } from "utils";
import { createCommonSelector } from "./Selector";

export const selectPageComponentData = createCommonSelector(
  [(state) => state.pageComponentData.componentData],
  (componentData) => ({
    carouselItems: componentData?.CarousalImages?.["$values"] || [],
    carouselConfig: componentData?.CarouselConfig,
  })
);

export const selectPageComponents = createCommonSelector(
  [(state) => state.pageComponents.pageComponents],
  (pageComponents) => ({
    pageComponents: pageComponents.pageComponents,
    pageHtmlTags: pageComponents.pageHtmlTags,
  })
);

export const selectCombinedPageData = createCommonSelector(
  [selectPageComponentData, selectPageComponents],
  (componentData, pageComponents) => ({
    ...componentData,
    ...pageComponents,
  })
);

const selectComponentsListData = (state) =>
  state.pageComponentData.componentsListData || {};

export const selectComponentsByType = (componentType) =>
  createCommonSelector([selectComponentsListData], (componentsListData) => {
    return transformKeysToCamelCase(componentsListData[componentType]);
  });

export const selectComponentsByPageId = (pageId) =>
  createCommonSelector(
    [(state) => state.pageComponentData.componentsListData],
    (componentsListData) => {
      const pageData = componentsListData[pageId] || {};

      return Object.entries(pageData).map(([componentType, data]) => ({
        componentType,
        data,
      }));
    }
  );

export const selectComponentDataByType = (pageId, componentType) =>
  createCommonSelector([selectComponentsByPageId(pageId)], (components) => {
    return (
      components.find(({ componentType: type }) => type === componentType)
        ?.data || []
    );
  });
