import React, { useCallback, useMemo, useState } from "react";
import { Box, List, useTheme } from "@mui/material";
import { LoginOutlined, LogoutOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Drawer, DrawerHeader } from "./Styles";
import Logo from "components/Logo/Logo";
import NavigationItem from "./components/NavigationItem";
import {
  LayoutNames,
  NAVIGATION_ITEM_ACTIVE_COLOR,
  PREVIEW_ROUTE_NAME,
  TEXT_CONSTANTS,
} from "Constants";
import { isPathPreview } from "utils";
import { logoStyles } from "styles/CommonStyles";

const Sidebar = ({ selectedPageId, onClick, type = "", onClose }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isPreview = isPathPreview(location.pathname);

  const [isOpen, setIsOpen] = useState(true);
  const { pagesList = [] } = useSelector((state) => state.pages);
  const filteredPageList = useMemo(
    () => pagesList.filter((page) => page.layoutCode !== LayoutNames.Landing),
    [pagesList]
  );
  const theme = useTheme();
  const { logo, palette } = theme;
  const { secondary, tertiary, accent1 , accent2 } = palette;

  const logoPath = logo;

  const handleDrawerToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handlePageClick = useCallback(
    (page) => {
       if(onClose) {
          onClose()
        }
      if (type === TEXT_CONSTANTS.ORGANIZATION_TEXT) {
        onClick(page);
      }
      else {
        if(page?.isExternal) {
          window.open(page?.pageUrl , "_blank")
        }
        else if (page?.pageSlug) {
          const formattedSlug = page.pageSlug.startsWith("/")
            ? page.pageSlug
            : `/${page.pageSlug}`;
          const path = isPreview
            ? `/${PREVIEW_ROUTE_NAME}/${projectId}${formattedSlug}`
            : `/${projectId}${formattedSlug}`;
          navigate(path);
        }
      }
       
    },
    [type, onClick, navigate, projectId, isPreview, onClose]
  );

  const getNavItemStyle = useCallback(
    (page) => ({
      display: "block",
      color: selectedPageId === page?.id ? accent1 : accent2,
      background:
        selectedPageId === page?.id
          ? tertiary || NAVIGATION_ITEM_ACTIVE_COLOR
          : "inherit",
      "&:hover": {
        background: tertiary || NAVIGATION_ITEM_ACTIVE_COLOR,
      },
    }),
    [selectedPageId, tertiary, accent1 , accent2]
  );
  return (
    <>
    <Drawer
      variant="permanent"
      open={isOpen}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: secondary.main,
        },
      }}
    >
      <DrawerHeader isOpen={isOpen}>
        <Box onClick={() => handlePageClick(filteredPageList?.[0])}>
          <Logo imagePath={logoPath} styles={logoStyles} isOpen={isOpen} />
        </Box>
      </DrawerHeader>
      <List
        sx={{
          maxHeight: "calc(100vh)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {filteredPageList.map((page) => (
          <NavigationItem
            key={page.id}
            page={page}
            selectedPageId={selectedPageId}
            secondaryColor={secondary.main}
            itemStyle={getNavItemStyle(page)}
            isOpen={isOpen}
            onClick={handlePageClick}
            accent1={accent1}
            accent2={accent2}
          />
        ))}
      </List>
      <Box sx={{display: {xs: "none", md: "flex"}}}>
      <NavigationItem
        customIcon={isOpen ? <LoginOutlined style={{color: accent2}}/> : <LogoutOutlined style={{color: accent2}}/>}
        customText="Collapse"
        itemStyle={getNavItemStyle({ id: "collapse" })}
        isOpen={isOpen}
        onClick={handleDrawerToggle}
      />
      </Box>
    </Drawer>
    </>
  );
};

export default Sidebar;