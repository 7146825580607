import React, { useMemo } from "react";
import { Box, Stack, styled } from "@mui/material";
import { sortDataByOrderId } from "utils";
import ContentBlock from "components/ContentBlock";
import CTAButton from "components/CTAButton/CTAButton";
import Loader from "components/Loader";
import { TEXT_CONSTANTS } from "Constants";
import Image from "components/Image";

const IntroDetailsCard = ({
  data: { carouselCTAs = [], texts = [], ...otherProps } = {},
  from,
  brandTags = [],
  isLoading,
}) => {
  const orderedTexts = useMemo(() => sortDataByOrderId(texts.$values), [texts]);
  const ctas = useMemo(() => carouselCTAs.$values, [carouselCTAs]);
  if (isLoading) {
    return (
      <LoadingContainer>
        <Loader fullPage={false} />
      </LoadingContainer>
    );
  }
  return (
    <StyledBox>
      <Image extraStyles={{ height: "100%" }} {...otherProps} />
      {orderedTexts?.length > 0 && (
        <DetailsCard>
          <Stack>
            {orderedTexts.map(
              ({ content, contentHTML, style, tagType }, index) => {
                const additionalStyles =
                  from === TEXT_CONSTANTS.ORGANIZATION_TEXT &&
                  (tagType === "h1" || tagType === "p")
                    ? {
                        ...brandTags?.[tagType],
                        padding: tagType === "p" ? "8px 0px 8px 0px" : "",
                      }
                    : {};
                return (
                  <ContentBlock
                    key={index}
                    contentHTML={contentHTML ? contentHTML : content}
                    content={content}
                    styles={{ ...style, ...additionalStyles }}
                    tagType={tagType}
                  />
                );
              }
            )}
          </Stack>
          {ctas?.length > 0 && <CTAButton cta={ctas[0]} from={from} />}
        </DetailsCard>
      )}
    </StyledBox>
  );
};
export default IntroDetailsCard;

const StyledBox = styled(Box)({
  width: "100%",
  position: "relative",
  height: "320px",
});

const DetailsCard = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  background: "rgba(255, 255, 255, 0.8)",
  borderRadius: "20px",
  padding: theme.spacing(4, 5),
  width: "80%",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
}));

const LoadingContainer = styled(Box)({
  height: "320px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
