import React, { memo, useMemo } from "react";
import { Typography } from "@mui/material";
import { generateStyles } from "styles/GenerateStyles";
import useGoogleFonts from "hooks/useGoogleFonts";
import { multilineEllipsis } from "styles/CommonStyles";
import { htmlTags } from "Constants";

const TextWrapper = memo(
  ({ title, styles = {}, sx = {}, variant = "body1", lines = null }) => {
    const fontFamily = useMemo(
      () => (styles?.fontFamily ? [styles.fontFamily] : []),
      [styles.fontFamily]
    );
    useGoogleFonts(fontFamily);

    const combinedStyles = useMemo(
      () => ({
        ...sx,
        ...generateStyles({ styles, disableBackground: true }),
        ...(lines ? multilineEllipsis(lines) : {}),
        wordBreak: "break-word",
      }),
      [sx, styles, lines]
    );

    return (
      <Typography
        sx={combinedStyles}
        variant={htmlTags[variant?.toLowerCase()]}
      >
        {title}
      </Typography>
    );
  }
);

export default TextWrapper;
