import React, { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { useProjectPages } from "hooks/useProjectPages";
import Loader from "components/Loader";
import NoDataFound from "components/NoDataContainer";
import { isPathPreview } from "utils";
import { PREVIEW_ROUTE_NAME } from "Constants";

const ProjectPageNavigator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isPreview = isPathPreview(location.pathname);

  const { projectId, pageSlug } = useParams();
  // Custom hook for pages fetching
  const { loading, pageData, errorMessage, fetchProjectPages } =
    useProjectPages(pageSlug);

  useEffect(() => {
    if (projectId) {
      fetchProjectPages(projectId);
    }
  }, [projectId, fetchProjectPages]);

  // Logging the pageData to inspect it
  useEffect(() => {
    if (pageData && pageData.pageSlug) {
      const formattedSlug = pageData.pageSlug.startsWith("/")
        ? pageData.pageSlug
        : `/${pageData.pageSlug}`;
      if (isPreview) {
        navigate(`/${PREVIEW_ROUTE_NAME}/${projectId}${formattedSlug}`);
      } else {
        navigate(`/${projectId}${formattedSlug}`);
      }
    }
  }, [pageData, navigate, projectId, isPreview]);

  if (loading) {
    return <Loader />;
  }
  if (errorMessage) {
    return <NoDataFound title={errorMessage} />;
  }
  return null;
};

export default ProjectPageNavigator;
