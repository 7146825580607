import React, { useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import NoDataContainer from "components/NoDataContainer";
import CarouselSlider from "components/CarouselSlider";
import { selectComponentDataByType } from "reduxStore/selectors/PageSelectors";
import { usePageComponents } from "hooks/usePageComponents";
import Loader from "components/Loader/Loader";
import { ComponentTypes, LayoutNames } from "Constants";
import LandingLogo from "components/Logo/LandingLogo";

const Landing = ({ pageId }) => {
  const { pagesList = [] } = useSelector((state) => state.pages);
  const filteredPageList = useMemo(
    () => pagesList.filter((page) => page.layoutCode !== LayoutNames.Landing),
    [pagesList]
  );
  const { loading, fetchPageComponents } = usePageComponents();
  const carouselComponents = useSelector(
    selectComponentDataByType(pageId, ComponentTypes.Carousel)
  );

  const carouselData = useMemo(() => {
    const [data = {}] = carouselComponents || [];
    return data;
  }, [carouselComponents]);

  const { carouselConfig = {}, carousalImages = {} } = carouselData || {};
  const carouselItems = carousalImages?.["$values"] || [];

  useEffect(() => {
    if (pageId) fetchPageComponents(pageId);
  }, [pageId, fetchPageComponents]);

  return (
    <Box position="relative" sx={{ overflow: "hidden" }}>
      {loading && <Loader />}
      {carouselItems.length > 0 ? (
        <>
          <LandingLogo firstPage={filteredPageList?.[0]} />
          <CarouselSlider
            carouselConfig={carouselConfig}
            carouselItems={carouselItems}
            carouselStyle={defaultStyles}
            applyGradient={true}
          />
        </>
      ) : (
        !loading && <NoDataContainer title="No Data Found." />
      )}
    </Box>
  );
};

export default Landing;

const defaultStyles = {
  height: "calc(100vh)",
  maxHeight: "100%",
};
