import React from "react";
import { Box } from "@mui/material";

import { imageComponentData, menuData } from "metaDataJson/Menu";
import HeaderComponent from "../Components/HeaderSection";
import { TEXT_CONSTANTS } from "Constants";
import IntroDetailsCard from "components/IntroDetailsCard";
import CardsList from "./components/CardsList";

const MenuLayout = ({ brandTags }) => {
  return (
    <Box flexGrow={1}>
      <HeaderComponent
        title={TEXT_CONSTANTS.MENU}
        subTitle={TEXT_CONSTANTS.MENU_SUBTITLE}
        styles={brandTags}
        titleVariant={"h2"}
        subTitleVariant={"p"}
      />
      {imageComponentData && (
        <IntroDetailsCard data={imageComponentData?.$values?.[0]} />
      )}

      <CardsList cardData={menuData} brandTags={brandTags} />
    </Box>
  );
};

export default MenuLayout;
