export const defaultStyles = {
  title: {},
  subTitle: {},
  description: {},
  media: { height: "auto", borderRadius: "30px" },
  cardContainer: {
    borderColor: "#D9D9D9",
    borderRadius: "30px",
    height: "100%",
    minHeight: "100%",
  },
  categoryBadgeStyles: {
    position: "absolute",
    top: 14,
    right: 14,
    // cursor: "pointer",
    // borderRadius: "20px",
    // textAlign: "center",
    // backgroundColor: "#FFFFFFB2",
    // padding: "8px 18px 8px 18px",
    // padding: "10px 10px 10px 10px",
    // color: "#5B7961",
    // fontSize:"10px",

  },
  gridContainer: { height: "430px" },
  carouselStyles: {
    maxHeight: "500px",
    minHeight: "500px",
  },

  cardActionsStyles: {
    position: "absolute",
    bottom: "2%",
    width: "90%",
    transform: "translateX(6%)",
    left: 0,
    right: 0,
    cursor: "pointer",
    background: "#ffffffb2",
    textAlign: "center",
    filter: "blur(4px) !important",
  },
  defaultCardStyles: {
    flex: 1,
    position: "relative",
    overflow: "hidden",
  },
  containerStyles: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  mediaStyles: {
    fontSize: "8px",
    fontWeight: 400,
    fontStyle: 1,
    textDecoration: "",
    foregroundColor: "#000000",
    backgroundColor: "#FFFFFF",
    textAlignment: 1,
    padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
    borderRadius: "50px",
    borderThickness: 0,
    borderColor: "#FFFFFF",
    opacity: 1,
  },
  cardStyles: {
    fontSize: "10px",
    fontWeight: 400,
    fontStyle: 1,
    textDecoration: "",
    foregroundColor: "#885636",
    backgroundColor: "#FFFFFF",
    padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
    borderRadius: "50px",
    borderThickness: 0,
    borderColor: "#FFFFFF",
    opacity: 1,
  },
  titleStyles: {
    
    fontStyle: "normal",
    backgroundColor: "transparent",
    padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
    borderRadius: 0,
    
    lineHeight: "normal",
    border: "none",
    opacity: 1,
    wordBreak: "break-word",
  },
  subTitleStyles: {
    
    fontStyle: "normal",
    backgroundColor: "transparent",
    padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
    borderRadius: 0,
    
    lineHeight: "normal",
    border: "none",
    opacity: 1,
    wordBreak: "break-word",
  },
  descriptionStyles: {
    
    fontStyle: "normal",
    backgroundColor: "transparent",
    padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
    borderRadius: 0,
    
    lineHeight: "normal",
    border: "none",
    opacity: 1,
    wordBreak: "break-word !important",
  },
  buttonStyles: {
    fontWeight: 400,

    color:"#5B7961 !important",
    foregroundColor: "#5B7961",
    minWidth: "64px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily: "Poppins",

    backgroundColor: "#FFFFFFB2 !important",
    opancity: 1,
    padding: "6px 18px 6px 18px",
    borderRadius: "19px",
    fontSize: "15px",
    position: "relative",
  },

};
