import React, { memo, useMemo } from "react";
import { Box, Stack, styled } from "@mui/material";

import Image from "../Image";
import { sortDataByOrderId } from "utils";
import ContentBlock from "components/ContentBlock";
import CTAButton from "components/CTAButton";
import {
  defaultBorderThickness,
  RadialGradientOverlay,
} from "styles/CommonStyles";
import { buttonPosition, TEXT_CONSTANTS } from "Constants";

const getAdditionalStyles = (from, tagType, brandTags , alignment) => {
  const isOrganizationText = from === TEXT_CONSTANTS.ORGANIZATION_TEXT;
  const isValidTagType = ["h1", "h3", "p"].includes(tagType);
  if (isOrganizationText && isValidTagType) {
    return { 
      ...brandTags?.[tagType], 
      textAlignment: alignment || 1 
    };
  }
  return {};
};

const CarouselItem = memo(
  ({
    item: {
      texts = { $values: [] },
      carouselCTAs = { $values: [] },
      ...otherProps
    },
    carouselStyle = {},
    lines,
    from,
    brandTags,
    applyGradient = false,
    boxStyles = {},
  }) => {
    const orderedTexts = useMemo(
      () => sortDataByOrderId(texts.$values),
      [texts]
    );
    const ctas = useMemo(() => carouselCTAs.$values, [carouselCTAs]);
    
    return (
      <>
        {applyGradient && <RadialGradientOverlay />}
        <Image
          extraStyles={{ ...carouselStyle, cursor: "pointer" }}
          {...otherProps}
        />
        <StyledStack sx={{ ...boxStyles }}>
          {orderedTexts.map(({ content, contentHTML, style, tagType, id }) => {
            const additionalStyles = getAdditionalStyles(
              from,
              tagType,
              brandTags,
              style?.textAlignment,

            );
            const combinedStyles = {
              ...style,
              ...additionalStyles,
              ...(from === TEXT_CONSTANTS.ORGANIZATION_TEXT
                ? defaultBorderThickness
                : {}),
            };
            return (
              <ContentBlock
                key={id}
                contentHTML={contentHTML}
                content={content}
                styles={combinedStyles}
                tagType={tagType}
                lines={lines}
              />
            );
          })}

          {ctas?.length > 0 && <Box sx={{textAlign: buttonPosition[ctas?.[0]?.style?.textAlignment]}}><CTAButton cta={ctas[0]} brandTags={brandTags} from={from} /></Box>}
        </StyledStack>
      </>
    );
  }
);

export default CarouselItem;

const StyledStack = styled(Stack)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "block",
  width: "85%",
  padding: "20px",
}));
