import React, { memo, useMemo } from "react";
import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material";
import { generateStyles } from "styles/GenerateStyles";
import useGoogleFonts from "hooks/useGoogleFonts";

const Button = memo(({ name, styles, handleClick, sx }) => {
  const fontFamily = useMemo(
    () => (styles?.fontFamily ? [styles.fontFamily] : []),
    [styles?.fontFamily]
  );
  useGoogleFonts(fontFamily);
  return (
    <StyledButton
      variant="contained"
      sx={{ ...sx }}
      styles={styles}
      onClick={handleClick}
    >
      {name}
    </StyledButton>
  );
});

export default Button;

const StyledButton = styled(MuiButton)(({ styles }) => ({
  ...generateStyles({ styles }),
}));
