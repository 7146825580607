import { useCallback, useEffect, useState } from "react";
import { fetchThemeByOrganizationIdApi } from "services/ApiService";
import { updateFavicon } from "utils";
import { ERROR_MESSAGES } from "StatusAlerts";
import useCustomSnackbar from "./useCustomSnackbar";

// Custom hook to fetch project branding
export const useOrganizationBranding = (organizationId) => {
  const [loading, setLoading] = useState(true);
  const snackbar = useCustomSnackbar();
  const [branding, setBranding] = useState({});
  const [error, setError] = useState(null);

  const onSetError = useCallback(
    (message) => {
      snackbar.showError(message, { autoHideDuration: 3000 });
    },
    [snackbar]
  );

  const fetchOrganizationBranding = useCallback(async () => {
    try {
      setLoading(true);
      const { result, statusCode } = await fetchThemeByOrganizationIdApi(
        organizationId
      );
      if (statusCode === 200) {
        setBranding(result.data);
        if (result?.data?.favIcon) {
          updateFavicon(result.data.favIcon);
        }
      } else if (statusCode === 400) {
        setError(ERROR_MESSAGES.INVALID_ORGANIZATION_ID);
      } else {
        onSetError(ERROR_MESSAGES.FAILED_TO_FETCH_BRANDING_DATA);
      }
    } catch (err) {
      onSetError(ERROR_MESSAGES.UN_EXPECTED_ERROR_OCCUR);
    } finally {
      setLoading(false);
    }
  }, [organizationId, onSetError]);
  useEffect(() => {
    if (organizationId) {
      fetchOrganizationBranding();
    }
  }, [organizationId, fetchOrganizationBranding]);
  return { loading, branding, error };
};
