import LandingImage1 from "assets/images/Land_img1.jpeg";
import LandingImage2 from "assets/images/Land_img2.png";
import LandingImage3 from "assets/images/Land_img3.png";
import LandingImage4 from "assets/images/Land_img4.png";
import LandingImage5 from "assets/images/Land_img5.png";
import { LANDING_URL } from "Constants";

export const carouselItems = [
  {
    $id: "7",
    id: "4c30e874-0305-4c6d-a37f-d5c6eeef05ab",
    componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
    contextType: 4,
    styleId: "48b2c007-2922-45a4-b959-6eee2aac3576",
    imageURL: LandingImage2,
    isExternal: false,
    linkURL: null,
    openInWindow: true,
    imageName: "home_Caraousal_Image2",
    altText: "Landing Carousel Image",
    sizeOption: 2,
    maximumWidth: 100,
    maximumHeight: 500,
    cornerRadius: 10,
    order: 2,
    objectFit: 3,
    texts: {
      $id: "8",
      $values: [
        {
          $id: "9",
          id: "28c5fac9-811d-485c-94e3-b1233ce22514",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "4c30e874-0305-4c6d-a37f-d5c6eeef05ab",
          styleId: "d000f043-3f1a-4a29-99e1-91c25f8a2185",
          content: "Longer Days, Longer Stays",
          contentHTML: "Longer Days, Longer Stays",
          tagType: "h1",
          order: 1,
          style: {},
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.740886+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
        {
          $id: "11",
          id: "95c6d160-9c08-4cbc-98ae-001eff98ed6c",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "4c30e874-0305-4c6d-a37f-d5c6eeef05ab",
          styleId: "60d09ec4-26b8-47c9-8232-ebf93cceeb90",
          content:
            "Make the most of your time in Laguna Beach with your own curated blend of coastal adventures and total relaxation. From morning golf to beach days and sunset swims to fireside cocktails, you’ll be asking to stay even longer. ",
          contentHTML:
            "<span>Make the most of your time in Laguna Beach with your own curated blend of coastal adventures and total relaxation. From morning golf to beach days and sunset swims to fireside cocktails, you’ll be asking to stay even longer. </span>",
          tagType: "h3",
          order: 2,
          style: {},
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.749524+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
      ],
    },
    carouselCTAs: {
      $id: "13",
      $values: [
        {
          $id: "14",
          id: "2fb8a433-0b3f-4657-ba85-6de84a1fab6f",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "4c30e874-0305-4c6d-a37f-d5c6eeef05ab",
          styleId: "23fe0b1a-9439-4178-b4c5-43d8b242578c",
          text: "Book now",
          uRL: LANDING_URL,
          isExternal: true,
          buttonType: "primaryButton",
          style: {
            $id: "15",
            id: "23fe0b1a-9439-4178-b4c5-43d8b242578c",
            fontFamily: "Poppins",
            fontSize: "10px",
            fontWeight: 400,
            fontStyle: 1,
            textDecoration: "",
            foregroundColor: "#885636",
            backgroundColor: "#FFFFFF",
            textAlignment: 1,
            padding: "6px 20px 6px 20px",
            margin: "0px 0px 0px 0px",
            borderRadius: "20px",
            borderThickness: 0,
            borderColor: "#FFFFFF",
            opacity: 1,
            hoverEffect: "",
            customCSS: "",
            isActive: true,
            createdBy: null,
            createdDate: null,
            modifiedBy: null,
            modifiedDate: null,
            isDeleted: false,
          },
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.758604+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
      ],
    },
    style: {
      $id: "16",
      id: "48b2c007-2922-45a4-b959-6eee2aac3576",
      fontFamily: "Poppins",
      fontSize: "8px",
      fontWeight: 400,
      fontStyle: 1,
      textDecoration: "",
      foregroundColor: "#000000",
      backgroundColor: "#FFFFFF",
      textAlignment: 1,
      padding: "0px 0px 0px 0px",
      margin: "0px 0px 0px 0px",
      borderRadius: "0px",
      borderThickness: 0,
      borderColor: "#FFFFFF",
      opacity: 1,
      hoverEffect: "",
      customCSS: "",
      isActive: true,
      createdBy: null,
      createdDate: null,
      modifiedBy: null,
      modifiedDate: null,
      isDeleted: false,
    },
    isActive: true,
    createdBy: null,
    createdDate: "2024-10-15T04:14:05.731217+00:00",
    modifiedBy: null,
    modifiedDate: null,
    isDeleted: false,
  },
  {
    $id: "17",
    id: "547d19dd-ab89-4f46-aa7a-9ec7e2e31c94",
    componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
    contextType: 4,
    styleId: "3c16cd6f-967c-4260-a613-1f89ab090397",
    imageURL: LandingImage1,
    isExternal: false,
    linkURL: null,
    openInWindow: true,
    imageName: "land_Caraousal_Image1",
    altText: "Landing Carousel Image",
    sizeOption: 2,
    maximumWidth: 100,
    maximumHeight: 500,
    cornerRadius: 10,
    order: 1,
    objectFit: 3,
    texts: {
      $id: "18",
      $values: [
        {
          $id: "19",
          id: "1cdbef53-7b78-4019-a216-cb8fd97705e0",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "547d19dd-ab89-4f46-aa7a-9ec7e2e31c94",
          styleId: "86b3625b-169f-47b9-ab0c-198b4c692bb6",
          content: "Longer Days, Longer Stays",
          contentHTML: "Longer Days, Longer Stays",
          tagType: "h1",
          order: 1,
          style: {},
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.693003+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
        {
          $id: "21",
          id: "c885f93b-ba5d-4087-b0e6-ac78ba9e6a8d",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "547d19dd-ab89-4f46-aa7a-9ec7e2e31c94",
          styleId: "0a6db4aa-3a11-45ac-a2c8-2faa2f88538f",
          content:
            "Make the most of your time in Laguna Beach with your own curated blend of coastal adventures and total relaxation. From morning golf to beach days and sunset swims to fireside cocktails, you’ll be asking to stay even longer. ",
          contentHTML:
            "<span>Make the most of your time in Laguna Beach with your own curated blend of coastal adventures and total relaxation. From morning golf to beach days and sunset swims to fireside cocktails, you’ll be asking to stay even longer. </span>",
          tagType: "h3",
          order: 2,
          style: {},
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.699999+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
      ],
    },
    carouselCTAs: {
      $id: "23",
      $values: [
        {
          $id: "24",
          id: "fe28ea52-afd2-4041-bc2f-016ea753b3b9",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "547d19dd-ab89-4f46-aa7a-9ec7e2e31c94",
          styleId: "1ed182f8-0506-43c4-8e43-5c035d0bf187",
          text: "Book now",
          uRL: LANDING_URL,
          isExternal: true,
          buttonType: "primaryButton",
          style: {
            $id: "25",
            id: "1ed182f8-0506-43c4-8e43-5c035d0bf187",
            fontFamily: "Poppins",
            fontSize: "10px",
            fontWeight: 400,
            fontStyle: 1,
            textDecoration: "",
            foregroundColor: "#885636",
            backgroundColor: "#FFFFFF",
            textAlignment: 1,
            padding: "6px 20px 6px 20px",
            margin: "0px 0px 0px 0px",
            borderRadius: "20px",
            borderThickness: 0,
            borderColor: "#FFFFFF",
            opacity: 1,
            hoverEffect: "",
            customCSS: "",
            isActive: true,
            createdBy: null,
            createdDate: null,
            modifiedBy: null,
            modifiedDate: null,
            isDeleted: false,
          },
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.708595+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
      ],
    },
    style: {
      $id: "26",
      id: "3c16cd6f-967c-4260-a613-1f89ab090397",
      fontFamily: "Poppins",
      fontSize: "8px",
      fontWeight: 400,
      fontStyle: 1,
      textDecoration: "",
      foregroundColor: "#000000",
      backgroundColor: "#FFFFFF",
      textAlignment: 1,
      padding: "0px 0px 0px 0px",
      margin: "0px 0px 0px 0px",
      borderRadius: "0px",
      borderThickness: 0,
      borderColor: "#FFFFFF",
      opacity: 1,
      hoverEffect: "",
      customCSS: "",
      isActive: true,
      createdBy: null,
      createdDate: null,
      modifiedBy: null,
      modifiedDate: null,
      isDeleted: false,
    },
    isActive: true,
    createdBy: null,
    createdDate: "2024-10-15T04:14:05.684271+00:00",
    modifiedBy: null,
    modifiedDate: null,
    isDeleted: false,
  },
  {
    $id: "27",
    id: "55921033-0daf-4e93-97e1-781bb091dd43",
    componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
    contextType: 4,
    styleId: "3bf05e56-385f-44aa-87d5-abc8d3d8be1f",
    imageURL: LandingImage5,
    isExternal: false,
    linkURL: null,
    openInWindow: true,
    imageName: "home_Caraousal_Image5",
    altText: "Landing Carousel Image",
    sizeOption: 2,
    maximumWidth: 100,
    maximumHeight: 500,
    cornerRadius: 10,
    order: 5,
    objectFit: 3,
    texts: {
      $id: "28",
      $values: [
        {
          $id: "29",
          id: "754927ce-b9b7-4ef2-9cf3-0fc5671162fd",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "55921033-0daf-4e93-97e1-781bb091dd43",
          styleId: "f4800fb2-8578-43e3-8b9f-8a263f3a7936",
          content:
            "Make the most of your time in Laguna Beach with your own curated blend of coastal adventures and total relaxation. From morning golf to beach days and sunset swims to fireside cocktails, you’ll be asking to stay even longer. ",
          contentHTML:
            "Make the most of your time in Laguna Beach with your own curated blend of coastal adventures and total relaxation. From morning golf to beach days and sunset swims to fireside cocktails, you’ll be asking to stay even longer. ",
          tagType: "h3",
          order: 2,
          style: {},
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.894163+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
        {
          $id: "31",
          id: "a1292ef3-d6b5-4498-9696-b8bd8700b131",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "55921033-0daf-4e93-97e1-781bb091dd43",
          styleId: "ebec5773-e622-4c07-bd0b-d3ff0b154626",
          content: "Longer Days, Longer Stays",
          contentHTML: "Longer Days, Longer Stays",
          tagType: "h1",
          order: 1,
          style: {},
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.867057+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
      ],
    },
    carouselCTAs: {
      $id: "33",
      $values: [
        {
          $id: "34",
          id: "e155b5ba-559b-49e1-a692-c19ad2701d5c",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "55921033-0daf-4e93-97e1-781bb091dd43",
          styleId: "6ab48ef3-9866-4410-96d4-4001d84aef91",
          text: "Book now",
          uRL: LANDING_URL,
          isExternal: true,
          buttonType: "primaryButton",
          style: {
            $id: "35",
            id: "6ab48ef3-9866-4410-96d4-4001d84aef91",
            fontFamily: "Poppins",
            fontSize: "10px",
            fontWeight: 400,
            fontStyle: 1,
            textDecoration: "",
            foregroundColor: "#885636",
            backgroundColor: "#FFFFFF",
            textAlignment: 1,
            padding: "6px 20px 6px 20px",
            margin: "0px 0px 0px 0px",
            borderRadius: "20px",
            borderThickness: 0,
            borderColor: "#FFFFFF",
            opacity: 1,
            hoverEffect: "",
            customCSS: "",
            isActive: true,
            createdBy: null,
            createdDate: null,
            modifiedBy: null,
            modifiedDate: null,
            isDeleted: false,
          },
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.904748+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
      ],
    },
    style: {
      $id: "36",
      id: "3bf05e56-385f-44aa-87d5-abc8d3d8be1f",
      fontFamily: "Poppins",
      fontSize: "8px",
      fontWeight: 400,
      fontStyle: 1,
      textDecoration: "",
      foregroundColor: "#000000",
      backgroundColor: "#FFFFFF",
      textAlignment: 1,
      padding: "0px 0px 0px 0px",
      margin: "0px 0px 0px 0px",
      borderRadius: "0px",
      borderThickness: 0,
      borderColor: "#FFFFFF",
      opacity: 1,
    },
    isActive: true,
    createdBy: null,
    createdDate: "2024-10-15T04:14:05.852998+00:00",
    modifiedBy: null,
    modifiedDate: null,
    isDeleted: false,
  },
  {
    $id: "37",
    id: "6ac1859f-c49c-46ed-bae4-37f9afbffe2f",
    componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
    contextType: 4,
    styleId: "c1d13d36-78e9-4a2e-bb2c-f3312f0f8d2d",
    imageURL: LandingImage3,
    isExternal: false,
    linkURL: null,
    openInWindow: true,
    imageName: "home_Caraousal_Image3",
    altText: "Landing Carousel Image",
    sizeOption: 2,
    maximumWidth: 100,
    maximumHeight: 500,
    cornerRadius: 10,
    order: 3,
    objectFit: 3,
    texts: {
      $id: "38",
      $values: [
        {
          $id: "39",
          id: "3163c074-39f9-40cc-a61b-f8343f4ef47f",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "6ac1859f-c49c-46ed-bae4-37f9afbffe2f",
          styleId: "878a9003-5fde-4e2b-9347-ad27c76eb8be",
          content:
            "Make the most of your time in Laguna Beach with your own curated blend of coastal adventures and total relaxation. From morning golf to beach days and sunset swims to fireside cocktails, you’ll be asking to stay even longer. ",
          contentHTML:
            "<span>Make the most of your time in Laguna Beach with your own curated blend of coastal adventures and total relaxation. From morning golf to beach days and sunset swims to fireside cocktails, you’ll be asking to stay even longer. </span>",
          tagType: "h3",
          order: 2,
          style: {},
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.787816+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
        {
          $id: "41",
          id: "51177869-30b3-462d-ac7d-84cd79fe4740",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "6ac1859f-c49c-46ed-bae4-37f9afbffe2f",
          styleId: "b4ebb504-2f67-4d37-9275-d03b619eb46a",
          content: "Longer Days, Longer Stays",
          contentHTML: "Longer Days, Longer Stays",
          tagType: "h1",
          order: 1,
          style: {},
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.777029+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
      ],
    },
    carouselCTAs: {
      $id: "43",
      $values: [
        {
          $id: "44",
          id: "c8172ae9-081f-4747-abb8-613e0f0d0efc",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "6ac1859f-c49c-46ed-bae4-37f9afbffe2f",
          styleId: "6b0a46fb-54ba-4caf-a6d0-b45af073bbf5",
          text: "Book now",
          uRL: LANDING_URL,
          isExternal: true,
          buttonType: "primaryButton",
          style: {
            $id: "45",
            id: "6b0a46fb-54ba-4caf-a6d0-b45af073bbf5",
            fontFamily: "Poppins",
            fontSize: "10px",
            fontWeight: 400,
            fontStyle: 1,
            textDecoration: "",
            foregroundColor: "#885636",
            backgroundColor: "#FFFFFF",
            textAlignment: 1,
            padding: "6px 20px 6px 20px",
            margin: "0px 0px 0px 0px",
            borderRadius: "20px",
            borderThickness: 0,
            borderColor: "#FFFFFF",
            opacity: 1,
            hoverEffect: "",
            customCSS: "",
            isActive: true,
            createdBy: null,
            createdDate: null,
            modifiedBy: null,
            modifiedDate: null,
            isDeleted: false,
          },
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.794598+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
      ],
    },
    style: {
      $id: "46",
      id: "c1d13d36-78e9-4a2e-bb2c-f3312f0f8d2d",
      fontFamily: "Poppins",
      fontSize: "8px",
      fontWeight: 400,
      fontStyle: 1,
      textDecoration: "",
      foregroundColor: "#000000",
      backgroundColor: "#FFFFFF",
      textAlignment: 1,
      padding: "0px 0px 0px 0px",
      margin: "0px 0px 0px 0px",
      borderRadius: "0px",
      borderThickness: 0,
      borderColor: "#FFFFFF",
      opacity: 1,
    },
    isActive: true,
    createdBy: null,
    createdDate: "2024-10-15T04:14:05.768749+00:00",
    modifiedBy: null,
    modifiedDate: null,
    isDeleted: false,
  },
  {
    $id: "47",
    id: "da5ab770-eb7d-4801-b54f-19092c786395",
    componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
    contextType: 4,
    styleId: "0695df6a-01df-49f2-875f-a889fd25a7ec",
    imageURL: LandingImage4,
    isExternal: false,
    linkURL: null,
    openInWindow: true,
    imageName: "home_Caraousal_Image4",
    altText: "Landing Carousel Image",
    sizeOption: 2,
    maximumWidth: 100,
    maximumHeight: 500,
    cornerRadius: 10,
    order: 4,
    objectFit: 3,
    texts: {
      $id: "48",
      $values: [
        {
          $id: "49",
          id: "1b17bb59-c484-413c-b979-fcd2e4db9b77",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "da5ab770-eb7d-4801-b54f-19092c786395",
          styleId: "2c0a304c-a4a1-497f-8d72-445831013113",
          content: "Longer Days, Longer Stays",
          contentHTML: "Longer Days, Longer Stays",
          tagType: "h1",
          order: 1,
          style: {},
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.808387+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
        {
          $id: "51",
          id: "c8031e3a-c2e0-406a-8977-66796752eebb",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "da5ab770-eb7d-4801-b54f-19092c786395",
          styleId: "4db2f88f-15a2-48a7-8234-d977c34d66ec",
          content:
            "Make the most of your time in Laguna Beach with your own curated blend of coastal adventures and total relaxation. From morning golf to beach days and sunset swims to fireside cocktails, you’ll be asking to stay even longer. ",
          contentHTML:
            "<span>Make the most of your time in Laguna Beach with your own curated blend of coastal adventures and total relaxation. From morning golf to beach days and sunset swims to fireside cocktails, you’ll be asking to stay even longer. </span>",
          tagType: "h3",
          order: 2,
          style: {},
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.819468+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
      ],
    },
    carouselCTAs: {
      $id: "53",
      $values: [
        {
          $id: "54",
          id: "6ab78309-cb92-4af2-88c6-4b68c3101129",
          componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
          contextType: 4,
          imagesId: "da5ab770-eb7d-4801-b54f-19092c786395",
          styleId: "8e9807ca-2998-444d-9bbe-0fa4d098ed7c",
          text: "Book now",
          uRL: LANDING_URL,
          isExternal: true,
          buttonType: "primaryButton",
          style: {
            $id: "55",
            id: "8e9807ca-2998-444d-9bbe-0fa4d098ed7c",
            fontFamily: "Poppins",
            fontSize: "10px",
            fontWeight: 400,
            fontStyle: 1,
            textDecoration: "",
            foregroundColor: "#885636",
            backgroundColor: "#FFFFFF",
            textAlignment: 1,
            padding: "6px 20px 6px 20px",
            margin: "0px 0px 0px 0px",
            borderRadius: "20px",
            borderThickness: 0,
            borderColor: "#FFFFFF",
            opacity: 1,
            hoverEffect: "",
            customCSS: "",
            isActive: true,
            createdBy: null,
            createdDate: null,
            modifiedBy: null,
            modifiedDate: null,
            isDeleted: false,
          },
          isActive: true,
          createdBy: null,
          createdDate: "2024-10-15T04:14:05.836633+00:00",
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
      ],
    },
    style: {
      $id: "56",
      id: "0695df6a-01df-49f2-875f-a889fd25a7ec",
      fontFamily: "Poppins",
      fontSize: "8px",
      fontWeight: 400,
      fontStyle: 1,
      textDecoration: "",
      foregroundColor: "#000000",
      backgroundColor: "#FFFFFF",
      textAlignment: 1,
      padding: "0px 0px 0px 0px",
      margin: "0px 0px 0px 0px",
      borderRadius: "0px",
      borderThickness: 0,
      borderColor: "#FFFFFF",
      opacity: 1,
    },
    isActive: true,
    createdBy: null,
    createdDate: "2024-10-15T04:14:05.801274+00:00",
    modifiedBy: null,
    modifiedDate: null,
    isDeleted: false,
  },
];
export const carouselConfig = {
  $id: "2",
  id: "bd86ed94-a71a-480c-b430-6e9e2438fb10",
  componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
  carouselIconsId: null,
  styleId: "d9c6ec0e-69e6-4b1e-8da4-7fa77ce8f237",
  slideTransition: 1,
  navigationStyle: "Arrows",
  slidePerPage: 1,
  transistionTime: 300,
  leftNavIconId: "6c0212e8-7961-4c29-ac80-e9271f37d2a7",
  rightNavIconId: "9bab9ada-7724-4018-89be-33adac005c78",
  loopSlides: true,
  autoplay: false,
  showNavigationArrows: true,
  showThumbnails: false,
  showDotNavigation: true,
  component: null,
  carouselIcons: null,
  leftNavIcon: {
    $id: "3",
    id: "6c0212e8-7961-4c29-ac80-e9271f37d2a7",
    componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
    imageId: null,
    styleId: null,
    content: "Sample content for the carousel item.",
    uRL: "",
    colorHex: "#000000",
    opacity: 0,
    component: null,
    isActive: true,
    createdBy: null,
    createdDate: null,
    modifiedBy: null,
    modifiedDate: null,
    isDeleted: false,
  },
  rightNavIcon: {
    $id: "4",
    id: "9bab9ada-7724-4018-89be-33adac005c78",
    componentId: "081b8fd7-45e5-4f40-b1ef-c6d6740f63a7",
    imageId: null,
    styleId: null,
    content: "Sample content for the carousel item.",
    uRL: "",
    colorHex: "#000000",
    opacity: 0,
    component: null,
    isActive: true,
    createdBy: null,
    createdDate: null,
    modifiedBy: null,
    modifiedDate: null,
    isDeleted: false,
  },
  style: {
    $id: "5",
    id: "d9c6ec0e-69e6-4b1e-8da4-7fa77ce8f237",
    fontFamily: "Poppins",
    fontSize: "15px",
    fontWeight: 400,
    fontStyle: 1,
    textDecoration: "",
    foregroundColor: "#000000",
    backgroundColor: "#FFFFFF00",
    textAlignment: 1,
    padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
    borderRadius: "0",
    borderThickness: 0,
    borderColor: "#FFFFFF",
    opacity: 1,
    hoverEffect: "",
    customCSS: "",
    isActive: true,
    createdBy: null,
    createdDate: null,
    modifiedBy: null,
    modifiedDate: null,
    isDeleted: false,
  },
  isActive: true,
  createdBy: "System",
  createdDate: "2024-10-15T04:14:05.673248+00:00",
  modifiedBy: null,
  modifiedDate: null,
  isDeleted: false,
};
