import React from "react";
import { Grid } from "@mui/material";
import CardSection from "components/CardSection/CardSection";
import { defaultStyles } from "../DefaultStyles";
import { TEXT_CONSTANTS } from "Constants";

const CardList = ({ cardData, tagStyle }) => {
  return (
    <Grid container spacing={3}>
      {cardData.map((card) => (
        <Grid item xs={6} md={3} lg={3} key={card?.id}>
          <CardSection
            image={card.image}
            title={card.title}
            description={card.description}
            onLearnMoreClick={() => console.log("Learn More clicked!")}
            imagePosition={TEXT_CONSTANTS.TOP}
            buttonPosition={TEXT_CONSTANTS.TOP}
            cardStyles={{ margin: 2 }}
            styles={tagStyle}
            titleVariant="h3"
            subTitleVariant="h4"
            descriptionVariant="p"
            defaultStyles={defaultStyles}
            CTALink={card.CTALink}
            isExternal={card.isCTAExternal}
            {...{buttonType: card?.buttonType || ""}}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CardList;
