import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Sidebar from 'pages/WithSidebar/Sidebar';
import { Box, Drawer, useTheme } from '@mui/material';
import Logo from 'components/Logo/Logo';

const drawerWidth = 240;

const MobileHeader = ({ pageId, onClick, type }) => {
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  return (
    <>
      <AppBar component="nav" sx={{background: "#fff !important"}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'block' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box m={"auto"} >
            <Logo imagePath={theme.logo} extraStyles={{width: "70px", height: "40px"}} isOpen={true} />
          </Box>

        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <Sidebar selectedPageId={pageId} type={type} onClose={handleDrawerToggle} onClick={onClick}/>
      </Drawer>
    </>
  );
}


export default MobileHeader;
