import React, { useEffect, useMemo } from "react";

import { useSelector } from "react-redux";


import { usePageComponents } from "hooks/usePageComponents";
import {
  selectComponentDataByType,
  selectPageComponents,
} from "reduxStore/selectors/PageSelectors";
import Loader from "components/Loader";
import HeaderWithTags from "components/HeaderWithTags";
import { ComponentTypes } from "Constants";
import IntroDetailsCard from "components/IntroDetailsCard";
import CardsList from "./components/CardsList";

const Menu = ({ pageId }) => {
  const { loading, fetchPageComponents } = usePageComponents();
  const { pageHtmlTags } = useSelector(selectPageComponents);

  const componentsData = useSelector(
    selectComponentDataByType(pageId, ComponentTypes.Image)
  );
  const imageComponentData = useMemo(() => {
    const [data = {}] = componentsData || [];
    return data;
  }, [componentsData]);
  const cardsData = useSelector(
    selectComponentDataByType(pageId, ComponentTypes.CardGroup)
  );

  const cardsDataListMemo = useMemo(() => {
    const cardData = cardsData.flatMap((item) => item["$values"]);

    return cardData?.sort((a, b) => a.order - b.order) || [];
  }, [cardsData]);

  useEffect(() => {
    if (pageId) fetchPageComponents(pageId);
  }, [pageId, fetchPageComponents]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {pageHtmlTags?.length > 0 && (
            <HeaderWithTags pageHtmlTags={pageHtmlTags} />
          )}
          {imageComponentData && (
            <IntroDetailsCard data={imageComponentData?.$values?.[0]} />
          )}

          <CardsList cardData={cardsDataListMemo} />
        </>
      )}
    </>
  );
};

export default Menu;
