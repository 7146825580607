import { useCallback, useEffect, useState } from "react";
import { onFetchProjectBranding } from "services/ApiService";

import { updateFavicon } from "utils";
import { ERROR_MESSAGES } from "StatusAlerts";

import useCustomSnackbar from "./useCustomSnackbar";

// Custom hook to fetch project branding
export const useProjectBranding = (projectId) => {
  const snackbar = useCustomSnackbar();
  const [loading, setLoading] = useState(true);
  const [branding, setBranding] = useState({});
  const [error, setError] = useState(null);

  const onSetError = useCallback(
    (message) => {
      setError(message);
      snackbar.showError(message, { autoHideDuration: 3000 });
    },
    [snackbar]
  );

  const fetchProjectBranding = useCallback(async () => {
    try {
      setLoading(true);
      const { result, statusCode } = await onFetchProjectBranding(projectId);
      setLoading(false);
      if (statusCode === 200) {
        setBranding(result.data);
        if (result?.data?.favIcon) {
          updateFavicon(result.data.favIcon);
        }
      } else {
        onSetError(ERROR_MESSAGES.FAILED_TO_FETCH_BRANDING_DATA);
      }
    } catch (err) {
      onSetError(ERROR_MESSAGES.UN_EXPECTED_ERROR_OCCUR);
    } finally {
      setLoading(false);
    }
  }, [projectId, onSetError]);

  useEffect(() => {
    if (projectId) {
      fetchProjectBranding();
    }
  }, [projectId, fetchProjectBranding]);

  return { loading, branding, error };
};
