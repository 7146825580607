import React, { memo, useMemo } from "react";
import { Typography } from "@mui/material";
import DOMPurify from "dompurify";
import useGoogleFonts from "hooks/useGoogleFonts";
import { generateStyles } from "styles/GenerateStyles";

const HtmlContentWrapper = memo(({ contentHTML = "", styles = {}  , tagType}) => {
  const fontsToLoad = useMemo(
    () => (styles?.fontFamily ? [styles.fontFamily] : []),
    [styles?.fontFamily]
  );
  useGoogleFonts(fontsToLoad);
  const combinedStyles = useMemo(
    () =>
      generateStyles({
        styles: styles,
        disableBackground: true,
      }),
    [styles]
  );

  const sanitizedContentHTML = useMemo(
    () => DOMPurify.sanitize(contentHTML || ""),
    [contentHTML]
  );

  return (
    sanitizedContentHTML && (
      <Typography
        component={tagType}
        variant={tagType}
        sx={combinedStyles}
        dangerouslySetInnerHTML={{ __html: sanitizedContentHTML }}
      />
    )
  );
});

export default HtmlContentWrapper;
