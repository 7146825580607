export const cardData = {
  $id: "2",
  id: "ac95558b-aef0-40ea-aec2-d9ed543dbb20",
  styleId: "895c9565-f0a1-4672-9035-c7f49b48e747",
  cardType: 2,
  viewType: "horizontal",
  order: 0,
  description: "A card view with ImageFirst display style.",
  contextID: "1c5efbc0-df0f-4a7d-ae8d-f13e1408dd4d",
  contextType: 0,
  cardConfig: "",
  image: {
    $id: "3",
    id: "f6010c6b-0162-4b55-bf66-f8b7cb4664e1",
    componentId: "ac95558b-aef0-40ea-aec2-d9ed543dbb20",
    contextType: 0,
    styleId: "efcea802-32e1-48a8-86f3-0ae9b7bfa02c",
    imageURL:
      "https://dev.gems.intelity.com/images/static-images/Home/Home_cardview_img-1.png",
    isExternal: false,
    linkURL: null,
    openInWindow: true,
    imageName: "Card_Image1",
    altText: "",
    sizeOption: 2,
    maximumWidth: 100,
    maximumHeight: 200,
    cornerRadius: 10,
    order: 1,
    objectFit: 3,
    texts: null,
    carouselCTAs: null,
    style: {
      $id: "4",
      id: "efcea802-32e1-48a8-86f3-0ae9b7bfa02c",
      fontFamily: "Poppins",
      fontSize: "8px",
      fontWeight: 400,
      fontStyle: 1,
      textDecoration: "",
      foregroundColor: "#000000",
      backgroundColor: "#ffffff",
      textAlignment: 1,
      padding: "0px 0px 0px 0px",
      margin: "0px 0px 0px 0px",
      borderRadius: "50px",
      borderThickness: 0,
      borderColor: "#FFFFFF",
      opacity: 1,
      hoverEffect: "",
      customCSS: "",
      isActive: true,
      createdBy: null,
      createdDate: null,
      modifiedBy: null,
      modifiedDate: null,
      isDeleted: false,
    },
    isActive: true,
    createdBy: null,
    createdDate: "2024-10-11T12:56:24.0639+00:00",
    modifiedBy: null,
    modifiedDate: null,
    isDeleted: false,
  },
  texts: {
    $id: "5",
    $values: [
      {
        $id: "6",
        id: "954d1d42-a248-429a-85c9-0095122fcbbc",
        componentId: "ac95558b-aef0-40ea-aec2-d9ed543dbb20",
        contextType: 0,
        imagesId: null,
        styleId: "0375a5ab-0434-43b4-a24c-5eec0f390af5",
        content: "Stay and Place",
        tagType: "h2",
        order: 1,
        images: null,
        style: {
          $id: "7",
          id: "0375a5ab-0434-43b4-a24c-5eec0f390af5",
          fontFamily: "Poppins",
          fontSize: "40px",
          fontWeight: 500,
          fontStyle: 1,
          textDecoration: "",
          foregroundColor: "#000000",
          backgroundColor: "#ffffff",
          textAlignment: 3,
          padding: "0px 0px 0px 0px",
          margin: "0px 0px 0px 0px",
          borderRadius: "0",
          borderThickness: 0,
          borderColor: "#FFFFFF",
          opacity: 1,
          hoverEffect: "",
          customCSS: "",
          isActive: true,
          createdBy: null,
          createdDate: null,
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
        isActive: true,
        createdBy: null,
        createdDate: "2024-10-11T12:56:24.085955+00:00",
        modifiedBy: null,
        modifiedDate: null,
        isDeleted: false,
      },
      {
        $id: "8",
        id: "fa7fa20b-d0b1-47c7-b06a-4e24721464e5",
        componentId: "ac95558b-aef0-40ea-aec2-d9ed543dbb20",
        contextType: 0,
        imagesId: null,
        styleId: "9180940f-4a4c-4387-b46b-0fec96bdba7e",
        content: "Cultural Experience",
        tagType: "h4",
        order: 2,
        images: null,
        style: {
          $id: "9",
          id: "9180940f-4a4c-4387-b46b-0fec96bdba7e",
          fontFamily: "Poppins",
          fontSize: "20px",
          fontWeight: 400,
          fontStyle: 1,
          textDecoration: "",
          foregroundColor: "#885636",
          backgroundColor: "#ffffff",
          textAlignment: 3,
          padding: "0px 0px 0px 0px",
          margin: "0px 0px 0px 0px",
          borderRadius: "0",
          borderThickness: 0,
          borderColor: "#FFFFFF",
          opacity: 1,
          hoverEffect: "",
          customCSS: "",
          isActive: true,
          createdBy: null,
          createdDate: null,
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
        isActive: true,
        createdBy: null,
        createdDate: "2024-10-11T12:56:24.102285+00:00",
        modifiedBy: null,
        modifiedDate: null,
        isDeleted: false,
      },
      {
        $id: "10",
        id: "231bda66-a44b-4472-baf2-cbde5d9e0290",
        componentId: "ac95558b-aef0-40ea-aec2-d9ed543dbb20",
        contextType: 0,
        imagesId: null,
        styleId: "f9682f4d-a490-4fe3-b493-07103da625ec",
        content:
          "California has a rich history of innovation defined by legendary artists and craftsman ",
        tagType: "p",
        order: 3,
        images: null,
        style: {
          $id: "11",
          id: "f9682f4d-a490-4fe3-b493-07103da625ec",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 400,
          fontStyle: 1,
          textDecoration: "",
          foregroundColor: "#7D7D7D",
          backgroundColor: "#ffffff",
          textAlignment: 3,
          padding: "0px 0px 0px 0px",
          margin: "0px 0px 0px 0px",
          borderRadius: "0",
          borderThickness: 0,
          borderColor: "#FFFFFF",
          opacity: 1,
          hoverEffect: "",
          customCSS: "",
          isActive: true,
          createdBy: null,
          createdDate: null,
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
        isActive: true,
        createdBy: null,
        createdDate: "2024-10-11T12:56:24.116136+00:00",
        modifiedBy: null,
        modifiedDate: null,
        isDeleted: false,
      },
    ],
  },
  callToActions: {
    $id: "12",
    $values: [
      {
        $id: "13",
        id: "f2d14680-0862-41b4-9762-845bfad3acd7",
        componentId: "ac95558b-aef0-40ea-aec2-d9ed543dbb20",
        contextType: 0,
        imagesId: null,
        styleId: "8ea6e15f-835b-4d76-96a1-830dd5c21e33",
        text: "Learn More",
        uRL: "https://intelity.com/",
        isExternal: false,
        images: null,
        style: {
          $id: "14",
          id: "8ea6e15f-835b-4d76-96a1-830dd5c21e33",
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: 400,
          fontStyle: 1,
          textDecoration: "",
          foregroundColor: "#885636",
          backgroundColor: "#ffffff",
          textAlignment: 3,
          padding: "6px 20px 6px 20px",
          margin: "0px 0px 0px 0px",
          borderRadius: "20px",
          borderThickness: 0,
          borderColor: "#FFFFFF",
          opacity: 1,
          hoverEffect: "",
          customCSS: "",
          isActive: true,
          createdBy: null,
          createdDate: null,
          modifiedBy: null,
          modifiedDate: null,
          isDeleted: false,
        },
        isActive: true,
        createdBy: null,
        createdDate: "2024-10-11T12:56:24.1314+00:00",
        modifiedBy: null,
        modifiedDate: null,
        isDeleted: false,
      },
    ],
  },
  style: {
    $id: "15",
    id: "895c9565-f0a1-4672-9035-c7f49b48e747",
    fontFamily: "Poppins",
    fontSize: "10px",
    fontWeight: 400,
    fontStyle: 1,
    textDecoration: "",
    foregroundColor: "#885636",
    backgroundColor: "#ffffff",
    textAlignment: 3,
    padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
    borderRadius: "50px",
    borderThickness: 0,
    borderColor: "#FFFFFF",
    opacity: 1,
    hoverEffect: "",
    customCSS: "",
    isActive: true,
    createdBy: null,
    createdDate: null,
    modifiedBy: null,
    modifiedDate: null,
    isDeleted: false,
  },
};

export const mapJSONData = [
  {
    $id: "1",
    $values: [
      {
        $id: "2",
        id: "876d927f-e7b8-4d1f-867e-3801fe2d5fe5",
        componentId: "742f8e2f-7497-4d1d-aa4a-39fa1c5d7c2b",
        image: {
          imageURL:
            "https://dev.gems.intelity.com/images/static-images/Golf/Golf_cardview_map_background.png",
          imageName: "Golf top image",
          altText: "Creakside Studio suite",
          sizeOption: "OrignalSize",
          maximumWidth: 1232,
          maximumHeight: 354,
          cornerRadius: 10,
          order: 1,
          objectFit: "cover",
          style: {
            $id: "3",
            id: "75d10e2c-6cd7-481a-9a31-fe9161327ef4",
            backgroundColor: "#FFFFFF",
            borderColor: "#FFFFFF",
            borderRadius: "40px",
            foregroundColor: "#000000",
            margin: "0px 0px 0px 0px",
            fontSize: "8px",
            borderThickness: 0,
            fontFamily: "Poppins",
            fontWeight: 400,
            fontStyle: 1,
            padding: "0px 0px 0px 0px",
            opacity: 1,
            textAlignment: 1,
            textDecoration: "",
            customCSS: "",
          },
        },
        latitude: 36.1626638,
        longitude: -86.7816016,
        cornerRadius: 10,
        isExternal: false,
        linkURL: null,
        maximumHeight: 354,
        maximumWidth: 1232,
        openInWindow: true,
        sizeOption: 1,
        texts: {
          $id: "4",
          $values: [
            {
              $id: "5",
              id: "2f3b5070-d68b-4b5a-a2c9-d470552297cf",
              content: "Mapping",
              order: 1,
              tagType: "h4",
              style: {
                $id: "6",
                id: "f1efae9b-bbe6-4125-82b2-87057cd5df8c",
                backgroundColor: "#FFFFFF",
                borderColor: "#FFFFFF",
                borderRadius: "0",
                foregroundColor: "#000000",
                margin: "0px 0px 0px 0px",
                fontSize: "20px",
                borderThickness: 0,
                fontFamily: "Arial",
                fontWeight: 400,
                fontStyle: 1,
                padding: "0px 0px 0px 0px",
                opacity: 1,
                textAlignment: 3,
                textDecoration: "",
                customCSS: "",
              },
            },
            {
              $id: "7",
              id: "b0322bd3-2afd-4033-94da-8a133aaa4126",
              content:
                "The course is carved into the naturally dramatic and lush walls of Aliso and Wood Canyons, creating an ultra-private playing experience",
              order: 2,
              tagType: "h5",
              style: {
                $id: "8",
                id: "1fbdb6d3-d537-461e-9b0c-30d3a3e90a03",
                backgroundColor: "#FFFFFF",
                borderColor: "#FFFFFF",
                borderRadius: "0",
                foregroundColor: "#7d7d7d",
                margin: "0px 0px 0px 0px",
                fontSize: "14px",
                borderThickness: 0,
                fontFamily: "Arial",
                fontWeight: 400,
                fontStyle: 1,
                padding: "16px 0px 0px 0px",
                opacity: 1,
                textAlignment: 3,
                textDecoration: "",
                customCSS: "",
              },
            },
          ],
        },
        carouselCTAs: {
          $id: "9",
          $values: [
            {
              $id: "10",
              id: "325308f4-73f4-431b-a693-038543c3369d",
              text: "Know More about Golf",
              isExternal: false,
              uRL: "https://intelity.com/",
              style: {
                $id: "11",
                id: "b0870279-8865-435d-8e08-792fb9a93668",
                backgroundColor: "#FFFFFF",
                borderColor: "#FFFFFF",
                borderRadius: "50px",
                foregroundColor: "#885636",
                margin: "0px 0px 0px 0px",
                fontSize: "10px",
                borderThickness: 0,
                fontFamily: "Poppins",
                fontWeight: 400,
                fontStyle: 1,
                padding: "6px 12px 6px 12px",
                opacity: 1,
                textAlignment: 3,
                textDecoration: "",
                customCSS: "",
              },
            },
            {
              $id: "10",
              id: "325308f4-73f4-431b-a693-038543c3369d",
              text: "Book a Round of Golf",
              isExternal: false,
              uRL: "https://intelity.com/",
              style: {
                $id: "11",
                id: "b0870279-8865-435d-8e08-792fb9a93668",
                backgroundColor: "#FFFFFF",
                borderColor: "#FFFFFF",
                borderRadius: "50px",
                foregroundColor: "#885636",
                margin: "0px 0px 0px 0px",
                fontSize: "10px",
                borderThickness: 0,
                fontFamily: "Poppins",
                fontWeight: 400,
                fontStyle: 1,
                padding: "6px 12px 6px 12px",
                opacity: 1,
                textAlignment: 3,
                textDecoration: "",
                customCSS: "",
              },
            },
            {
              $id: "10",
              id: "325308f4-73f4-431b-a693-038543c3369d",
              text: "Book a Tournament",
              isExternal: false,
              uRL: "https://intelity.com/",
              style: {
                $id: "11",
                id: "b0870279-8865-435d-8e08-792fb9a93668",
                backgroundColor: "#FFFFFF",
                borderColor: "#FFFFFF",
                borderRadius: "50px",
                foregroundColor: "#885636",
                margin: "0px 0px 0px 0px",
                fontSize: "10px",
                borderThickness: 0,
                fontFamily: "Poppins",
                fontWeight: 400,
                fontStyle: 1,
                padding: "6px 12px 6px 12px",
                opacity: 1,
                textAlignment: 3,
                textDecoration: "",
                customCSS: "",
              },
            },
          ],
        },
      },
    ],
  },
];
