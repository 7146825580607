import React, { memo, useMemo } from "react";
import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "Constants";

const MapDisplay = ({ coordinates, mapStyles = {}, zoom = 12 }) => {
  const memoizedCoordinates = useMemo(() => coordinates, [coordinates]);

  const onLoad = (map) => {
    map.setZoom(zoom);
  };
  return (
    <LoadScriptNext googleMapsApiKey={GOOGLE_API_KEY}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        center={coordinates}
        zoom={zoom}
        onLoad={onLoad}
      >
        <Marker position={memoizedCoordinates} />
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default memo(MapDisplay);
