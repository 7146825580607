import React, { useEffect, useState } from "react";
import {
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { BorderAllOutlined } from "@mui/icons-material";
import DOMPurify from "dompurify";
import { iconStyles } from "styles/CommonStyles";
import { navTextStyles } from "../Styles";
import { NAVIGATION_URL } from "Constants";

const NavigationItem = ({
  page,
  selectedPageId,
  itemStyle,
  isOpen,
  onClick,
  customIcon,
  customText,
  accent1,
  accent2,
}) => {
  const [svgContent, setSvgContent] = useState("");
  const [isSvg, setIsSvg] = useState("");
  const isSelected = selectedPageId === page?.id;
  const tooltipTitle = page?.pageName?.length > 16 ? page.pageName : "";
  useEffect(() => {
    const fetchSvgContent = async () => {
      if (page?.iconUrl) {
        try {
          const response = await fetch(page?.iconUrl);
          if (!response.ok) throw new Error("Failed to load SVG");
          const svgData = await response.text();

          // const sanitizedSvg = DOMPurify.sanitize(
          //   svgData.replace(
          //     /fill="[^"]*"/g,
          //     `fill="${isSelected ? accent1 : accent2}"`
          //   )
          // );

          const parser = new DOMParser();
          const svgDoc = parser.parseFromString(svgData, "image/svg+xml");

          const svgElements = svgDoc.querySelectorAll("svg");
          svgElements.forEach((el) => {
            el.setAttribute("fill", isSelected ? accent1 : accent2);
          });

          const modifiedSvg = new XMLSerializer().serializeToString(svgDoc);

          const sanitizedSvg = DOMPurify.sanitize(modifiedSvg);
          setSvgContent(sanitizedSvg);
        } catch (error) {
          console.error("Error fetching the SVG:", error);
        }
      }
    };
    if (page?.iconUrl) {
      const iconName = page.iconUrl.split("/").pop();
      const iconExtension = iconName.split(".").pop().toLowerCase()
      if (iconExtension === "svg") {
        setIsSvg(true)
        fetchSvgContent();

      }
      else {
        setIsSvg(false)
      }
    }

  }, [page?.iconUrl, isSelected, accent1, accent2]);

  return (
    <ListItem
      key={page?.id || "drawer-toggle"}
      disablePadding
      sx={itemStyle}
      onClick={() => onClick(page)}
    >
      <ListItemButton sx={{ minHeight: "50px" }}>
        <Grid container alignItems="center">
          <Grid
            item
            xs={isOpen ? 3 : 12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ListItemIcon
              sx={{
                // color: customIcon ? "#7d7d7d" : iconColor,
                minWidth: 0,
              }}
            >
              {customIcon ? (
                customIcon
              ) : page?.iconUrl ? (
                isSvg && svgContent ? (
                  <div
                    style={{ iconStyles }}
                    dangerouslySetInnerHTML={{ __html: svgContent }}
                  />

                ) : (
                  <i
                    className={page.iconUrl}
                    color={isSelected ? accent1 : accent2}
                    style={{
                      color: isSelected ? accent1 : accent2,
                    }}
                  ></i>
                )
              ) : NAVIGATION_URL[page?.navigationIcon] ? (
                <i
                  className={NAVIGATION_URL[page?.pageName]}
                  style={{color: isSelected ? accent1 : accent2}}
                  color={isSelected ? accent1 : accent2}
                  width={page?.pageName === "Spa" ? "35px" : "24px"}
                ></i>
              ) : (
                <BorderAllOutlined sx={iconStyles} />
              )}
            </ListItemIcon>
          </Grid>

          {isOpen && (
            <Grid item xs={9} pl={1}>
              <Tooltip title={tooltipTitle} arrow>
                <ListItemText
                  primary={(customText || page.pageName)}
                  primaryTypographyProps={{
                    sx: navTextStyles,
                  }}

                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </ListItemButton>
    </ListItem>
  );
};

export default NavigationItem;
