import React, { useState } from "react";
import { Box, Grid } from "@mui/material";

import ChipSet from "components/Tabs/Tabs";
import CardList from "./components/CardList";

import HeaderComponent from "../Components/HeaderSection";

import {
  cardData,
  carouselConfig,
  carouselItems,
  subChipSets,
} from "metaDataJson/Spa";

import { TEXT_CONSTANTS } from "Constants";
import CarouselSlider from "components/CarouselSlider";
import { defaultStyles } from "./DefaultStyles";

const SpaLayout = ({ brandTags }) => {
  const [activeTab] = useState("1");
  const [subActiveTab, setSubActiveTab] = useState("1");

  const handleSubChip = (value) => {
    setSubActiveTab(value);
  };

  return (
    <Box flexGrow={1}>
      <HeaderComponent
        title={TEXT_CONSTANTS.SPA}
        subTitle={TEXT_CONSTANTS.SPA_SUBTITLE}
        styles={brandTags}
        titleVariant={"h2"}
        subTitleVariant={"p"}
        showSearch={false}
      />

      {/* CMB-1032 - [Temp Fix]: Spa Page -Remove Top Level List from UI */}
      {/* <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
        <ChipSet chips={chips} activeTab={activeTab} handleChip={handleChip} />
      </Box> */}

      {carouselItems?.length > 0 && (
        <Box sx={{ position: "relative", overflow: "hidden" }}>
          <CarouselSlider
            carouselConfig={carouselConfig}
            carouselItems={carouselItems}
            carouselStyle={{ ...defaultStyles.carouselStyles }}
            brandTags={brandTags}
            from={TEXT_CONSTANTS.ORGANIZATION_TEXT}
            showBackgroundBlurColor={true}
            boxStyles={defaultStyles?.mainCardTitle}
          />
        </Box>
      )}
      <Grid container mt={3} alignItems={"center"}>
        <Grid item xs={12} mt={1} gap={2} container justifyContent="flex-end">
          <ChipSet
            chips={subChipSets[activeTab]}
            activeTab={subActiveTab}
            handleChip={handleSubChip}
          />
        </Grid>
      </Grid>
      <Grid container mt={3}>
        <CardList cardData={cardData} tagStyle={brandTags} />
      </Grid>
    </Box>
  );
};

export default SpaLayout;
