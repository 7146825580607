import React from "react";
import { Grid } from "@mui/material";
import TextWrapper from "../TextWrapper";
import { defaultBorderThickness } from "styles/CommonStyles";

const defaultTitleStyles = {
  color: "#3e435d",
  fontFamily: "Poppins",
  fontSize: "24px",
  fontWeight: 500,
};
const defaultSubTitleStyles = {
  color: "#ada7a7",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 300,
};

const Header = ({
  title,
  subTitle,

  userNameStyles,
  dateStyles,

  titleVariant,
  subTitleVariant,
}) => {
  return (
    <>
      <Grid item xs={12} md={8} lg={8}>
        <TextWrapper
          title={title}
          styles={{
            ...(userNameStyles || defaultTitleStyles),
            textAlignment: 1,
            ...defaultBorderThickness,
          }}
          sx={userNameStyles}
          variant={titleVariant}
        />

        <TextWrapper
          title={subTitle}
          styles={{
            ...(dateStyles || defaultSubTitleStyles),
            textAlignment: 1,
            ...defaultBorderThickness,
            padding: "10px 0px 16px 0px",
          }}
          sx={dateStyles}
          variant={subTitleVariant}
        />
      </Grid>
    </>
  );
};

export default Header;
