import React from "react";

import { Grid } from "@mui/material";

import ImageCard from "components/Card/ImageCard";

import { defaultBorderThickness } from "styles/CommonStyles";
import { getUpdatedButtonStyles } from "utils";

const ImageCardSection = React.memo(
  ({
    title,
    image,
    category,
    styles,
    color,
    defaultStyles,
    titleVariant = "h2",
    buttonVariant,
    categoryCTALink,
    CTALink,

  }) => {
    const titleStyle = {
      ...styles?.[titleVariant],
      ...defaultStyles.title,
      ...defaultBorderThickness,
    };
    const categoryStyle = {
      ...defaultStyles.categoryBadgeStyles,
      color: color,
    };

    const buttonStyles = getUpdatedButtonStyles(styles, buttonVariant, defaultStyles.buttonStyles)
    const bottomButtonStyles = getUpdatedButtonStyles(styles, "tertiaryButton", defaultStyles.buttonStyles)
    return (
      <Grid item xs={12} md={6} lg={6}>
        <ImageCard
          title={title}
          titleStyle={titleStyle}
          image={image}
          cardStyles={defaultStyles.cardContainer}
          categoryBadgeStyles={{
            ...categoryStyle,
            ...defaultStyles?.categoryBadgeStyles,
          }}
          buttonStyles={buttonStyles}
          bottomButtonStyles={bottomButtonStyles}
          titleVariant={titleVariant}
          category={category}
          categoryCTALink={categoryCTALink}
          CTALink={CTALink}

        />
      </Grid>
    );
  }
);

const ImageCardGridSection = ({
  tagStyle,
  defaultStyles,
  cardData,
}) => (
  <Grid item xs={12} sm={12} md={6} lg={6} container spacing={2}>
    {cardData.map((card) => (
      <ImageCardSection
        key={card?.id}
        image={card.image}
        title={card.title}
        category={card.category}
        styles={tagStyle}
        color={card.color}
        defaultStyles={defaultStyles}
        titleVariant={card.titleVariant}
        buttonVariant={card.buttonType}
        categoryCTALink={card.categoryCTALink}
        CTALink={card.CTALink}
      />
    ))}
  </Grid>
);

export default ImageCardGridSection;
