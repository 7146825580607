import React from "react";
import { Box, Grid, Paper, Stack } from "@mui/material";
import Image from "components/Image";
import DynamicTextRenderer from "components/DynamicTextRenderer";
import MapDisplay from "components/MapDisplay";
import CTAButton from "components/CTAButton";
import { styles } from "styles/MapStyles";

const renderButtons = (ctas = []) => {
  return ctas.map((cta) => <CTAButton key={cta?.id} cta={cta} />);
};

const MapView = ({ data = {} }) => {
  const { map, texts, carouselCTAs, style } = data;
  const coordinates = {
    lat: parseFloat(map?.latitude) || 0,
    lng: parseFloat(map?.longitude) || 0,
  };
  return (
    <Paper sx={styles.paper}>
      <Box sx={styles.imageContainer}>
        <Image
          imageURL={map.backgroundImage}
          style={style}
          extraStyles={{ height: "100%" }}
        />
      </Box>
      <Grid
        sx={styles.overlay}
        container
        alignItems="center"
        height="100%"
        columnSpacing={1}
        px={1}
        overflow="auto"
      >
        <Grid item xs={12} md={8}>
          <Box sx={styles.leftGrid}>
            <MapDisplay
              coordinates={coordinates}
              mapStyles={styles.mapStyles}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box p={1}>
            <DynamicTextRenderer texts={texts?.$values || []} />
          </Box>
          <Stack p={1} spacing={2}>
            {renderButtons(carouselCTAs?.$values || [])}
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MapView;
