import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { isPathPreview } from "utils";

import pagesReducer from "reduxStore/reducers/PagesReducer";

// Custom hook to fetch project pages
export const useProjectPages = (pageSlug) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const isPreview = isPathPreview(location.pathname);

  const findPageData = useCallback(
    (response) => {
      return pageSlug
        ? response.find((data) => {
            const normalizedPageSlug = pageSlug.startsWith("/")
              ? pageSlug
              : `/${pageSlug}`;
            return (
              data.pageSlug === normalizedPageSlug || data.pageSlug === pageSlug
            );
          })
        : response[0];
    },
    [pageSlug]
  );

  const handleSuccess = useCallback(
    ({ response }) => {
      setLoading(false);
      if (response?.length > 0) {
        const foundPageData = findPageData(response);
        setPageData(foundPageData);
      } else {
        setErrorMessage("No Project Pages Found.");
      }
    },
    [findPageData]
  );

  const handleError = useCallback(({ response }) => {
    setErrorMessage(response?.errorMessage + " Failed to fetch project pages.");
    setLoading(false);
  }, []);

  const fetchProjectPages = useCallback(
    (projectId) => {
      setLoading(true);
      dispatch(
        pagesReducer.operations.fetchProjectPagesList({
          projectId,
          isPreview,
          onSuccessResponse: handleSuccess,
          onErrorResponse: handleError,
        })
      );
    },
    [dispatch, isPreview, handleSuccess, handleError]
  );

  return { loading, pageData, errorMessage, fetchProjectPages };
};
