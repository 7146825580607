import { EMPTY_PADDING, PREVIEW_ROUTE_NAME } from "Constants";
import moment from "moment";

// Function to check the external link
const isExternalLink = (url) => {
  return /^https?:\/\//.test(url);
};

// Function to convert Pascal case or any mixed-case to camel case
const toCamelCase = (str) => {
  return str
    .replace(/([-_][a-z])/gi, (match) => {
      return match.toUpperCase().replace("-", "").replace("_", "");
    })
    .replace(/^./, (match) => match.toLowerCase());
};

// Function to recursively convert object keys to camel case
const transformKeysToCamelCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => transformKeysToCamelCase(v));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((result, key) => {
      const newKey = toCamelCase(key);
      result[newKey] = transformKeysToCamelCase(obj[key]);
      return result;
    }, {});
  }
  return obj;
};

const getTodayDate = () => {
  return moment().format("ddd, DD MMMM YYYY");
};

// function to handle cta navigation
const handleCTANavigation = ({
  isExternal,
  url,
  projectId,
  navigate,
  snackbar,
}) => {
  if (!url) {
    // snackbar.showInfo("URL not available.", { autoHideDuration: 3000 });
    return;
  }
  if (isExternal) {
    window.open(url, "_blank");
  } else {
    const formattedSlug = url.startsWith("/") ? url : `/${url}`;
    const isPreview = isPathPreview(window.location.pathname);
    if (isPreview) {
      navigate(`/${PREVIEW_ROUTE_NAME}/${projectId}${formattedSlug}`);
    } else {
      navigate(`/${projectId}${formattedSlug}`);
    }
  }
};

const handleCTA = (isExternal , url , navigate , organizationId) => {
  if(!url) return
  if(isExternal) {
    window.open(url , "_blank")
  }
  else {
    navigate(`/layout${url}/${organizationId}`)
  }
}
// function to sort the items based on the order
const sortDataByOrderId = (data = []) => {
  return [...data].sort((a, b) => {
    const orderA = a && typeof a.order === "number" ? a.order : Infinity;
    const orderB = b && typeof b.order === "number" ? b.order : Infinity;
    return orderA - orderB;
  });
};

// Helper function to extract CallToActions
const extractCallToActions = (actions) => {
  const sortedAction = actions?.$values?.sort((a, b) => a.order - b.order);
  return (
    sortedAction?.map((action) => ({
      text: action?.text || "",
      uRL: action?.uRL || "",
      isExternal: action?.isExternal || false,
      style: action?.style,
    })) || []
  );
};

// Helper function to extract text content and styles

const extractTextContent = (texts) => {
  const sortedTexts = [...texts?.$values]?.sort((a, b) => a.order - b.order);
  const contentArray = sortedTexts?.map((text) => ({
    content: text.content,
    style: text?.style || {},
    tagType: text?.tagType || "p", // Default tagType to "p" if not provided
    contentHTML: text?.contentHTML,
  }));
  return contentArray;
};

const updateFavicon = (iconUrl) => {
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = iconUrl;

  document.getElementsByTagName("head")[0].appendChild(link);
};

const extractData = (data) =>
  data?.flatMap((item) => item["$values"] || []) || [];

const isPathPreview = (path) => {
  return path.startsWith(`/${PREVIEW_ROUTE_NAME}`);
};


const tagTypes = ["H1", "H2", "H3", "H4", "H5", "H6", "p" , "PrimaryButton" , "SecondaryButton", "TertiaryButton", "FourthButton", "Button"];
 const getStylesFromJson = (jsonResponse) => {
  const styles = {};

  tagTypes.forEach((tagType) => {
    const item = jsonResponse?.brandTag.find(
      (item) => item.htmlTag.tagType === tagType
    );
    if (item) {
      styles[tagType] = item.style;
    }
  });
return transformKeysToCamelCase(styles)
  // return styles;
};

const invertColor = (hex)=> {
  hex = hex.replace(/^#/, '');

  let invertedHex = (0xFFFFFF ^ parseInt(hex, 16)).toString(16).padStart(6, '0');
  
  return `#${invertedHex}`;
}
const truncateString = (str, maxLength = 16) => {
  return str?.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
}

const getUpdatedButtonStyles = (styles, buttonType, defaultStyles) => {
  const originalPadding = styles?.[buttonType]?.padding;
  return {
    ...styles?.[buttonType],
    padding:
      originalPadding === EMPTY_PADDING
        ? defaultStyles.padding
        : originalPadding,
  };
}
export {
  isExternalLink,
  transformKeysToCamelCase,
  getTodayDate,
  handleCTANavigation,
  sortDataByOrderId,
  extractCallToActions,
  extractTextContent,
  updateFavicon,
  extractData,
  isPathPreview,
  getStylesFromJson,
  invertColor,
  handleCTA,
  truncateString,
  getUpdatedButtonStyles
  
};
