import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Loader from "components/Loader/Loader";
import DynamicChipSet from "components/Tabs/DynamicTabs";
import HeaderWithTags from "components/HeaderWithTags/HeaderWithTags";
import IntroDetailsCard from "components/IntroDetailsCard/IntroDetailsCard";

import CardList from "./components/CardList";

import { contentType, ComponentTypes } from "Constants";
import { handleCTANavigation } from "utils";
import { useTab2Details } from "hooks/useTabDetails";
import {
  selectComponentDataByType,
  selectComponentsByType,
  selectPageComponents,
} from "reduxStore/selectors/PageSelectors";
import { usePageComponents } from "hooks/usePageComponents";
import { defaultStyles } from "styles/SpaStyles";
import CarouselSlider from "components/CarouselSlider";
import TextComponentRenderer from "components/TextComponentRenderer";

const Spa = ({ pageId }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const {
    loading: section2Loading,
    tabData: tab2Data,
    fetchTabDetailsData: fetchTab2Details,
  } = useTab2Details();

  const [subActiveTab, setSubActiveTab] = useState("");
  const [section2CardType, setSection2CardType] = useState("");

  const { loading, fetchPageComponents } = usePageComponents();
  const { pageHtmlTags } = useSelector(selectPageComponents);

  const carouselComponents = useSelector(
    selectComponentDataByType(pageId, ComponentTypes.Carousel)
  );
  const carouselData = useMemo(() => {
    const [data = {}] = carouselComponents || [];
    return data;
  }, [carouselComponents]);

  const { carouselConfig = {}, carousalImages = {} } = carouselData || {};
  const carouselItems = carousalImages?.["$values"] || [];
  
  const tabComponentData = useSelector(
    selectComponentsByType(pageId, ComponentTypes.TabGroup)
  );
  const sortedTabComponentData = useMemo(
    () =>
      tabComponentData?.tabGroup?.sort(
        (a, b) => a.componentOrder - b.componentOrder
      ),
    [tabComponentData]
  );
  const section2tabComponentsData =
    sortedTabComponentData?.[1] || sortedTabComponentData?.[0] || null;

  useEffect(() => {
    if (pageId) {
      fetchPageComponents(pageId);
      setSubActiveTab("");
    }
  }, [pageId, fetchPageComponents]);

  const subTabsList = useMemo(() => {
    return section2tabComponentsData?.tabs?.["$values"]?.sort(
      (a, b) => a.order - b.order
    );
  }, [section2tabComponentsData]);

  useEffect(() => {
    if (subTabsList?.length > 0 && !subActiveTab) {
      const firstTabId = subTabsList[0]?.id;
      if (firstTabId) {
        setSubActiveTab(firstTabId);
        setSection2CardType(subTabsList[0]?.contentType);
        fetchTab2Details(firstTabId);
      }
    }
  }, [subTabsList, fetchTab2Details, subActiveTab]);

  const handleClick = useCallback(
    (cta) => {
      const { isExternal, uRL: url } = cta;
      handleCTANavigation({ isExternal, url, projectId, navigate });
    },
    [navigate, projectId]
  );

  const handleDispatch = useCallback(
    (tabId) => {
      const cardType = subTabsList?.find((item) => item?.id === tabId);
      setSection2CardType(cardType?.contentType);
      fetchTab2Details(tabId);
    },
    [subTabsList, fetchTab2Details]
  );

  const renderTabs = (
    tabsList,
    tab,
    handleChip,
    style,
    direction,
    contentPosition
  ) => (
    <DynamicChipSet
      chips={tabsList}
      activeTab={tab}
      handleChip={(e) => {
        handleChip(e);
        handleDispatch(e);
      }}
      styles={style}
      direction={direction}
      justifyContent={contentPosition}
    />
  );

  const renderComponent = (cardType, cardData, handleClick) => {
    if (contentType[cardType] === "Image") {
      return cardData?.$values?.map((item) => (
        <IntroDetailsCard data={item} key={item?.$id} />
      ));
    } else if (contentType[cardType] === "Cards") {
      const tabCardViews = cardData?.$values?.sort((a, b) => a.order - b.order);
      return <CardList cardData={tabCardViews} handleClick={handleClick} />;
    } else if(contentType[cardType] === "Text") {
        return <TextComponentRenderer contentArray={cardData?.$values || []} />
    }
    return null;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {pageHtmlTags?.length > 0 && (
            <HeaderWithTags pageHtmlTags={pageHtmlTags} />
          )}

          {carouselItems?.length > 0 && (
            <Box sx={{ position: "relative", overflow: "hidden" }}>
              <CarouselSlider
                carouselConfig={carouselConfig}
                carouselItems={carouselItems}
                carouselStyle={defaultStyles.carouselStyles}
                boxStyles={defaultStyles?.mainCardTitle}

              />
            </Box>
          )}
          
          <Grid container mt={3} alignItems={"center"}>
            <Grid item xs={12} md={4}></Grid>

            {section2tabComponentsData?.style && (
              <Grid
                item
                xs={12}
                md={8}
                gap={2}
                container
                justifyContent="flex-end"
              >
                {subTabsList?.length > 0 &&
                section2tabComponentsData?.isHorizontalDisplay ? (
                  renderTabs(
                    subTabsList,
                    subActiveTab,
                    setSubActiveTab,
                    section2tabComponentsData?.style,
                    "row",
                    ""
                  )
                ) : (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{
                        pr: 3,
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      {renderTabs(
                        subTabsList,
                        subActiveTab,
                        setSubActiveTab,
                        section2tabComponentsData?.style,
                        "column",
                        "flex-start"
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>

          {section2Loading ? (
            <Loader fullPage={false} />
          ) : (
            <Grid container mt={3}>
              {renderComponent(section2CardType, tab2Data, handleClick)}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Spa;
