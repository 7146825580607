import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import { getUpdatedButtonStyles, handleCTANavigation } from "utils";
import useCustomSnackbar from "hooks/useCustomSnackbar";


const CTAButton = ({ cta, extrastyles, from, brandTags }) => {
  const snackbar = useCustomSnackbar();
  const { style, text: name, isExternal, uRL: url, buttonType = "" } = cta;
  const navigate = useNavigate();
  const { projectId, } = useParams();

  const handleClick = () => {
    
      
      handleCTANavigation({
        isExternal,
        url,
        projectId,
        navigate,
        snackbar,
      });
    
  };

  const updatedStyles = buttonType
    ? getUpdatedButtonStyles(brandTags, buttonType, style)
    : style;

  return (
    <Button
      name={name}
      styles={updatedStyles}
      sx={{ ...extrastyles }}
      handleClick={handleClick}
    />
  );
};
export default CTAButton;
