import { Box, Grid, Paper, Stack } from "@mui/material";
import Image from "components/Image";
import TextWrapper from "components/TextWrapper";
import Button from "components/Button";
import { styles } from "styles/MapStyles";
import MapDisplay from "components/MapDisplay";
import { defaultBorderThickness } from "styles/CommonStyles";
import { getUpdatedButtonStyles, handleCTA } from "utils";
import { OVERVIEW_URL , BOOK_A_ROUND_OF_GOLF , GOLF_TOURNAMENTS_URL } from "Constants";

const MapComponent = ({ data, tagStyle }) => {
  const { image, latitude, longitude } = data;
  const coordinates = { lat: latitude || 0, lng: longitude || 0 };
  // Common button styles
  const buttonStyles = getUpdatedButtonStyles(tagStyle, "fourthButton", {padding:"5px"})
  const buttonSx = {
    width: "90%",
    borderRadius: "50px",
    fontSize: "10px",
  };
  const buttonsArray = [
    { name: "Know More about Golf", url: OVERVIEW_URL },
    { name: "Book a Round of Golf", url: BOOK_A_ROUND_OF_GOLF },
    { name: "Book a Tournament", url: GOLF_TOURNAMENTS_URL },
  ];
  return (
    <Paper sx={styles.paper}>
      <Box sx={styles.imageContainer}>
        <Image extraStyles={{ height: "100%" }} {...image} />
      </Box>

      <Grid
        sx={styles.overlay}
        container
        alignItems="center"
        height="100%"
        columnSpacing={1}
        px={1}
        overflow="auto"
      >
        <Grid item xs={12} md={8}>
          <Box sx={styles.leftGrid}>
            <MapDisplay
              coordinates={coordinates}
              mapStyles={styles.mapStyles}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box p={1}>
            {[
              "Mapping",
              "The course is carved into the naturally dramatic and lush walls of Aliso and Wood Canyons, creating an ultra-private playing experience",
            ].map((text, index) => (
              <TextWrapper
                key={text}
                title={text}
                styles={{
                  ...tagStyle?.[index === 0 ? "h5" : "p"],
                  textAlignment: 3,
                  borderColor: "none",
                  ...defaultBorderThickness,
                }}
                sx={{ marginBottom: "10px !important" }}
                variant={index === 0 ? "h5" : "p"}
              />
            ))}
          </Box>
          <Stack p={1} spacing={2}>
            {buttonsArray.map((button) => (
              <Button
                key={button.name}
                name={button.name}
                handleClick={()=>handleCTA(true , button.url)}
                styles={buttonStyles}
                sx={buttonSx}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default MapComponent;
