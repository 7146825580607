import React from "react";

import { Box, CardActions, Grid, styled } from "@mui/material";

import { extractCallToActions, extractTextContent } from "utils";

import { buttonAlignment, generateStyles } from "styles/GenerateStyles";
import ContentBlock from "components/ContentBlock";
import CTAButton from "components/CTAButton";

const TabSection = ({ cardData }) => {
  const renderTextContent = (contentArray) =>
    contentArray?.map((item) => (
      <React.Fragment key={item?.id}>
        <ContentBlock
          content={item?.content}
          styles={item?.style}
          contentHTML={item?.contentHTML}
          tagType={item?.tagType}
        />
      </React.Fragment>
    ));

  const renderCallToActions = (cta) =>
    cta?.map(
      (item) =>
        item?.text && (
          <CardActions
            key={item?.id}
            sx={
              item?.style?.textAlignment
                ? buttonAlignment(item?.style?.textAlignment)
                : {}
            }
          >
            <CTAButton cta={item} extrastyles={{ width: "100%" , boxShadow: "none" }} />
          </CardActions>
        )
    );

  const renderCardContent = (card, index) => {
    const { texts, carouselCTAs } = card;

    const contentArray = extractTextContent(texts);
    const cta = extractCallToActions(carouselCTAs);

    return (
      <Grid
        item
        xs={6}
        md={3}
        lg={3}
        key={card.id}
        sx={{
          p: {xs: 0 , md:0},
          borderLeft: {
            lg: index % 4 !== 0 ? "1px solid #DDE8E9" : "none",
            md: index % 3 !== 0 ? "1px solid #DDE8E9" : "none",
          },
          overflow: "hidden",
        }}
        mt={3}
      >
        <StyledBox
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            textAlign: "center",
          }}
        >
          <Box>{renderTextContent(contentArray)}</Box>
          {renderCallToActions(cta)}
        </StyledBox>
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      {cardData?.map((card, index) => renderCardContent(card, index))}
    </Grid>
  );
};

export default TabSection;
const StyledBox = styled(Box)(({ styles }) => ({
  ...generateStyles({ styles, disableBackground: true }),
}));
