import FitnessImage from "../assets/images/fitness-header-img.png"

export const jsonResponse = {
  statusCode: 0,
  message: "string",
  data: {
    brandTheme: {
      primary: "#007bff",
      secondary: "#6c757d",
      primaryFontProperties: {
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#000",
      },
      secondaryFontProperties: {
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#555",
      },
    },
    organization: {
      name: "Example Organization",
      isActive: true,
    },
  },
};
export const mainChips = [
  { label: "Equinox", value: "1" },
  { label: "Soul Cycle", value: "2" },
];
export const subChipSets = {
  1: [
    { label: "Equinox Club", value: "1" },
    { label: "Group Fitness", value: "2" },
    { label: "Personal Training", value: "3" },
    { label: "Pilates", value: "4" },
  ],
};

export const thirdChipSets = [
  { label: "Tickets", value: "1" },
  { label: "Speakers", value: "2" },
  { label: "Schedule", value: "3" },
];

export const thirdChipContent = {
  1: [
    {
      title: "Tickets for Complete Event",
      tabData: [
        {
          subTitle: "2 Day Pass",
          description: "Lorem ipsum placeholder text.",
        },
        {
          subTitle: "VIP Pass",
          description: "Lorem ipsum placeholder text.",
        },
        {
          subTitle: "General Admission",
          description: "Lorem ipsum placeholder text.",
        },
      ],
    },
  ],
  2: [
    {
      title: "Speakers",
      tabData: [
        { subTitle: "Speaker 1", description: "Details about Speaker 1." },
        { subTitle: "Speaker 2", description: "Details about Speaker 2." },
      ],
    },
  ],
  3: [
    {
      title: "Schedule",
      tabData: [
        {
          subTitle: "Day 1 Schedule",
          description: "Details about Day 1 Schedule.",
        },
        {
          subTitle: "Day 2 Schedule",
          description: "Details about Day 2 Schedule.",
        },
      ],
    },
  ],
};

export const contentMap = {
  1: {
    1: {
      title: "Equinox Club",
      subTitle:
        "The ultimate realization of high-performance living. Designed to inspire uncompromised results.",
      description:
        "At Equinox Hudson Yards, unlocking your potential takes center stage. Our personalized service and signature programming come together in unparalleled fitness experiences, from renowned Personal Training to innovative Group Fitness classes led by the industry’s most beloved talent and rising stars.",
    },
  },
};
export const imageComponentData = {
  "$id": "1",
  "$values": [
      {
          "$id": "2",
          "id": "11a0d9e8-21ab-4f7f-92fd-8c98981d0d06",
          "componentId": "be985752-8b84-40e6-94b3-2d802966beaf",
          "imageURL": FitnessImage,
          "cornerRadius": 10,
          "isExternal": false,
          "linkURL": null,
          "maximumHeight": 354,
          "maximumWidth": 1232,
          "openInWindow": true,
          "sizeOption": 2,
          "style": {
              "$id": "3",
              "id": "3cabf19e-b402-41e1-b2a1-485f1fe0e0ff",
              "backgroundColor": "#FFFFFF",
              "borderColor": "#FFFFFF",
              "borderRadius": "40px",
              "foregroundColor": "#000000",
              "margin": "0px 0px 0px 0px",
              "fontSize": "8px",
              "borderThickness": 0,
              "fontFamily": "Poppins",
              "fontWeight": 400,
              "fontStyle": 1,
              "padding": "0px 0px 0px 0px",
              "opacity": 1,
              "textAlignment": 1,
              "textDecoration": "",
              "customCSS": ""
          },
          "texts": {
              "$id": "4",
              "$values": []
          },
          "carouselCTAs": {
              "$id": "5",
              "$values": []
          }
      }
  ]
}