import React, { useMemo } from "react";
import Image from "../Image";

const Logo = ({
  imagePath,
  styles = {},
  isOpen = false,
  extraStyles = null,
}) => {
  const responsiveStyles = useMemo(() => {
    const width = window.innerWidth;
    return width < 576
      ? { width: "100%", height: "59px" }
      : {
          width: isOpen ? "132.43px" : "59px",
          height: isOpen ? "76.72px" : "59px",
        };
  }, [isOpen]);

  return (
    <Image
      extraStyles={extraStyles ? extraStyles : { ...responsiveStyles }}
      imageURL={imagePath}
      style={styles}
      disableCTANavigation
      objectFit={2}
    />
  );
};

export default Logo;
