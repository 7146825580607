import { styled } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";

export const drawerWidth = 240;
export const smallDrawerWidth = 200;

export const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  [theme.breakpoints.up("md")]: {
    width: drawerWidth,
  },
  [theme.breakpoints.down("md")]: {
    width: smallDrawerWidth,
  },
});

export const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: theme.spacing(7),
  [theme.breakpoints.up("sm")]: {
    width: theme.spacing(9),
  },
});

export const DrawerHeader = styled("div")(({ theme , isOpen }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: isOpen ? theme.spacing(1, 3.1) : theme.spacing(0.875 , 0.875),
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const navTextStyles = {
  fontFamily: "Poppins !important",
  whiteSpace: "nowrap",
  textAlign: "left",
  color: "inherit",
  fontSize: "16px",
  maxWidth: "200px",
  overflow:"hidden",
  textOverflow:"ellipsis",
};
