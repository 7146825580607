export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL;

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const GOOGLE_FONTS_API = process.env.REACT_APP_GOOGLE_FONTS_API;

export const NAVIGATION_ITEM_ACTIVE_COLOR =
  "linear-gradient(269.84deg, #F66B0E -38.97%, rgba(246, 107, 14, 0) 90.03%)";

export const PREVIEW_ROUTE_NAME = "preview";
export const FontWeight = {
  DEFAULT: 0,
  NORMAL: 400,
  BOLD: 700,
  LIGHTER: -1,
  BOLDER: -2,
  HUNDRED: 100,
  TWO_HUNDRED: 200,
  THREE_HUNDRED: 300,
  FOUR_HUNDRED: 400,
  FIVE_HUNDRED: 500,
  SIX_HUNDRED: 600,
  SEVEN_HUNDRED: 700,
  EIGHT_HUNDRED: 800,
  NINE_HUNDRED: 900,
};

export const FontStyle = {
  NONE: 0,
  NORMAL: 1,
  ITALIC: 2,
  OBLIQUE: 3,
};

export const TextAlignment = {
  LEFT: 1,
  RIGHT: 2,
  CENTER: 3,
  JUSTIFY: 4,
};

export const textAlignMap = {
  1: "left",
  2: "right",
  3: "center",
  4: "justify",
};
export const EMPTY_PADDING = "0px 0px 0px 0px";
export const fontWeightMap = {
  [FontWeight.DEFAULT]: "normal",
  [FontWeight.NORMAL]: "normal",
  [FontWeight.BOLD]: "bold",
  [FontWeight.HUNDRED]: 100,
  [FontWeight.TWO_HUNDRED]: 200,
  [FontWeight.THREE_HUNDRED]: 300,
  [FontWeight.FOUR_HUNDRED]: 400,
  [FontWeight.FIVE_HUNDRED]: 500,
  [FontWeight.SIX_HUNDRED]: 600,
  [FontWeight.SEVEN_HUNDRED]: 700,
  [FontWeight.EIGHT_HUNDRED]: 800,
  [FontWeight.NINE_HUNDRED]: 900,
};

export const fontStyleMap = {
  [FontStyle.NONE]: "none",
  [FontStyle.NORMAL]: "normal",
  [FontStyle.ITALIC]: "italic",
  [FontStyle.OBLIQUE]: "oblique",
};

export const LayoutNames = {
  Landing: "Land1",
  Home: "Home1",
  Fitness: "Fit01",
  Map: "Map01",
  Offer: "Offer",
  Spa: "Spa01",
  Fitness2: "Fit02",
};

export const htmlTags = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",

  p: "body1",
};

export const imagePosition = {
  0: "left",
  1: "right",
  2: "top",
  3: "fullImage",
};

export const ComponentTypes = {
  Carousel: "Carousel",
  Image: "Image",
  CardGroup: "CardGroup",
  Card: "Card",
  TabGroup: "TabGroup",
};

export const buttonPosition = {
  1: "start",
  2: "end",
  3: "center",
  4: "justify",
};
export const PageStates = {
  0: "Draft",
  1: "Published",
  2: "Scheduled_Publishing",
  3: "Page_Expiration",
  4: "InActive",
};
export const contentType = {
  0: "None",
  1: "TabGroup",
  2: "Image",
  3: "Carousel",
  4: "Cards",
  5: "Text",
  6: "List",
  7: "Combination",
};

export const borderStyleMap = {
  0: "none",
  1: "solid",
  2: "dotted",
  3: "dashed",
  4: "double"
};
export const textTransformMap = {
  0: "none",
  1: "capitalize",
  2: "uppercase",
  3: "lowercase"
};
export const NAVIGATION_URL = {
  Home: "fa-solid fa-house",
  Spa: "fa-solid fa-spa",
  Fitness: "fa-solid fa-dumbbell",
  Golf: "fa-solid fa-location-dot",
  Offers: "fa-solid fa-grid-2",
  Menu: "fa-solid fa-grid-2",
};

export const TEXT_CONSTANTS = {
  ORGANIZATION_TEXT: "organization",
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
  KNOW_MORE: "Know More",
  SECONDARY_BUTTON: "secondaryButton",
  PRIMARY_BUTTON: "primaryButton",
  LEARN_MORE: "Learn More",
  SPECIAL_OFFER: "Special Offers",
  GOLF: "Golf",
  WELCOME_THE_RANCH: "Welcome The Ranch",
  AT_LAGUNA_BEACH: "At Laguna Beach",
  PACKAGES: "Packages",
  SPA: "Spa",
  SPA_WELCOME_TEXT: "Welcome to Sycamore Spa by Hudson",
  SPA_HOURS:
    "Spa Hours:​ Monday-Friday: 10:00am - 6:00pm Saturday & Sunday: 9:00am - 6:00pm",

  GOLF_WELCOME_TEXT: "Welcome to Fairways Golf",
  GOLF_HOURS:
    "Golf Hours:​ Monday-Friday: 10:00am - 6:00pm Saturday & Sunday: 9:00am - 6:00pm",
  GOLF_HOURS_CONTENT_HTML: `<span>Golf Hours:&ZeroWidthSpace; Monday-Friday:&nbsp;<span style="color:#5B7961"><b>10:00am - 6:00pm</b></span> Saturday &amp; Sunday:&nbsp;<span style="color:#5B7961"><b>9:00am - 6:00pm</b></span></span>`,

  SPA_SUBTITLE: "Relax, rejuvenate, and unwind in pure bliss",
  FITNESS_TITLE: "Fitness",
  FITNESS_SUBTITLE: "Stay active and feel great every day",
  GOLF_SUBTITLE: "Master your swing and enjoy the game of golf",
  OFFERS: "Offers",
  OFFERS_SUBTITLE: "Offers subtitle",
  BOOK_NOW:"Book Now",
  MENU:"Menu",
  MENU_SUBTITLE:"Where flavorful dining meets the ultimate relaxation",

};

export const ImageObjectFit = {
  0: "none",
  1: "fill",
  2: "contain",
  3: "cover",
  4: "scale-down",
};

export const PagesList = [
  { pageName: "Home", id: "1", url: "Home1", navigationIcon: "Home" },
  { pageName: "Spa", id: "2", url: "Spa01", navigationIcon: "Spa" },
  { pageName: "Fitness", id: "3", url: "Fit01", navigationIcon: "Fitness" },
  { pageName: "Golf", id: "4", url: "Map01", navigationIcon: "Golf" },
  { pageName: "Menu", id: "5", url: "Offer", navigationIcon: "Offers" },
  {
    pageName: "Land",
    id: "6",
    url: "Land1",
    layoutCode: "Land1",
    navigationIcon: "Land",
  },
];

export const HITFITNESS_URL="https://www.theranchlb.com/play/experiences#!/e/hiit-fitness-07bc8c7e"
export const SERVICE_MENU_URL="https://go.booker.com/location/SycamoreSpaHudson/service-menu"
export const SELECT_A_ROOM_URL="https://be.synxis.com/?_gl=1*hifq5n*_gcl_aw*R0NMLjE3MzA4MTUxMjcuQ2p3S0NBandfNFMzQmhBQUVpd0FfNjRZaG81d0p5UXEzRk1jSUpjUWc1Q0d6TnlxbHo3TWZGcFFhbVhSU1I1OW9HcW5BdjdkZFI3QkFCb0MyLTBRQXZEX0J3RQ..*_gcl_dc*R0NMLjE3MzA4MTUxMjcuQ2p3S0NBandfNFMzQmhBQUVpd0FfNjRZaG81d0p5UXEzRk1jSUpjUWc1Q0d6TnlxbHo3TWZGcFFhbVhSU1I1OW9HcW5BdjdkZFI3QkFCb0MyLTBRQXZEX0J3RQ..*_gcl_au*MTA2NDk5NDAxLjE3MzA4MTQxODA.*_ga*MTA0OTcwNjIwMS4xNzMwODE0MTgx*_ga_B4819V18WP*MTczMDkwNDUwNi40LjEuMTczMDkwNTQwOS4xOC4wLjA.&adult=1&arrive=2024-11-06&chain=10237&child=0&currency=USD&depart=2024-11-07&hotel=61912&level=hotel&locale=en-US&nck=8552818809&productcurrency=USD&promo=PANCAKES&rooms=1"
export const GOLF_TOURNAMENTS_URL="https://www.theranchlb.com/golf/golf-tournaments"
export const OVERVIEW_URL="https://www.theranchlb.com/golf/overview"
export const LANDING_URL="https://be.synxis.com/?_gl=1*1amo8zo*_gcl_aw*R0NMLjE3MzA4MTUxMjcuQ2p3S0NBandfNFMzQmhBQUVpd0FfNjRZaG81d0p5UXEzRk1jSUpjUWc1Q0d6TnlxbHo3TWZGcFFhbVhSU1I1OW9HcW5BdjdkZFI3QkFCb0MyLTBRQXZEX0J3RQ..*_gcl_dc*R0NMLjE3MzA4MTUxMjcuQ2p3S0NBandfNFMzQmhBQUVpd0FfNjRZaG81d0p5UXEzRk1jSUpjUWc1Q0d6TnlxbHo3TWZGcFFhbVhSU1I1OW9HcW5BdjdkZFI3QkFCb0MyLTBRQXZEX0J3RQ..*_gcl_au*MTA2NDk5NDAxLjE3MzA4MTQxODA.*_ga*MTA0OTcwNjIwMS4xNzMwODE0MTgx*_ga_B4819V18WP*MTczMDkwNDUwNi40LjEuMTczMDkwNjQ5NC41OC4wLjA.&adult=1&arrive=2024-11-06&chain=10237&child=0&currency=USD&depart=2024-11-07&hotel=61912&level=hotel&locale=en-US&nck=8444118242&productcurrency=USD&promo=MKTGMC&rooms=1"
export const BOOK_A_ROUND_OF_GOLF="https://www.chronogolf.com/club/19319/widget?medium=widget&source=club#?course_id=26694&nb_holes=9"

