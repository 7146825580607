import { Box, styled } from "@mui/material";

import TextWrapper from "components/TextWrapper";
import Button from "components/Button";
import ContentBlock from "components/ContentBlock";

import { OVERVIEW_URL, TEXT_CONSTANTS } from "Constants";

import MapImage from "assets/images/headerImage.png";

import { defaultBorderThickness } from "styles/CommonStyles";
import { getUpdatedButtonStyles, handleCTA } from "utils";

const BannerSection = ({ tagStyle }) => {
  const buttonStyles = getUpdatedButtonStyles(tagStyle, "secondaryButton",  {padding:"6px 12px 6px 12px"})
  return (
    <StyledCard>
      <DetailsCard>
        <TextWrapper
          title={TEXT_CONSTANTS.GOLF_WELCOME_TEXT}
          styles={{
            backgroundColor: "transparent",

            textAlignment: 3,
            margin: "10px",
            ...tagStyle?.["h1"],
            ...defaultBorderThickness,
          }}
          variant={"h1"}
        />

        <ContentBlock
          title={
            TEXT_CONSTANTS.GOLF_HOURS
          }
          contentHTML={TEXT_CONSTANTS.GOLF_HOURS_CONTENT_HTML}
          styles={{
            backgroundColor: "transparent",
            textAlignment: 3,
            margin: "10px",
            ...tagStyle?.["p"],
            padding: "8px 0px 8px 0px",
            ...defaultBorderThickness,
          }}
          variant={"p"}
        />
        <Button
          handleClick={() => {
           handleCTA(true , OVERVIEW_URL)
          }}
          styles={buttonStyles}
          name={TEXT_CONSTANTS.LEARN_MORE}
        />
      </DetailsCard>
    </StyledCard>
  );
};
export default BannerSection;

const StyledCard = styled(Box)({
  height: "320px",
  width: "100%",
  position: "relative",
  overflow: "hidden",
  backgroundImage: `url(${MapImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  borderRadius: "40px",
});

const DetailsCard = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  background: "rgba(255, 255, 255, 0.8)",
  borderRadius: "20px",
  padding: theme.spacing(4, 5),
  width: "80%",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
}));
