export const defaultStyles = {
  title: { fontFamily: "Poppins" },
  subTitle: {
    fontFamily: "Poppins",
  },
  description: {
    fontFamily: "Poppins",
  },
  media: { height: "auto", borderRadius: "30px" },
  cardContainer: {
    borderColor: "#D9D9D9",
    borderRadius: "30px",
    height: "100%",
    minHeight: "100%",
  },
  categoryBadgeStyles: {
    position: "absolute",
    top: 14,
    right: 14,
    cursor: "pointer",
  },
  gridContainer: { height: "430px" },
  carouselStyles: {
    maxHeight: "500px",
    minHeight: "500px",
  },

  cardActionsStyles: {
    position: "absolute",
    bottom: "2%",
    width: "90%",
    transform: "translateX(6%)",
    left: 0,
    right: 0,
    borderRadius: "40px",
    padding: "16px 0 !important",
    cursor: "pointer",
    backgroundColor: "#ffffffb2",
    textAlign: "center",
    backdropFilter: "blur(10px) !important",
  },
  defaultCardStyles: {
    flex: 1,
    position: "relative",
    overflow: "hidden",
  },
  containerStyles: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
};
