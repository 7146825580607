import { configureStore } from "@reduxjs/toolkit";

import { pagesSlice } from "./reducers/PagesReducer";
import { pageComponentsSlice } from "./reducers/PageComponents";
import { pageComponentDataSlice } from "./reducers/PageComponentData";
import { organizationComponentsSlice } from "./reducers/Organization";
import { tabDetailsData } from "./reducers/TabComponent";


export const store = configureStore({
  reducer: {
    pages: pagesSlice.reducer,
    pageComponents: pageComponentsSlice.reducer,
    pageComponentData: pageComponentDataSlice.reducer,
    organizationComponentData: organizationComponentsSlice.reducer,
    tabComponentDataSlice: tabDetailsData.reducer,
    
  },
});
