import { Box, styled } from "@mui/material";

const logoStyles = {
  width: "100%",
  maxWidth: "100%",
  height: "50px",
  objectFit: "contain",
  backgroundColor: "transparent"
};

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  minHeight: "100vh",
  background: theme.palette.primary.main,
}));

const SidebarContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
}));

const ContentArea = styled(Box)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(2.5),
  flexGrow: 1,
  backgroundColor: theme.palette.primary.main,
}));

const iconStyles = {
  width: "24px",
  height: "24px",
};
const multilineEllipsis = (lines) => ({
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: lines,
  textOverflow: "ellipsis",
});
const defaultSubTitleStyles = {
  color: "#000",
  fontSize: "1rem",
  fontWeight: "500",
  fontFamily: "Poppins",
};

const textDefaultAddtionalStyles = {
  textAlignment: 1,
  borderThickness: 0,
};
const defaultBorderThickness = {
  borderThickness: 0,
};
const RadialGradientOverlay = styled(Box)({
  position: "absolute",
  inset: "0",
  background:
    "radial-gradient(46.67% 46.67% at 50% 53.33%, rgba(24, 25, 29, 0) 0%, rgba(24, 25, 29, 0.2) 100%)",
});
export {
  logoStyles,
  Container,
  SidebarContainer,
  ContentArea,
  iconStyles,
  multilineEllipsis,
  defaultSubTitleStyles,
  textDefaultAddtionalStyles,
  defaultBorderThickness,
  RadialGradientOverlay,
};
