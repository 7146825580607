export const defaultStyles = {
  cardContainer: {
    borderRadius: "50px",
    backgroundColor: "#FFF",
    padding: "0px",
  },
  buttonStyles: {
    fontWeight: 400,

    foregroundColor: "#5B7961",
    minWidth: "64px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily: "Poppins",

    backgroundColor: "#FFFFFFB2",
    opancity: 1,
    padding: "6px 18px 6px 18px",
    borderRadius: "19px",
    fontSize: "15px",
    position: "relative",
  },
  media: { borderRadius: "30px", padding: "0px" },
  mainCardTitle: {
    // position: "absolute",
    // top: "30%",
    // left: "50%",
    // transform: "translate(-50%, -10%)",
    // textAlign: "center",
    // bgcolor: "rgba(255, 255, 255, 0.8)",
    backgroundColor: "rgba(255, 255, 255, 0.8) !important",
    borderRadius: "10px",
    backdropFilter:"blur(2px)",

    // padding: "8px",
    // width: "81%",
  },
  carouselStyles: {
    maxHeight: "500px",
    minHeight: "500px",
  },
  
};
