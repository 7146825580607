import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import NoDataContainer from "components/NoDataContainer/NoDataContainer";
import Loader from "components/Loader/Loader";
import Dashboard from "./Dashboard/Dashboard";
import FitnessLayout from "./FitnessLayout/FitnessLayout";
import SpaLayout from "./Spa/Spa";
import { pagesSlice } from "reduxStore/reducers/PagesReducer";

import { getStylesFromJson } from "utils";
import MapLayout from "./Map/Map";

import MenuLayout from "./Menu";
import { useOrganizationBranding } from "../../hooks/useOrganizationBranding";
import {
  Container,
  ContentArea,
  SidebarContainer,
} from "../../styles/CommonStyles";
import Landing from "./Landing/Landing";
import Sidebar from "pages/WithSidebar/Sidebar";
import { PagesList, TEXT_CONSTANTS } from "Constants";
import MobileHeader from "pages/WithSidebar/Sidebar/MobileHeader";

// Component Mapping
const layoutComponents = {
  Home1: Dashboard,
  Fit01: FitnessLayout,
  Spa01: SpaLayout,
  Map01: MapLayout,
  Offer: MenuLayout,
  Land1: Landing,
};

const ProjectPageNavigator = () => {
  const { pageSlug, organizationId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentLayout, setCurrentLayout] = useState(null);
  const { branding, loading, error } = useOrganizationBranding(organizationId);
  
  const muiTheme = useMemo(() => {
    const {
      primary = "#fff",
      secondary = "#fff",
      tertiary = "#fff",
      accent1 = "#fff",
      accent2 = "#fff",
      primaryFontProperties,
      primaryFontWeight,

    } = branding?.brandTheme || {};

    
    return createTheme({
      palette: {
        primary: { main: primary },
        secondary: { main: secondary },
        tertiary: tertiary,
        accent1: accent1,
        accent2: accent2,
        primaryFontStyles:{
          fontFamily: primaryFontProperties,
          fontWeight: primaryFontWeight,

        }
      },

      logo: branding?.logoURL,
      appName: branding?.name || "",
    });
  }, [branding]);

  useEffect(() => {
    if (organizationId && !loading && branding) {
      dispatch(pagesSlice.actions.addProjectPagesList(PagesList));
      const page = PagesList.find((item) => item.url === pageSlug);
      const styles = getStylesFromJson(branding.brandTheme);
      if (page) {
        const LayoutComponent = layoutComponents[pageSlug];
        setCurrentLayout(<LayoutComponent brandTags={styles} />);
      } else {
        navigate(`/layout/Home1/${organizationId}`);
      }
    }
  }, [organizationId, pageSlug, loading, dispatch, branding, navigate]);

  const onClickPage = useCallback(
    (page) => {
      navigate(`/layout/${page.url}/${organizationId}`);
    },
    [navigate, organizationId]
  );

  const memoizedSidebar = useMemo(
    () => (
    
      <Box sx={{display: {xs: "none", md: "flex"}}}>
        <Sidebar 
        pages={PagesList}
        onClick={onClickPage}
        selectedPageId={PagesList.find((page) => page.url === pageSlug)?.id}
        type={TEXT_CONSTANTS.ORGANIZATION_TEXT}
      />
    </Box>
    ),
    [onClickPage, pageSlug]
  );

  if (loading) return <Loader />;

  if (error) return <NoDataContainer title={error} />;
  const pageId = PagesList.find((page) => page.url === pageSlug)?.id;
  return (
    <ThemeProvider theme={muiTheme}>
      {pageSlug === "Land1" ? (
        currentLayout || <NoDataContainer />
      ) : (
        <Container>
        <Box sx={{display: {xs: "flex", md: "none"}}}>
        <MobileHeader pageId={pageId} onClick={onClickPage} type={TEXT_CONSTANTS.ORGANIZATION_TEXT}/>
        </Box>
          <SidebarContainer >{memoizedSidebar}</SidebarContainer>
          <ContentArea sx={{mt: {xs: 6, md: 0}}}>{currentLayout || <NoDataContainer />}</ContentArea>
        </Container>
      )}
    </ThemeProvider>
  );
};

export default ProjectPageNavigator;
