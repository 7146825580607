import React from "react";
import { Box, CardActions, Grid, styled } from "@mui/material";
import Image from "components/Image";
import CTAButton from "components/CTAButton";
import { buttonAlignment, generateStyles } from "styles/GenerateStyles";
import { extractCallToActions, extractTextContent } from "utils";
import TextComponentRenderer from "components/TextComponentRenderer";

const TabSection = ({ tabData, contentType }) => {
  const renderCallToActions = (ctaArray) =>
    ctaArray?.map(
      (item) =>
        item?.text && (
          <CardActions
            key={item?.id}
            sx={
              item?.style?.textAlignment
                ? buttonAlignment(item?.style?.textAlignment)
                : {}
            }
          >
            <CTAButton cta={item} />
          </CardActions>
        )
    );

  const renderCardContent = (card, index) => {
    const { texts, image, carouselCTAs, style, imageURL, linkURL, isExternal } =
      card;
      const contentArray = extractTextContent(contentType === 5 ? {"$values": [card]} : texts);
    const cta = extractCallToActions(carouselCTAs);
    const modifiedImageObj = {
      ...image,
      style: {
        ...image?.style,
        width: "100% !important",
      },
    };
    if (contentType === 4) {
      return (
        <Grid
          item
          xs={12}
          md={4}
          lg={image?.imageURL ? 3 : 4}
          key={card.id}
          sx={{
            p: 3,
            borderLeft: {
              lg:
                index % (image?.imageURL ? 4 : 3) !== 0
                  ? "1px solid #000"
                  : "none",
              md: index % 3 !== 0 ? "1px solid #000" : "none",
            },
            overflow: "hidden",
          }}
          mt={3}
        >
          <StyledBox
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              textAlign:"center",
              ...style
            }}
          >
            <Box>
              {image?.imageURL && (
                <Image
                  extraStyles={{ height: "157px" }}
                  {...modifiedImageObj}
                />
              )}

              <TextComponentRenderer contentArray={contentArray}/>
            </Box>
            {cta?.length > 0 && renderCallToActions(cta)}
          </StyledBox>
        </Grid>
      );
    } else {
      return (
        <React.Fragment key={card.id}>
          <Grid item xs={12} md={imageURL? 8 : 12}  sx={{ padding:'0 0 0 24px !important' }}>
            <StyledBox>
              <TextComponentRenderer contentArray={contentArray} />
              {cta?.length > 0 && renderCallToActions(cta)}
            </StyledBox>
          </Grid>
          {imageURL && (
            <Grid item xs={12} md={4} sx={{ textAlign: "center" , paddingTop:'0px !important' }} mb={4}>
                <Image
                  {...{ imageURL, style, linkURL, isExternal }}
                  extraStyles={{ height: "200px" }}
                />
            </Grid>
           )}
        </React.Fragment>
      );
    }
  };

  return (
    <Grid container spacing={3} pt={4}>
      {tabData?.map((card, index) => renderCardContent(card, index))}
    </Grid>
  );
};

export default TabSection;
const StyledBox = styled(Box)(({ styles }) => ({
  ...generateStyles({ styles, disableBackground: true }),
}));
