import React from "react";
import Chip from "../Chip/Chip";
import { useTheme } from "@mui/material";


const ChipSet = ({ chips, activeTab, handleChip }) => {
  const theme = useTheme();
  const { palette } = theme;
  const { tertiary,   accent1 , accent2 , primaryFontStyles  } = palette;

  return chips?.map((item) => (
    <Chip
      key={item.value}
      label={item.label}
      onClick={() => handleChip(item.value)}
      variant={activeTab === item.value ? "filled" : "outlined"}
      styles={{
        backgroundColor:
          activeTab === item.value ? `${tertiary} !important` : "",

        padding: "1px 2px 1px 2px !important",
        marging: "1px 1px 1px 1px",

        borderRadius: "30px",

        fontFamily: primaryFontStyles?.fontFamily || "Poppins",
        fontSize: "14px",
        fontWeight: primaryFontStyles?.fontWeight || 400,
      }}
      sx={{
        backgroundColor: activeTab === item.value ? tertiary : "",

        borderRadius: "30px",

        fontWeight: primaryFontStyles?.fontWeight || 400,

        color: activeTab === item.value ? accent1 : accent2,
        "&:hover": {
          backgroundColor: `${tertiary} !important`,
          fontFamily: primaryFontStyles?.fontFamily || "Poppins",
          fontSize: "14px",
          fontWeight: primaryFontStyles?.fontWeight || 400,
          color: accent1,
        },
      }}
    />
  ));
};

export default ChipSet;
