import React from "react";
import Card from "../Card/Card";
import { overFlowStyles } from "styles/OverFlowStyles";
import { defaultBorderThickness } from "styles/CommonStyles";
import { getUpdatedButtonStyles } from "utils";

const getCardContentStyles = (justification) => ({
  textAlignment: justification === "end" ? 2 : 0,
  justifyContent: justification === "end" ? "end" : "start",
  padding: "10px",
});

const CardSection = React.memo(
  ({
    title,
    subTitle,
    description,
    image,
    imagePosition,
    styles,
    defaultStyles,
    titleVariant = "h2",
    subTitleVariant = "h4",
    descriptionVariant = "p",
    buttonPosition = "bottom",
    cardStyles,
    CTALink,
    isExternal,
    buttonType

  }) => {
    const titleStyle = {
      ...styles?.[titleVariant],
      ...getCardContentStyles(imagePosition === "left" ? "end" : "start"),
      padding: "0px",
      margin: "12px 0px !important",
      ...defaultBorderThickness,
    };
    const subTitleStyle = {
      ...styles?.[subTitleVariant],
      ...getCardContentStyles(imagePosition === "left" ? "end" : "start"),
      ...defaultBorderThickness,
    };
    const descriptionStyle = {
      ...styles?.[descriptionVariant],
      ...getCardContentStyles(imagePosition === "left" ? "end" : "start"),
      ...defaultBorderThickness,
    };

    const buttonStyles = getUpdatedButtonStyles(styles, buttonType, defaultStyles.buttonStyles);
    return (
      <Card
        title={title}
        subTitle={subTitle}
        description={description}
        titleStyle={titleStyle}
        subTitleStyle={subTitleStyle}
        descriptionStyle={{ ...descriptionStyle, ...overFlowStyles }}
        image={image}
        mediaStyle={defaultStyles.media}
        cardStyles={defaultStyles.cardContainer}
        cardContentStyles={getCardContentStyles(
          imagePosition === "left" ? "end" : "start"
        )}
        imagePosition={imagePosition}
        titleVariant={titleVariant}
        subTitleVariant={subTitleVariant}
        descriptionVariant={descriptionVariant}
        buttonPosition={buttonPosition}
        CTALink={CTALink}
        isExternal={isExternal}
        buttonStyles={buttonStyles}
        
      />
    );
  }
);
export default CardSection;
