import React from "react";
import HtmlContentWrapper from "components/HtmlContentWrapper";
import TextWrapper from "components/TextWrapper";

const ContentBlock = ({ contentHTML, content, lines, styles, tagType }) => {
  return contentHTML ? (
    <HtmlContentWrapper contentHTML={contentHTML} styles={styles} tagType={tagType} />
  ) : (
    <TextWrapper
      lines={lines}
      title={content}
      styles={styles}
      variant={tagType}
    />
  );
};

export default ContentBlock;
