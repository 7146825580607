import React, { memo, useMemo } from "react";
import { Box } from "@mui/material";
import { sortDataByOrderId } from "utils";
import ContentBlock from "components/ContentBlock";

const DynamicTextRenderer = memo(({ texts = [] }) => {
  const orderedTexts = useMemo(() => sortDataByOrderId(texts), [texts]);
  return (
    <Box>
      {orderedTexts.map(
        ({ id, tagType, content = "", contentHTML = "", style = {} }) => {
          return (
            <ContentBlock
              key={id}
              content={content}
              contentHTML={contentHTML ? contentHTML : content}
              styles={style}
              tagType={tagType}
            />
          );
        }
      )}
    </Box>
  );
});

export default DynamicTextRenderer;
