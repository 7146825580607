import React from "react";
import PropTypes from "prop-types";
import CardMedia from "@mui/material/CardMedia";
import { Box, Card, CardActions, styled } from "@mui/material";
import TextWrapper from "../TextWrapper";
import { generateStyles } from "styles/GenerateStyles";
import { defaultBorderThickness } from "styles/CommonStyles";
import { handleCTA } from "utils";
import { useNavigate , useParams } from "react-router-dom";
import Button from "components/Button";


const ImageCard = ({
  image,
  altText = "Image",
  title = "Title",
  category = "Category",
  onLearnMoreClick,
  height = "100%",
  width = "100%",
  titleStyle,
  categoryBadgeStyles,
  titleVariant,
  defaultStyles,
  categoryCTALink,
  CTALink,
  buttonStyles,
  bottomButtonStyles

}) => {

  const navigate = useNavigate()

  const {organizationId} = useParams()
  const handleLearnMoreClick = () => {
    if(CTALink) handleCTA(false , CTALink , navigate , organizationId)
  };

  const containerStyles = {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    width,
    height,
    backgroundColor: "transparent",
  };

  const cardStyles = {
    flex: 1,
    borderRadius: "50px",
    position: "relative",
    overflow: "hidden",
  };

  const cardActionsStyles = {
    position: "absolute",
    bottom: "2%",
    width: "90%",
    transform: "translateX(6%)",
    left: 0,
    right: 0,
    cursor: "pointer",
    backdropFilter: "blur(10px) !important",
    borderRadius: "40px",
    padding: "16px 0",
    background: "#ffffffb2",
    textAlign: "center",
  };

  return (
    <Box sx={{ ...containerStyles, width, height }}>
      <StyledCard
        sx={{ ...defaultStyles?.defaultCardStyles }}
        style={cardStyles}
      >
        <CardMedia
          component="img"
          image={image}
          alt={altText}
          sx={{ objectFit: "cover", height: "100%", width: "100%" }}
        />

        {category && <Box sx={categoryBadgeStyles}><Button name={category} handleClick={()=> handleCTA(true , categoryCTALink)} styles={buttonStyles} /></Box>}

        <CardActions sx={cardActionsStyles}>
          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextWrapper
              styles={{
                ...titleStyle,
                TextAlignment: 3,
                ...defaultBorderThickness,
              }}
              title={title}
              variant={titleVariant}
            />
            <Button
              styles={bottomButtonStyles || {
                foregroundColor: "#885636",
                cursor: "pointer",
                marginTop: "4px",
                textAlign: "center",
                backgroundColor:"transparent",
                boxShadow:"none !important"
              }}
              
              sx={{boxShadow:"none !important"}}

              handleClick={handleLearnMoreClick}
              name={"Learn More"}
            />
          </Box>
        </CardActions>
      </StyledCard>
    </Box>
  );
};

ImageCard.propTypes = {
  image: PropTypes.string.isRequired,
  altText: PropTypes.string,
  title: PropTypes.string,
  category: PropTypes.string,
  onLearnMoreClick: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default React.memo(ImageCard);
const StyledCard = styled(Card)(({ theme, styles }) => ({
  ...generateStyles({ styles, disableBackground: false }),
  [theme.breakpoints.down("md")]: {
    height: "500px",
  },
}));
