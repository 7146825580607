import React, { useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "components/Loader/Loader";
import HeaderWithTags from "components/HeaderWithTags/HeaderWithTags";
import IntroDetailsCard from "components/IntroDetailsCard/IntroDetailsCard";
import Card from "components/Card/DynamicCard";
import MapView from "./components/MapView";
import { usePageComponents } from "hooks/usePageComponents";
import {
  selectComponentDataByType,
  selectPageComponents,
} from "reduxStore/selectors/PageSelectors";
import { ComponentTypes, imagePosition } from "Constants";
import { extractCallToActions, extractData, extractTextContent } from "utils";

const Map = ({ pageId }) => {
  const { loading, fetchPageComponents } = usePageComponents();
  const { pageHtmlTags } = useSelector(selectPageComponents);
  const imageComponentData = useSelector(
    useMemo(
      () => selectComponentDataByType(pageId, ComponentTypes.Image),
      [pageId]
    )
  );
  const cardComponentsList = useSelector(
    useMemo(
      () => selectComponentDataByType(pageId, ComponentTypes.CardGroup),
      [pageId]
    )
  );
  const imageData = useMemo(
    () => extractData(imageComponentData),
    [imageComponentData]
  );
  const cardList = useMemo(
    () => extractData(cardComponentsList),
    [cardComponentsList]
  );
  const firstCard = useMemo(
    () => cardList?.find((card) => card.cardType === 2),
    [cardList]
  );
  const mapCard = useMemo(
    () => cardList?.find((card) => card.cardType === 5),
    [cardList]
  );

  useEffect(() => {
    if (pageId) fetchPageComponents(pageId);
  }, [pageId, fetchPageComponents]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {pageHtmlTags?.length > 0 && (
        <HeaderWithTags pageHtmlTags={pageHtmlTags} />
      )}

      {imageData?.length > 0 && <IntroDetailsCard data={imageData[0]} />}

      <Grid container spacing={2} mt={2}>
        {firstCard?.id && (
          <Grid item xs={12} md={4} key={firstCard.id}>
            <Card
              contentArray={extractTextContent(firstCard?.texts)}
              image={firstCard?.image?.imageURL}
              imagePosition={imagePosition[firstCard?.cardType]}
              cardStyles={firstCard?.style}
              imageStyles={firstCard?.image?.style}
              cta={extractCallToActions(firstCard?.carouselCTAs)}
              imageObject={{
                linkURL: firstCard?.image?.linkURL,
                isExternal: firstCard?.image?.isExternal,
              }}
            />
          </Grid>
        )}
        {mapCard?.id && (
          <Grid item xs={12} md={8} key={mapCard.id}>
            <MapView data={mapCard} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Map;
