import * as React from "react";
import { useSnackbar } from "material-ui-snackbar-provider";

export const useCustomSnackbar = () => {
  const snackbar = useSnackbar();

  const buildShowSnackbarFunction = React.useCallback(
    (alertSeverity) => (content, showMessageOptions) => {
      const { action, handleAction, anchorOrigin, autoHideDuration } =
        showMessageOptions || {};

      const textMessage = typeof content === "string" ? content : undefined;
      const childComponent = React.isValidElement(content)
        ? content
        : undefined;

      snackbar.showMessage(textMessage, action, handleAction, {
        alertSeverity,
        childComponent,
        anchorOrigin,
        autoHideDuration,
      });
    },
    [snackbar]
  );

  return React.useMemo(
    () => ({
      ...snackbar,
      showMessage: buildShowSnackbarFunction("info"),
      showInfo: buildShowSnackbarFunction("info"),
      showWarning: buildShowSnackbarFunction("warning"),
      showError: buildShowSnackbarFunction("error"),
      showSuccess: buildShowSnackbarFunction("success"),
    }),
    [snackbar, buildShowSnackbarFunction]
  );
};

export default useCustomSnackbar;
