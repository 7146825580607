import React from "react";
import Chip from "@mui/material/Chip";

import { styled } from "@mui/material";
import { generateStyles } from "../../styles/GenerateStyles";
import useGoogleFonts from "../../hooks/useGoogleFonts";

const defaultStyles = {
  variant: "filled",
  color: "default",
  size: "medium",
};
const ReusableChip = ({
  label,
  onClick,
  variant,
  color,
  size,
  icon,
  styles = { defaultStyles },
  sx,
}) => {
  useGoogleFonts(styles?.fontFamily ? [styles?.fontFamily] : []);

  return (
    <StyledChip
      label={label}
      onClick={onClick}
      variant={variant}
      color={color}
      size={size}
      icon={icon}
      styles={styles}
      sx={sx}
    />
  );
};

const StyledChip = styled(Chip)(({ styles }) => ({
  ...generateStyles({ styles }),
}));

export default ReusableChip;
