import React, { useMemo } from "react";
import { Box } from "@mui/material";

import CarouselSlider from "components/CarouselSlider";
import { transformKeysToCamelCase } from "utils";
import LandingLogo from "components/Logo/LandingLogo";
import { carouselConfig, carouselItems } from "metaDataJson/Landing";
import { TEXT_CONSTANTS } from "Constants";

const Landing = ({ brandTags }) => {
  const transformcarouselConfig = useMemo(
    () => (carouselConfig ? transformKeysToCamelCase(carouselConfig) : {}),
    []
  );
  const transformcarouselItemsConfig = useMemo(
    () => (carouselItems ? transformKeysToCamelCase(carouselItems) : {}),
    []
  );

  return (
    <Box position="relative" sx={{ overflow: "hidden" }}>
      <LandingLogo
        firstPage={{ pageSlug: "Home1", isExternal: false }}
        from={TEXT_CONSTANTS.ORGANIZATION_TEXT}
      />
      <CarouselSlider
        carouselConfig={transformcarouselConfig}
        carouselItems={transformcarouselItemsConfig}
        handleClick={() => {}}
        carouselStyle={{ ...defaultStyles }}
        brandTags={brandTags}
        from={TEXT_CONSTANTS.ORGANIZATION_TEXT}
        applyGradient={true}
      />
    </Box>
  );
};

export default Landing;

const defaultStyles = {
  height: "calc(100vh)",
  maxHeight: "100%",
};
