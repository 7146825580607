import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { isPathPreview } from "utils";
import { ERROR_MESSAGES } from "StatusAlerts";

import pageComponentsReducer from "reduxStore/reducers/PageComponents";
import pageComponentDataReducer from "reduxStore/reducers/PageComponentData";

// Custom hook to fetch page components
export const usePageComponents = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isPreview = isPathPreview(location.pathname);

  const handleError = useCallback(() => {
    setLoading(false);
    setError(ERROR_MESSAGES.FAILED_TO_LOAD_PAGE_COMPONENTS);
  }, []);

  const fetchComponentsListDataByIds = useCallback(
    (pageComponents) => {
      dispatch(
        pageComponentDataReducer.operations.fetchComponentsListDataByIds({
          pageComponents,
          isPreview,
          onSuccessResponse: () => setLoading(false),
          onErrorResponse: handleError,
        })
      );
    },
    [dispatch, isPreview, handleError]
  );

  const handleSuccess = useCallback(
    ({ response }) => {
      if (response?.pageComponents.length > 0) {
        fetchComponentsListDataByIds(response?.pageComponents);
      } else {
        setLoading(false);
      }
    },
    [fetchComponentsListDataByIds]
  );

  const fetchPageComponents = useCallback(
    (pageId) => {
      if (!pageId) return;
      setLoading(true);
      setError(null);
      dispatch(
        pageComponentsReducer.operations.fetchPageComponentsListById({
          pageId,
          isPreview,
          onSuccessResponse: handleSuccess,
          onErrorResponse: handleError,
        })
      );
    },
    [dispatch, handleSuccess, handleError, isPreview]
  );

  return { loading, error, fetchPageComponents };
};
