import { createSlice } from "@reduxjs/toolkit";
import { fetchThemeByOrganizationIdApi } from "services/ApiService";
import { ERROR_MESSAGES } from "StatusAlerts";

const initialState = {
  pageComponents: [],
  pageData: null,
};

export const organizationComponentsSlice = createSlice({
  name: "organizationComponents",
  initialState,
  reducers: {
    setThemeByOrganizationId: (state, { payload }) => ({
      ...state,
      pageData: payload,
    }),
  },
});

const fetchThemeByOrganizationId = ({
  organizationId,
  onSuccessResponse,
  onErrorResponse,
}) => {
  return async (dispatch) => {
    try {
      const { result, error, statusCode } = await fetchThemeByOrganizationIdApi(
        organizationId
      );
      if (statusCode === 200) {
        dispatch(
          organizationComponentsSlice.actions.setThemeByOrganizationId(
            result.data
          )
        );
        onSuccessResponse({ response: result.data });
      } else {
        onErrorResponse({
          response: {
            errorMessage:
              error || ERROR_MESSAGES.FAILED_TO_FETCH_ORGANIZATION_THEME,
          },
        });
      }
    } catch (error) {
      onErrorResponse({
        response: {
          errorMessage:
            error?.message || ERROR_MESSAGES.FAILED_TO_FETCH_ORGANIZATION_THEME,
        },
      });
    }
  };
};

const organizationComponent = {
  reducer: organizationComponentsSlice.reducer,
  actions: organizationComponentsSlice.actions,
  operations: {
    fetchThemeByOrganizationId,
  },
};

export default organizationComponent;
