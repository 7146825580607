import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DynamicRoutes from "./DynamicRoutes";
import NoRouteFound from "../NoRouteFound";
import PageNavigator from "pages/OrganizationLayout/PageNavigation";
import { PREVIEW_ROUTE_NAME } from "Constants";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:projectId/*" element={<DynamicRoutes />} />
        <Route
          path={`/${PREVIEW_ROUTE_NAME}/:projectId/*`}
          element={<DynamicRoutes />}
        />
        <Route
          path="/layout/:pageSlug/:organizationId"
          element={<PageNavigator />}
        />
        <Route
          path="/layout/:pageSlug/:organizationId/*"
          element={<NoRouteFound />}
        />
        <Route path="*" element={<NoRouteFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
