import React from "react";
import ContentBlock from "components/ContentBlock";

const TextComponentRenderer = ({
  contentArray,
}) => {
  return (
    contentArray?.map(
      ({
        id,
        content,
        style = {},
        contentHTML = "",
        tagType = "div"
      }, index) => (
        <ContentBlock
          key={id || index}
          content={content}
          styles={style}
          contentHTML={contentHTML}
          tagType={tagType}
        />
      ))
  )
}
export default TextComponentRenderer;